import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthUser from "../../Hooks/authUser";
import UpdateHooks from "../../Hooks/UpdateHooks";

const MyAccount = () => {
  const { userInfo,logout } = AuthUser();
  const [formData, setFormData] = useState({
    name: "",
    sex: "",
    address: "",
    phone: "",
  });
  useEffect(() => {
    fetch(
      `https://alala.niroghealthplus.com/api/v1/user/by-email?email=${userInfo?.email}`
    )
      .then((res) => res.json())
      .then((data) => {
        setFormData(data?.data);
      });
  }, [ userInfo]);

  const handelSubmit = async () => {
    if (userInfo?._id) {
      await UpdateHooks(
        `https://alala.niroghealthplus.com/api/v1/user/${userInfo?._id}`,
        formData
      );
      toast.success("data updated !");
    } else {
      toast.error("Please login first");
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className=" p-3 max-w-lg mx-auto">
      <div className="">
        <label className="text-sm">Full Name</label>
        <input
          onChange={handleChange}
          name="name"
          required
          value={formData?.name}
          type="text"
          className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
        ></input>
      </div>
      <div className="mt-1">
        <label className="text-sm">Number</label>
        <input
          required
          value={formData?.phone}
          onChange={handleChange}
          name="number"
          type="text"
          className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
        ></input>
      </div>

      <div className="mt-1">
        <label className="text-sm">Email</label>
        <input
          onChange={handleChange}
          value={userInfo?.email}
          name="email"
          disabled
          type="text"
          className="w-full rounded-md cursor-not-allowed focus:outline-none bg-white border-2 p-2"
        ></input>
      </div>
      <div className="mt-1">
        <label className="text-sm">Address</label>
        <input
          onChange={handleChange}
          value={formData?.address}
          name="address"
          required
          type="text"
          className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
        ></input>
      </div>

      <div className="flex gap-4"><button
        onClick={handelSubmit}
        className="bg-primary w-fit mt-5 py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
      >
        Update Profile!
      </button>
      <button
        onClick={()=>logout()}
        className="bg-red-500 w-fit mt-5 py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
      >
        Logout
      </button>
      </div>
    </div>
  );
};

export default MyAccount;
