import React from "react";
import {

  FaHeadset,
  FaCaravan,
  FaAddressCard,
  FaLinkedin,
  FaTwitterSquare,
  FaFacebookSquare,
  FaAssistiveListeningSystems,
  FaHandshake,
  FaCcVisa,
  FaCcMastercard,
  FaCcPaypal,
  FaCcStripe,
  FaCcApplePay,
  FaAccusoft,
} from "react-icons/fa";

const Comunity = () => {
  return (
    <div className="my-10 px-5 mt-20 md:px-10 bg-[#FDE0E2] pt-10 pb-28">
      {/* Frist div */}
      <div className=" flex gap-8 justify-between border-b-[1px] border-black pb-6">
        {/* text and social  */}
        <div className="w-3/4">
          <p className="text-xl md:text-3xl font-semibold">
            Lorem ipsum dolor sit
          </p>
          <div className="flex mt-4 gap-3 text-center">
            <FaFacebookSquare className="text-3xl md:text-4xl"></FaFacebookSquare>
            <FaLinkedin className="text-3xl md:text-4xl"></FaLinkedin>
            <FaTwitterSquare className="text-3xl md:text-4xl"></FaTwitterSquare>
          </div>
        </div>

        {/* icons  */}
        <div className="grid grid-cols-4 gap-5">
          <div className="">
            <div className="bg-[#F8CDD6] md:mx-6 flex justify-center py-1 rounded">
              <FaAddressCard className="text-[#D1196F] text-4xl"></FaAddressCard>
            </div>

            <p className="text-center mt-2 text-sm font-medium">
              Lorem ipsum dolor sit ametee.
            </p>
          </div>

          {/* div 2  */}
          <div className="">
            <div className="bg-[#DEDEEA] md:mx-6 flex  justify-center py-1 rounded">
              <FaAddressCard className="text-[#5F5EF7] text-4xl"></FaAddressCard>
            </div>

            <p className="text-center mt-2 text-sm font-medium">
              Lorem ipsum dolor sit amet.
            </p>
          </div>

          {/* div 3 */}
          <div className="">
            <div className="bg-[#ECE9E0] md:mx-6 flex justify-center py-1 rounded">
              <FaCaravan className="text-[#657436] text-4xl"></FaCaravan>
            </div>

            <p className="text-center mt-2 text-sm font-medium">
              Lorem ipsum dolor sit amet.
            </p>
          </div>

          {/* div 4 */}

          <div className="">
            <div className="bg-[#EECDD8] md:mx-6 flex justify-center py-1 rounded">
              <FaHeadset className="text-[#9185BE] text-4xl"></FaHeadset>
            </div>

            <p className="text-center mt-2 text-sm font-medium">
              Lorem ipsum dolor sit amet.
            </p>
          </div>
        </div>
      </div>

      {/* Secound div  */}

      <div className="flex flex-col md:flex-row gap-10 justify-between mt-4  ">
        <div className=" flex gap-3">
          
          <div>
            <p className="font-medium text-lg">Lorem ipsum dolor sit amet.</p>
            <div className="mt-5 flex justify-left gap-5">
              <div className="bg-[#FFFFFF] py-1 px-5 flex justify-center items-center rounded-lg ">
                <FaAssistiveListeningSystems className="text-4xl text-[#37A8E1]"></FaAssistiveListeningSystems>
              </div>

              <div className="bg-[#FFFFFF] py-1 flex px-5 justify-center items-center rounded-lg ">
                <FaAccusoft className="text-4xl text-[#37A]"></FaAccusoft>
              </div>
            </div>
          </div>
        </div>

        <div className="  ">
          <p className="text-lg mb-3">Lorem ipsum dolorrr </p>
          <div className="flex justify-between gap-1 md:gap-5">
            <div>
              <p>Cash on delivery</p>
              <div className="bg-white md:mx-5 py-1 mt-2 flex justify-center items-center rounded-lg">
                <FaHandshake className="text-4xl"></FaHandshake>
              </div>
            </div>

            <div>
              <p>Cart payment </p>
              <div className="bg-white px-2 md:px-4 mt-2 py-1 gap-2 flex justify-evenly rounded-lg">
                <FaCcVisa className="text-4xl text-[#283392] "></FaCcVisa>
                <FaCcMastercard className="text-4xl text-[#C91820]"></FaCcMastercard>
                <FaCcPaypal className="text-4xl text-[#24377A]"></FaCcPaypal>
              </div>
            </div>

            <div>
              <p>Mobile banking</p>
              <div className="bg-white px-2 md:px-4 mt-2 gap-2 py-1 flex justify-evenly rounded-lg">
                <FaCcStripe className="text-4xl text-[#FDD226]"></FaCcStripe>
                <FaCcApplePay className="text-4xl text-[#EB6608]"></FaCcApplePay>
                <FaCcPaypal className="text-4xl text-pink-600"></FaCcPaypal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comunity;


