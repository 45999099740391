import React from 'react'

const Mynotification = () => {
  return (
    <div className="px-5 md:px-10 my-10">
        <div>
          <h1 className="text-xl md:text-3xl font-bold">Newsletter </h1>
          <p className="mt-4">Find and update all your connection</p>
        </div>
        <div>
            
        </div>
    </div>
  )
}

export default Mynotification