import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="px-5 mb-10 md:px-10">
      <h1 className="font-bold">Privacy Policy</h1>
      <div>
        <p className="py-4">
          We therefore adhere to relevant privacy laws and regulations,
          including the General Data Protection Regulation:
        </p>
        <ul className="list-disc flex flex-col gap-2 ml-5">
          <li>
            Clearly define our purposes before we process your personal data,
            via this privacy statement;
          </li>
          <li>
            Keep as little personal data as possible and only the data necessary
            for our purposes;
          </li>
          <li>
            Request explicit consent for the processing of your personal data,
            if such consent is required;
          </li>
          <li>
            Take the necessary security measures to protect your personal data.
            We also impose these obligations on parties who process personal
            data on our behalf;
          </li>
          <li>
            Respect your rights, such as the right to access, correct or delete
            your personal data processed by us.
          </li>
        </ul>
        <p className="my-5 leading-10">
          Your data is safe with us and we will always use it correctly. In this
          privacy statement we explain what we do with the information we learn
          about you on the ALALA website. If you have any questions or would
          like to know exactly what we keep about you, please contact ALALA;
          Sending newsletters or “Newsletter” We have a newsletter and you will
          only be added to the list of subscribers with your permission. In the
          newsletter you can read news, tips and information about our products
          and services. We use your name and address for this purpose. We do
          this based on your consent. We retain this information until you
          cancel your subscription. You can unsubscribe from the newsletter at
          any time. Each newsletter contains an unsubscribe link. Making
          available to third parties. We work with certain companies who may
          receive your personal information from us. For the processing of
          newsletters with which we cooperate: ………………………………………………………………………………..
          We also share data with the following parties, who provide us with
          tailor-made advertisements: …………………………………………………………. Among these
          parties, some are located outside Côte d'Ivoire.
        </p>
        <h1 className="font-bold">Order processing :</h1>
        <p className="leading-8 mt-3">
          When you place an order with us, we use your personal data to be able
          to process it carefully. We may then transmit your personal data to
          our delivery service so that the order is delivered to you. We also
          receive information about your payment from your bank, mobile carrier,
          credit card company or any payment service you use.
        </p>
        <p className="my-5 leading-8">
          For this purpose, we use your billing address, your email address,
          your payment details, your telephone number and your name and address.
          We need this information because of the contract we have with you. We
          retain this information until your order is completed. We retain
          certain customer data for a longer period as part of the legal
          obligation to retain tax data.
        </p>

        <h1 className="font-bold">Making available to third parties:</h1>
        <p className="my-5">
          We work with certain companies who may receive your personal
          information from us.
        </p>
        <h1 className="font-bold">Your account :</h1>
        <p className="leading-8 my-5">
          Some parts of our website require you to register first. You will then
          need to provide information about yourself and choose a username. An
          account will be created where you can log in with this username and a
          password of your choice.
        </p>
        <p className="leading-8 my-5">
          For this purpose, we use your telephone number, name and address as
          well as your billing address. We need this information because of the
          contract we enter into with you. We will retain this information until
          you cancel your account.
        </p>
        <p className="leading-8">
          We will keep this information so that you do not have to fill it in
          each time and so that we can contact you more easily if necessary on
          the website. You can edit the information through your account
          whenever you want.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
