import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import Login from "./Login";
import SignUp from "./SignUp";
import Fade from 'react-reveal/Fade';

const AuthModal = ({ isOpen, setIsOpen }) => {
  const [nav, setNav] = useState("login");
  return (
    <Fade top>
     
    <div className=" fixed top-16 right-1 lg:w-4/12 md:w-5/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle  alig shadow-xl transition-all z-50 border">
      <div className="px-4 py-2">
        <div className="flex gap-2">
          <button
            onClick={() => setNav("login")}
            className={`${
              nav === "login"
                ? "bg-rose-400 text-white"
                : "bg-gray-200 text-black"
            } px-6 py-1.5  rounded-md duration-500`}
          >
            Login
          </button>
          <button
            onClick={() => setNav("signUp")}
            className={`${
              nav === "signUp"
                ? "bg-rose-400 text-white"
                : "bg-gray-200 text-black"
            } px-6 py-1.5  rounded-md duration-500`}
          >
            SignUp
          </button>
        </div>

        <div>
          {nav === "login" ? (
            <Login isOpen={isOpen} setIsOpen={setIsOpen} />
          ) : (
            <SignUp isOpen={isOpen} setIsOpen={setIsOpen} />
          )}
        </div>
      </div>

      <div className="border-t h-6 bg-rose-100"></div>
    </div>
    </Fade>
  );
};

export default AuthModal;
