import React, { useState } from "react";
import AuthUser from "../../Hooks/authUser";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Loading from "../Loading";
import { Link } from "react-router-dom";

const Login = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const { http, setToken } = AuthUser();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handelSubmit = async (e) => {
    // setLoading(true)
    e.preventDefault();
    if (!formData?.email && !formData?.password) {
      return toast.error("something wrong !");
    }
    await http
      .post("/user/login", formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Login Successfully");

          setToken(
            res.data.data.user.email,
            res.data.data.token,
            res.data.data.user.role,
            res.data.data.user,
            res.data.data.userIp
          );
          window.location.reload();
          setIsOpen(!isOpen);
        } else {
          // console.log("rrrrrr");
        }
      })
      .catch((err) => {
        console.log("Error", err.response.data.message);
        swal("Error", `${err.response.data.message}`, "error");
        setLoading(false);
      });
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <div className="">
        <div className="">
          <label className="text-sm">Email</label>
          <input
            onChange={handleChange}
            name="email"
            placeholder="Your Email Address"
            required
            value={formData?.email}
            type="email"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          ></input>
        </div>
        <div className="">
          <label className="text-sm">Password</label>
          <input
            onChange={handleChange}
            name="password"
            required
            value={formData?.password}
            placeholder="Your Password"
            type="password"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          ></input>
        <Link onClick={()=>setIsOpen(false)}
        className="text-left text-sm text-secondary underline"
        to="/resetPassword"
      >
        forget password
      </Link>
        </div>

        <button
          onClick={handelSubmit}
          className="bg-rose-400 w-fit mt-5 py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
