import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { server_url } from "../../../Config/API";

const UserDetailsModal = ({ open, setOpen, user }) => {
  const [formData, setFormData] = useState({});
  useEffect(() => {
    setFormData(user);
  }, [open, user]);

  const handelSubmit = async (e) => {
    e.preventDefault();
    await UpdateHooks(`${server_url}/user/${user?._id}`,formData);
    toast.success("User Data Updated!")
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative  z-50"
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-5/12 md:7/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle  alig shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">{user?.name} Details</h2>
                  <button
                    onClick={() => setOpen(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>
                <div className=" p-3">
                  <div className="">
                    <label className="text-sm">Name</label>
                    <input
                      onChange={handleChange}
                      name="name"
                      placeholder="Your Name"
                      required
                      value={formData?.name}
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>
                  <div className="">
                    <label className="text-sm">Email</label>
                    <input
                      onChange={handleChange}
                      name="email"
                      placeholder="Your Email Address"
                      required
                      value={formData?.email}
                      disabled
                      type="email"
                      className="w-full cursor-not-allowed rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>
                  <div className="">
                    <label className="text-sm">Phone</label>
                    <input
                      onChange={handleChange}
                      name="phone"
                      placeholder="Your Phone Number"
                      required
                      value={formData?.phone}
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>

                  <div>
                  <label className="text-sm block my-1">User Type</label>
                  <select
                      onChange={handleChange}
                      className="border-2 p-2 w-full  rounded-md outline-none"
                    >
                      <option
                        selected={user?.userType === "normal"}
                        value="normal"
                      >
                        Normal
                      </option>
                      <option selected={user?.userType === "VIP"} value="VIP">
                        VIP
                      </option>
                    </select>
                  </div>

                  <button
                    onClick={handelSubmit}
                    className="bg-rose-400 w-fit mt-5 py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
                  >
                    Update User
                  </button>
                </div>

                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UserDetailsModal;
