import React from 'react'
import BrandExclusive from '../../Components/BrandExclusive/BrandExclusive'

const BrandExclusivePage = () => {
  return (
    <div>
        <BrandExclusive></BrandExclusive>
    </div>
  )
}

export default BrandExclusivePage