import React from "react";
import { FaWhatsapp } from "react-icons/fa6";
import CardCategoryProduct from "../CategoryProduct/CardCategoryProduct";

const SuccessForm = () => {
  return (
    <div className="px-5 md:px-10">
      <h1 className="text-2xl font-bold uppercase text-center my-10 ">
        Alala Thanks you :) Debassane
      </h1>
      <div className="p-12 rounded bg-white">
        <p>Click on the button below for details on whatsapp</p>
        <button className="flex text-xs items-center rounded justify-center gap-2 uppercase w-full mt-4 bg-green-600 text-white px-2 font-bold py-1">
          <FaWhatsapp className="text-4xl py-1" />
          Follow the orders by whatsapp
        </button>
      </div>

      <h1 className="text-2xl font-bold my-10 ">Usually purchase with</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
        <CardCategoryProduct></CardCategoryProduct>
        <CardCategoryProduct></CardCategoryProduct>
        <CardCategoryProduct></CardCategoryProduct>
 
      </div>

      <div className="mt-16">
        <div className="flex justify-between items-center">
          <h4 className="text-lg font-bold">To sum up</h4>
          <div>
            <div className="flex font-bold ">
              <p className="text-2xl">95.600</p>
              <span>F</span>
            </div>
            <div className="flex ">
              <p className="text-lg font-semibold text-red-500">95.600</p>
              <span>F</span>
            </div>
          </div>
        </div>

        <div className="bg-white p-10 rounded">
          <div className="flex gap-2 border-b-2 border-b-black pb-4  flex-col">
            <h1 className="font-bold">Order N : <span>4523362-5254555658</span></h1>
            <h1 className="font-bold">Order Date : <span className="font-medium">Lun,. 17/07</span></h1>
            <h1 className="font-bold">Method of payment: <span className="font-medium">Cash on delivery</span></h1>
            <h1 className="font-bold">Estimeted delivery Date : <span className="font-medium">Lun,. 17/07 - Mar,. 25/07</span></h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessForm;
