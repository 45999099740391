import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import './index.css'
import 'react-toastify/dist/ReactToastify.css';
import Main from "./Pages/Main/Main";
import Home from "./Pages/Home/Home";
import PublicRoutes from "./Routes/PublicRoutes";
import UserDashboard from "./Pages/UserDashboard/UserDashboard";
import UserDashboardIndex from "./Components/AdminDashboard/UserDashboard/UserDashboardIndex";
import UserRoutes from "./Routes/UserRoutes";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import AdminDashboardIndex from "./Components/AdminDashboard/AdminDashboard/AdminDashboardIndex";
import AdminRoutes from "./Routes/AdminRoutes";
import Notfound from "./Shared/Notfound";
import { useState } from "react";
import AccountPage from "./Pages/AccountPage/AccountPage";
import MyContext from "./Context/MyContext";

function App() {
  const [openLogin, setOpenLogin] = useState(false);
  const [refetch,setRefetch]=useState(false)

  return (
    <MyContext.Provider value={{ refetch, setRefetch, openLogin,setOpenLogin }}>
    <div className="">
      <Routes>

        {/* Public routes */}
        <Route path="/" element={<Main />}>
          <Route index element={<Home />}></Route>
          {PublicRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>


        {/* User dashboard routes */}
        <Route path="/userDashboard" element={<UserDashboard />}>
          <Route index element={<AccountPage />}></Route>
          {UserRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>

        {/* Admin routes */}
        <Route path="/adminDashboard" element={<AdminDashboard />}>
          <Route index element={<AdminDashboardIndex />}></Route>
          {AdminRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>

        <Route path="*" element={<Notfound />}></Route>
      </Routes>

      {/* for toast 👇 */}
      <ToastContainer />

    </div>
    </MyContext.Provider>
  );
}

export default App;
