import React, { useEffect, useState } from "react";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { useParams } from "react-router-dom";
import { singleImageUpload } from "../../../Hooks/ImageUpload";

const UpdateEvent = () => {
  const { id } = useParams();
  const [banner, setBanner] = useState("");
  const [internalBanner, setInternalBanner] = useState("");
  const [descriptionImg, setDescriptionImg] = useState("");
  const [icon, setIcon] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    startDate: "",
    endDate: "",
    descriptionTitle: "",
    descriptionText: "",
    presentationText: "",
    averageDeliveryTime: "",
    subscribeOption: "",
    typeOfEvent:"",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    fetch(`https://alala.niroghealthplus.com/api/v1/event/getEventsById/${id}`).then(
      (res) =>
        res.json().then((data) => {
          setFormData(data?.data);
          setIcon(data?.data?.icon);
          setBanner(data?.data?.banner);
          setDescriptionImg(data?.data?.descriptionImg);
          setInternalBanner(data?.data?.internalBanner);
        })
    );
  }, [id]);

  const handelUpdate = async (e) => {
    e.preventDefault();
    const BASE_URL = `https://alala.niroghealthplus.com/api/v1/event/updateEvents/${id}`;

    await UpdateHooks(
      BASE_URL,
      { ...formData, icon, banner, descriptionImg, internalBanner },
      true,
      "Event info Updated"
    );
  };
  // noting

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    const name = event.target.name;
    if (name == "icon") {
      singleImageUpload(formData, setIcon);
    } else if (name == "banner") {
      singleImageUpload(formData, setBanner);
    } else if (name == "descriptionImg") {
      singleImageUpload(formData, setDescriptionImg);
    } else if (name == "internalBanner") {
      singleImageUpload(formData, setInternalBanner);
    }
  };

  return (
    <div>
      <div class=" ">
        <h1 class="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          Update Event
        </h1>
        <p class="text-lg text-gray-800 mb-8">
          Update essential Event for health . Act now to secure the latest items
        </p>
      </div>
      <form
        onSubmit={handelUpdate}
        className="shadow-lg shadow-gray-300 px-8 py-10 rounded flex flex-col gap-4 text-left"
      >
        {/* code  */}
        <div className="mb-1  w-full  mr-0 md:mr-2">
          <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
            Name
          </label>
          <input
            type="text"
            name="name"
            value={formData?.name}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            placeholder="Enter a name"
            required
          />
        </div>
        <div className="mb-1  w-full  mr-0 md:mr-2">
          <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
          Average Delivery Time
          </label>
          <input
            type="text"
            name="averageDeliveryTime"
            value={formData?.averageDeliveryTime}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            placeholder="Enter a average delivery Time"
            required
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 items-center">
          <div className="mb-1  w-full  mr-0 md:mr-2">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              Start Date {formData?.startDate}
            </label>
            <input
              type="date"
              name="startDate"
              value={formData?.startDate}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
              placeholder="Enter Start Date"
              required
            />
          </div>
          <div className="mb-1  w-full  mr-0 md:mr-2">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              End Date {formData?.endDate}
            </label>
            <input
              type="date"
              name="endDate"
              value={formData?.endDate}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
              placeholder="Enter End Date"
              required
            />
          </div>
        </div>

        <div className="mb-1 flex gap-3 items-center w-full">
          <div className="w-full">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              {" "}
              Icon image
            </label>
            <input
              onChange={handleChangeUploadImage}
              className="block w-full text-sm text-gray-900  rounded-lg cursor-pointer bg-[#F0FDF4]  focus:outline-none    p-2"
              name="icon"
              type="file"
            />
          </div>
          <img className="w-14 rounded-md" src={icon} alt="product img"></img>
        </div>

        <div className="mb-1 flex gap-3 items-center w-full">
          <div className="w-full">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              {" "}
              Banner image
            </label>
            <input
              onChange={handleChangeUploadImage}
              className="block w-full text-sm text-gray-900  rounded-lg cursor-pointer bg-[#F0FDF4]  focus:outline-none    p-2"
              name="banner"
              type="file"
            />
          </div>
          <img className="w-14 rounded-md" src={banner} alt="product img"></img>
        </div>

        <div className="mb-1 flex gap-3 items-center w-full">
          <div className="w-full">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              {" "}
              Internal Banner
            </label>
            <input
              onChange={handleChangeUploadImage}
              className="block w-full text-sm text-gray-900  rounded-lg cursor-pointer bg-[#F0FDF4]  focus:outline-none    p-2"
              name="internalBanner"
              type="file"
            />
          </div>
          <img className="w-14 rounded-md" src={internalBanner} alt="internal banner"></img>
        </div>
        <div className="mb-1  w-full  mr-0 md:mr-2">
          <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
            Description Title
          </label>
          <input
            type="text"
            name="descriptionTitle"
            value={formData?.descriptionTitle}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            placeholder="Enter description title"
            required
          />
        </div>
        <div className="mb-1  w-full  mr-0 md:mr-2">
          <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
            Description Text
          </label>
          <input
            type="text"
            name="descriptionText"
            value={formData?.descriptionText}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            placeholder="Enter description text"
            required
          />
        </div>
        <div className="mb-1 flex gap-3 items-center w-full">
          <div className="w-full">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              {" "}
              Description Img
            </label>
            <input
              onChange={handleChangeUploadImage}
              className="block w-full text-sm text-gray-900  rounded-lg cursor-pointer bg-[#F0FDF4]  focus:outline-none    p-2"
              name="descriptionImg"
              type="file"
            />
          </div>
          <img className="w-14 rounded-md" src={descriptionImg} alt="Banner img"></img>
        </div>

        <div className="mb-1  w-full  mr-0 md:mr-2">
          <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
          Presentation Text
          </label>
          <input
            type="text"
            name="presentationText"
            value={formData?.presentationText}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            placeholder="Enter description text"
            required
          />
        </div>

        <div className="mb-1  w-full  mr-0 md:mr-2">
          <label
          
            class="block mb-2 text-[13px] font-normal text-gray-900 "
          >
            Subscribe Option
          </label>
          <select
            name="subscribeOption"
            type="text"
            value={formData?.subscribeOption}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5    focus:border-blue-500"
          >
            <option value="" disabled selected>
              select one
            </option>

            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        <div className="mb-1  w-full  mr-0 md:mr-2">
          <label
            
            class="block mb-2 text-[13px] font-normal text-gray-900 "
          >
            Type Of Event
          </label>
          <select
            name="typeOfEvent"
            type="text"
            value={formData?.typeOfEvent}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5    focus:border-blue-500"
          >
            <option value="" disabled selected>
              select one
            </option>

            <option value="New">New</option>
            <option value="Current selections">Current selections</option>
            <option value="For her">For her</option>
            <option value="for him"> for him</option>
          </select>
        </div>

        <div className="text-center pt-3">
          <button
            className="bg-primary2 hover:bg-lightPrimary text-white  py-2 rounded-lg text-lg w-fit px-8"
            type="submit"
          >
            Update Event
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateEvent;
