import React from "react";
import BrandCarousel from "./BrandCarousel";
import BrandCard from "./BrandCard";
import useGetDataHook from "../../Hooks/useGetDataHook";
import { server_url } from "../../Config/API";
import Loading from "../../Shared/Loading";
import Universe from "./Universe";

const BrandExclusive = () => {
  const [brands, loading] = useGetDataHook(`${server_url}/brand/getBrands`);
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      {/* banner  */}
      <div>
        <img
          className="md:h-full h-32"
          src="https://alala.ci/wp-content/uploads/2022/11/Brand-Exclusive.png"
          alt=""
        ></img>
      </div>

      {/* Brand carousel */}
      <BrandCarousel brands={brands}></BrandCarousel>

      <Universe/>

      {/* brand card  */}
      <div className="mx-5 md:mx-10">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
          {brands?.map((brand) => (
            <BrandCard brand={brand}></BrandCard>
          ))}
        </div>
      </div>

      {/* extra card  */}
      <div className="mx-5 md:mx-10 my-14">
        <div className="px-5 md:px-10 pt-10 pb-16 bg-black">
          <h1 className="uppercase text-3xl font-bold text-white">
            Prochanment Disponiment
          </h1>
          <p className="text-white mb-7 mt-2">
            The KENZO Flower by Kenzo women's perfume set will
          </p>
          <div className="grid opacity-50 grid-cols-2 md:grid-cols-4 gap-7">
            {brands?.map((brand) => (
              <BrandCard brand={brand}></BrandCard>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandExclusive;
