import React, { useState } from "react";
import { toast } from "react-toastify";
import CreateUserHook from "../../Hooks/createUserHook";

const SignUp = ({ isOpen, setIsOpen }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    phone: "",
  });

  const handelSubmit = async (e) => {
    e.preventDefault();
    if (formData?.password?.length < 6) {
      return toast.error(`Password contain minimum 6 character`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (formData?.password !== formData?.confirmPassword) {
      return toast.error(`Both password are not same !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    try {
      await CreateUserHook(formData);
  
      setIsOpen(!isOpen);
    } catch (err) {
      toast.error(err?.message);
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <div className="">
        <div className="">
          <label className="text-sm">Name</label>
          <input
            onChange={handleChange}
            name="name"
            placeholder="Your Name"
            required
            value={formData?.name}
            type="text"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          ></input>
        </div>
        <div className="">
          <label className="text-sm">Email</label>
          <input
            onChange={handleChange}
            name="email"
            placeholder="Your Email Address"
            required
            value={formData?.email}
            type="email"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          ></input>
        </div>
        <div className="">
          <label className="text-sm">Phone</label>
          <input
            onChange={handleChange}
            name="phone"
            placeholder="Your Phone Number"
            required
            value={formData?.phone}
            type="text"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          ></input>
        </div>
        <div className="">
          <label className="text-sm">Password</label>
          <input
            onChange={handleChange}
            name="password"
            required
            value={formData?.password}
            placeholder="Your Password"
            type="password"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          ></input>
        </div>
        <div className="">
          <label className="text-sm">Confirm Password</label>
          <input
            onChange={handleChange}
            name="confirmPassword"
            required
            value={formData?.confirmPassword}
            placeholder="Your confirm Password"
            type="password"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          ></input>
        </div>

        <button
          onClick={handelSubmit}
          className="bg-rose-400 w-fit mt-5 py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
        >
          SignUp
        </button>
      </div>
    </div>
  );
};

export default SignUp;
