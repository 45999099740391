import React, { useEffect, useState } from "react";
import Loading from "../../../Shared/Loading";
import { Icon } from "@iconify/react";
import DeleteHook from "../../../Hooks/DeleteHook";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { server_url } from "../../../Config/API";
import { toast } from "react-toastify";
import UserDetailsModal from "./UserDetailsModal";

const AllUser = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [userType, setUserType] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [user,setUser]=useState([]);
  const [open,setOpen]=useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://alala.niroghealthplus.com/api/v1/user");

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setUserData(data.data);
        setFilteredUsers(data.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeleteUser = async (userId) => {
    try {
      console.log("Deleting user with ID:", userId);

      const response = await DeleteHook(
        `https://alala.niroghealthplus.com/api/v1/user/${userId}`
      );

      console.log("Delete response:", response);

      if (!response || !response.ok) {
        throw new Error("Failed to delete user");
      }

      setUserData((prevData) => prevData.filter((user) => user._id !== userId));
    } catch (error) {
      console.error("Error deleting user:", error.message);
    }
  };

  const handleSearch = () => {
    setFilteredUsers(
      userData?.filter((userData) =>
        userData?.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) || []
    );
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  if (loading) {
    return <Loading />;
  }

  const handelChange = async (id) => {
    console.log(userType)
    await UpdateHooks(`${server_url}/user/${id}`, { userType: userType });
    toast.success("user data updated");
  };

  return (
    <div>
      <div className="flex relative rounded-md w-full mt-3 mb-3">
        <input
          type="text"
          placeholder="Enter user name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>Search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search" />
          </span>
        </button>
      </div>
      <div className="mb-3">
        <span className="text-gray-700">
          Showing {filteredUsers.length} Results
        </span>
      </div>
      <div class="w-full overflow-x-auto">
        <table
          class="w-full text-left border border-collapse rounded sm:border-separate border-slate-200"
          cellspacing="0"
        >
          <tbody>
            <tr>
              <th
                scope="col"
                class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
              >
                #
              </th>
              <th
                scope="col"
                class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Name
              </th>

              <th
                scope="col"
                class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Role
              </th>
              <th
                scope="col"
                class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Phone
              </th>
              <th
                scope="col"
                class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Email
              </th>
              <th
                scope="col"
                class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Actions
              </th>
            </tr>
            {filteredUsers &&
              filteredUsers.map((user, index) => (
                <tr key={user?._id}>
                  <td className="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500">
                    {index + 1}
                  </td>
                  <td className="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500">
                    {user?.name}
                  </td>
                  <td className="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500">
                    {user?.role}
                  </td>
                  <td className="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500">
                    {user?.phone}
                  </td>
                  <td className="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500">
                    {user?.email}
                  </td>
                  <td className="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 flex items-center gap-2">
                    <Icon
                      className="text-3xl text-red-400 cursor-pointer"
                      icon="eos-icons:content-deleted"
                      onClick={() => handleDeleteUser(user._id)}
                    ></Icon>
                    <Icon
                      className="text-3xl text-green-400 cursor-pointer"
                      icon="tabler:edit"
                      onClick={() => {
                        setUser(user);
                        setOpen(!open)
                      }}
                    ></Icon>
                    <select
                      onChange={(e) => {
                        setUserType(e?.target?.value);
                        handelChange(user?._id)
                      }}
                      className="border px-2 py-1 rounded-md outline-none"
                    >
                      <option
                        selected={user?.userType === "normal"}
                        value="normal"
                      >
                        Normal
                      </option>
                      <option selected={user?.userType === "VIP"} value="VIP">
                        VIP
                      </option>
                    </select>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <UserDetailsModal open={open} setOpen={setOpen} user={user}/>
    </div>
  );
};

export default AllUser;
