import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdOutlineDoNotDisturbOn } from "react-icons/md";
import { server_url } from "../../../Config/API";
import Loading from "../../../Shared/Loading";

const UserOrderOverview = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setLoading(true);
    try {
      fetch(`${server_url}/order/getOrdersById/${id}`)
        .then((res) => res.json())
        .then((data) => {
          setData(data?.data);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  }, [id]);
  console.log(data);
  if (loading) {
    return <Loading />;
  }

  const delivered = data?.status === "delivered";
  const delivering = delivered || data?.status === "delivering";
  const confirm = delivered || delivering || data?.status === "confirm";
  const pending =
    delivered || delivering || confirm || data?.status === "pending";
  return (
    <div className="bg-white p-5 rounded-lg">
      <div className="w-fit mb-2">
        <h2 className="text-lg font-semibold">Timeline</h2>
        <div className="h-1 w-[60%] border-2 border-dashed border-green-300"></div>
      </div>
      <div className="p-2">
        <div>
          <ol class="relative border-s-8 border-gray-100 ">
            <li class="mb-10 ms-8">
              <span
                className={`${
                  pending ? "bg-green-200" : "bg-red-100"
                } absolute flex items-center justify-center w-10 h-10  rounded-full -start-6 ring-white`}
              >
                {pending ? (
                  <IoIosCheckmarkCircle className="text-green-600 text-2xl" />
                ) : (
                  <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />
                )}
              </span>
              <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 ">
                Order Placed
              </h3>
              <p class="mb-4 text-base font-normal text-gray-500 ">
                Your order is successfully placed to Arogga. Order id #662044
              </p>
            </li>
            <li class="mb-10 ms-8">
              <span
                className={`${
                  pending ? "bg-green-200" : "bg-red-100"
                } absolute flex items-center justify-center w-10 h-10  rounded-full -start-6 ring-white`}
              >
                {pending ? (
                  <IoIosCheckmarkCircle className="text-green-600 text-2xl" />
                ) : (
                  <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />
                )}
              </span>
              <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 ">
                Processing
              </h3>

              <p class="mb-4 text-base font-normal text-gray-500 ">
                We have received your order, our pharmacist will check and
                confirm shortly.
              </p>
            </li>
            <li class="mb-10 ms-8">
              <span
                className={`${
                  pending ? "bg-green-200" : "bg-red-100"
                } absolute flex items-center justify-center w-10 h-10  rounded-full -start-6 ring-white`}
              >
                {pending ? (
                  <IoIosCheckmarkCircle className="text-green-600 text-2xl" />
                ) : (
                  <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />
                )}
              </span>
              <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 ">
                Pending
              </h3>

              <p class="mb-4 text-base font-normal text-gray-500 ">
                We have received your order, our pharmacist will check and
                confirm shortly.
              </p>
            </li>
            <li class="mb-10 ms-8">
              <span
                className={`${
                  confirm ? "bg-green-200" : "bg-red-100"
                } absolute flex items-center justify-center w-10 h-10  rounded-full -start-6 ring-white`}
              >
                {confirm ? (
                  <IoIosCheckmarkCircle className="text-green-600 text-2xl" />
                ) : (
                  <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />
                )}
              </span>
              <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 ">
                Confirm
              </h3>

              <p class="mb-4 text-base font-normal text-gray-500 ">
                We have confirmed your order.
              </p>
            </li>
            <li class="mb-10 ms-8">
              <span
                className={`${
                  delivering ? "bg-green-200" : "bg-red-100"
                } absolute flex items-center justify-center w-10 h-10  rounded-full -start-6 ring-white`}
              >
                {delivering ? (
                  <IoIosCheckmarkCircle className="text-green-600 text-2xl" />
                ) : (
                  <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />
                )}
              </span>
              <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 ">
                Delivering
              </h3>

              <p class="mb-4 text-base font-normal text-gray-500 ">
                Our delivery partner has picked up your order for delivering. it
                generally takes 1-5 days to deliver outside dhaka.
              </p>
            </li>
            <li class="mb-10 ms-8">
              <span
                className={`${
                  delivered ? "bg-green-200" : "bg-red-100"
                } absolute flex items-center justify-center w-10 h-10  rounded-full -start-6 ring-white`}
              >
                {delivered ? (
                  <IoIosCheckmarkCircle className="text-green-600 text-2xl" />
                ) : (
                  <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />
                )}
              </span>
              <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 ">
                Delivered
              </h3>

              <p class="mb-4 text-base font-normal text-gray-500 ">
                You have received your order.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default UserOrderOverview;
