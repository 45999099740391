import React from 'react'

const Advantage = () => {
  return (
    <div className="px-5 md:px-10 my-10">
        <div>
          <h1 className="text-xl md:text-3xl font-bold">My loyality advantage </h1>
          <p className="mt-4">Find and update all your connection</p>
        </div>

        <div className='grid mt-12 border grid-cols-1 md:grid-cols-2 gap-5 md:gap-10'>
            <div className='px-10 mt-16'>
                <p className='text-lg '>Total points on counters</p>
                <h1 className='text-4xl mt-9 font-bold'>906</h1>
                <button className='border-2 mt-16 border-stone-500 rounded w-full px-5 py-2'>View history</button>
            </div>
            <div>
                <img className='h-96 w-full' src='https://w0.peakpx.com/wallpaper/888/234/HD-wallpaper-orange-kiss-model-orange-woman-kiss-anna-subbotina-fruit-girl-hand-slice.jpg' alt=''></img>
            </div>
        </div>


        <div className='mt-16'>
        <h1 className="text-xl md:text-3xl font-bold">How does it work? </h1>
        <div className='border mt-5 p-10 rounded'>
            <ul className='list-decimal flex flex-col gap-3 text-lg'>
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
            </ul>
            <p className="text-xl text-end mt-3 underline">
                Trak your persel
              </p>
        </div>
        </div>
    </div>
  )
}

export default Advantage