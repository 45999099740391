import React from "react";
import MyOrders from "../../Components/UserDashboard/MyOrder/MyOrders";

const values = [
  "Short by popularity",
  "Short by average score",
  "Short by newst to oldest",
  "Short by acending price",
];

const Orders = () => {
  return (
    <div className="px-5 md:px-10 my-10">
      <h1 className="text-xl md:text-3xl font-bold">Orders </h1>

      <div className="">
        <MyOrders />
      </div>
    </div>
  );
};

export default Orders;
