import React, { useEffect, useState } from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import CardCategoryProduct from "../../Components/CategoryProduct/CardCategoryProduct";
import MultiRangeSlider from "multi-range-slider-react";
import { useParams } from "react-router-dom";
import { server_url } from "../../Config/API";
import useGetDataHook from "../../Hooks/useGetDataHook";
import Loading from "../../Shared/Loading";
import { toast } from "react-toastify";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Pagination from "../../Shared/Pagination";

const UniverseProducts = () => {
  const { id } = useParams();
  const [universe] = useGetDataHook(
    `${server_url}/universe/specific?fieldName=${"name"}&&fieldValue=${id}`
  );
  const [range, setRange] = useState({ minValue: 0, maxValue: 100000 });
  const [showIconeCategories, setShowIconeCategories] = useState(true);
  const [order, setOrder] = useState(100);
  const [maxValue, setMaxValue] = useState(100000);
  const [minValue, setMinValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subCategorys] = useGetDataHook(
    `${server_url}/subCategory/getSubCategorys`
  );
  const [products, setProducts] = useState([]);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  const handleRangeChange = (newRange) => {
    setRange(newRange);
    setMaxValue(newRange.maxValue);
    setMinValue(newRange.minValue);
  };

  const [selectedCategorys, setSelectedCategorys] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    const isSelected = selectedCategorys.includes(value);
    setSelectedCategorys((prevSelectedItems) =>
      isSelected
        ? prevSelectedItems.filter((item) => item !== value)
        : [...prevSelectedItems, value]
    );
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const encodedNames = selectedCategorys.map(encodeURIComponent);
      const params = `${encodedNames.join(",")}`;
      // console.log(params)
      try {
        await fetch(
          `${server_url}/product/getProducts?fieldName=${"universe"}&&fieldValue=${id}&&minPrice=${minValue}&&maxPrice=${maxValue}&&subCategory=${params}&&order=${order}&&size=${size}&&page=${page}`
        )
          .then((res) => res.json())
          .then((data) => {
            setQuantity(data?.total);
            setProducts(data?.data);
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        toast.error(err.message);
      }
    };
    fetchData();
  }, [id, selectedCategorys, minValue, maxValue, order, size, page]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <div className="mx-5 md:mx-10">
        {universe?.length ? (
          <img
            className=" w-full max-h-[500px] object-cover rounded-md mt-5 overflow-hidden"
            src={universe[0]?.banner}
            alt="img"
          ></img>
        ) : (
          ""
        )}
      </div>

      {/* 4 product  */}
      <div className="mx-5 md:mx-10 my-10 grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 gap-5">
        {products?.slice(0, 4)?.map((product) => (
          <div key={product?._id} className=" shadow-sm p-2 rounded-md">
            <img
              className="w-full rounded h-44 object-contain"
              src={product?.primaryImg}
              alt=""
            ></img>
            <h1 className="md:text-2xl text-lg font-bold px-2 uppercase mt-2">
              {product?.productName}
            </h1>
          </div>
        ))}
      </div>
      <div className="mx-5 md:mx-10 ">
        <div className="w-fit mb-10">
          <h2 className="text-3xl font-semibold">{id} Products</h2>
          <div className="bg-black h-1 rounded-lg mt-2 w-[60%]"></div>
        </div>
        <div className="flex flex-col gap-14 md:flex-row">
          <div className=" md:w-2/12 lg:border-r lg:pr-5">
            <div>
              <h1>Filter by price</h1>
              <div className="my-5">
                <MultiRangeSlider
                  min={0}
                  max={100000}
                  minValue={minValue}
                  maxValue={maxValue}
                  step={1}
                  ruler={false}
                  label={false}
                  range={range}
                  onChange={handleRangeChange}
                />
                <div>
                  Price: {minValue} CFA - {maxValue} CFA
                </div>
              </div>

              <div>
                {/* Categories  */}
                <div>
                  <div className="flex justify-between items-center">
                    <h1 className=" font-semibold">Categories</h1>
                    <div
                      className="text-xl"
                      onClick={() =>
                        setShowIconeCategories(!showIconeCategories)
                      }
                    >
                      {showIconeCategories ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </div>
                  </div>
                  {showIconeCategories && (
                    <div className="mt-2 grid grid-cols-1">
                      {subCategorys?.map((c) => (
                        <label
                          key={c?.name}
                          className="inline-flex items-center"
                        >
                          <input
                            type="checkbox"
                            value={c?.name}
                            onChange={handleCheckboxChange}
                            checked={selectedCategorys.includes(c?.name)}
                            className="form-checkbox h-5 w-5 text-indigo-600"
                          />
                          <span className="ml-2 text-gray-700">{c?.name}</span>
                        </label>
                      ))}
                    </div>
                  )}
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          {/* Product card side bar  */}
          <div className="md:w-10/12">
            <div>
              <div>
                <label className="text-sm font-semibold mr-2">Sort by: </label>
                <select
                  name="order"
                  type="number"
                  onChange={(e) => setOrder(e.target.value)}
                  className="border p-2 text-sm focus:outline-none mb-5 rounded"
                >
                  <option value={100} selected={order === 100}>
                    None
                  </option>
                  <option value={1} selected={order === 1}>
                    Short by ascending price
                  </option>
                  <option value={-1} selected={order === -1}>
                    Short by descending price
                  </option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-5 gap-y-16 md:grid-cols-3">
              {products?.map((product) => (
                <CardCategoryProduct product={product} />
              ))}
            </div>

            <Pagination
              quantity={quantity}
              page={page}
              setPage={setPage}
              size={size}
              setSize={setSize}
            />
          </div>
        </div>
      </div>

      {/* extra section  */}
      {/* <div className="mt-2 grid grid-cols-14">
        <div className="flex bg-sky-950 text-white p-5 justify-between items-center">
          <h1 className="text-2xl  font-bold uppercase">
            LoCOste : Uni Article unicq
          </h1>
          <div className="flex gap-5 text-xl items-center">
            <p>Enter Alert</p>
            <IoNotificationsOutline className="text-2xl" />
          </div>
        </div>
        <div className="px-5 py-5 pb-10 bg-sky-200 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
          <img
            className="w-full h-72"
            src="https://alala.ci/wp-content/uploads/2023/10/Affiche-nouvelle-saison-web-copie-1.png"
            alt=""
          ></img>
          <div className="flex flex-col justify-center">
            <p className=" text-justify">
              La nouvelle L003 Neo casse les codes du streetwear en mixant
              esprit sportif et design conceptuel. Sa silhouette audacieuse
              présente une empeigne en mesh respirante et des détails
              réfléchissants. La meilleure option pour vos week-end urbains. La
              nouvelle L003 Neo casse les codes du streetwear en mixant esprit
              sportif et design conceptuel. Sa silhouette audacieuse présente
              une empeigne en mesh respirante et des détails réfléchissants. La
              meilleure option pour vos week-end urbains.
            </p>
            <div className="mt-5 justify-end text-rose-600 flex items-center gap-3 text-xl capitalize ">
              <div>Discover more</div>
              <FaArrowRightLong className="text-2xl" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default UniverseProducts;
