import React from "react";

const WriteToUs = () => {
  return (
    <div className="px-5 md:px-10">
      <div>
        <img
          className="md:h-full h-32"
          src="https://alala.ci/wp-content/uploads/2023/10/Affiche-nouvelle-saison-web-copie-1.png"
          alt=""
        ></img>
      </div>

      <div className="my-10">
        <h1 className="text-2xl md:text-3xl font-bold">How can we help you?</h1>
        <p className="text-lg font-semibold mt-1">
          {" "}
          You can always consult our{" "}
          <span className="font-bold text-rose-500"> FAQ</span>{" "}
        </p>
      </div>

      <div className=" my-10 w-2/3 mx-auto">
        <div className="flex flex-col gap-4">
          <input
            placeholder="NAME & FIRST NAME*"
            className="border rounded w-full px-3 py-2 border-stone-600"
            type="text"
          ></input>
          <input
            placeholder="PHONE NUMBER (OPTIONAL)"
            className="border rounded w-full px-3 py-2 border-stone-600"
            type="number"
          ></input>
          <input
            placeholder="EMAIL ADDRESS*"
            className="border rounded w-full px-3 py-2 border-stone-600"
            type="email"
          ></input>
          <input
            placeholder="TOPIC*"
            className="border rounded w-full px-3 py-2 border-stone-600"
            type="text"
          ></input>
          <textarea
            placeholder="MESSAGE*"
            rows="6"
            cols="50"
            className="border rounded w-full px-3 py-2 border-stone-600"
            type="text"
          ></textarea>
        </div>
        <div className="text-center">
        <button className="bg-black py-2 mt-8 rounded text-lg hover:bg-stone-700 duration-300 text-white font-semibold w-2/3 mx-auto">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default WriteToUs;
