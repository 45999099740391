import React from "react";

const ReturnPolicy = () => {
  return (
    <div className="px-5 md:px-10">
      <div
        style={{
          backgroundImage: `url("https://alala.ci/wp-content/uploads/2022/11/xHeaderPagedeInfogeneral_1312x303-1.webp.pagespeed.ic.58fI5FzHdm.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h1 className="text-2xl md:text-4xl text-white py-10 font-bold text-center">
          Return Policy
        </h1>
      </div>

      <div className="my-10">
        <p className="leading-8">
          Are you not satisfied with a purchase? You have 14 working days to
          return your purchase from the date you received the item. You have the
          option to request a home pickup or drop off at the ALALA relay point
          closest to you by contacting us. Your funds* are refunded to you under
          conditions** within 7-10 days after receipt of the product at our
          premises.
        </p>

        <h1 className="font-bold my-5">*Return conditions:</h1>
        <ul className="flex ml-6 flex-col gap-2 list-disc">
          <li>Purchase period not exceeding 14 days.</li>
          <li>
            Have correctly followed the return procedure as indicated here
          </li>
          <li>Items eligible for return. Check here</li>
          <li>
            The item must be intact, in its original condition, in its
            packaging, with its accessories and instructions.
          </li>
          <li>It must not have been used</li>
        </ul>

        <p className="leading-8 mt-5">
          *Return costs will remain the responsibility of the Buyer or the
          recipient of the Order if it is the latter who exercises the
          contractual right of withdrawal whatever the reason, except in the
          event of return due to lack of conformity of the products delivered in
          relation to the Order or in the event of a damaged product.
        </p>
      </div>
    </div>
  );
};

export default ReturnPolicy;
