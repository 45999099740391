import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, Outlet, Link } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";
import "./UserDashboard.css";
import WebNavbar from "../../Shared/WebNavbar";
import Footer from "../../Shared/Footer";

const UserDashboard = () => {
  const { userInfo,logout } = AuthUser();

  const navWrapper = useRef();
  //hide sidenav by default
  const [issideNavOpen, setSidenavOpen] = useState(false);

  const navigate = useNavigate();

  //show sidenav on toggle
  const handleToggle = () => {
    issideNavOpen === true ? setSidenavOpen(false) : setSidenavOpen(true);
  };

  //close sidebar on click

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        issideNavOpen &&
        navWrapper.current &&
        !navWrapper.current.contains(event.target)
      ) {
        setSidenavOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    //return wrapper.current;
  }, [issideNavOpen]);
  useEffect(()=>{
    
    if (userInfo?.role === "user") {
    } else {
      if (userInfo) {
        logout();
      }
      navigate("/");
    }
  },[userInfo])

    console.log("check user role ",userInfo?.role)

  //active style for sidenav

  return (
    <>
      <section className="bg-gray-50 min-h-screen ">
        {/* dashboard main part */}
        <WebNavbar />
        <div className="md:w-11/12 w-full mx-auto mb-5 mt-5">
          <div className="">
            <Outlet />
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default UserDashboard;
