import React, { useEffect, useState } from "react";
import CardFashion from "./CardCategoryProduct";
import MultiRangeSlider from "multi-range-slider-react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import "../../App.css";
import useGetDataHook from "../../Hooks/useGetDataHook";
import { server_url } from "../../Config/API";
import { toast } from "react-toastify";
import Loading from "../../Shared/Loading";
import Pagination from "../../Shared/Pagination";


const CategoryProduct = ({ category }) => {
  const [range, setRange] = useState({ minValue: 0, maxValue: 100000 });
  const [showIconeCategories, setShowIconeCategories] = useState(true);
  const [order, setOrder] = useState(100);
  const [maxValue, setMaxValue] = useState(100000);
  const [minValue, setMinValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subCategorys] = useGetDataHook(
    `${server_url}/subCategory/getSubCategorys`
  );
  const [products, setProducts] = useState([]);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  const handleRangeChange = (newRange) => {
    setRange(newRange);
    setMaxValue(newRange.maxValue);
    setMinValue(newRange.minValue);
  };

  const [selectedCategorys, setSelectedCategorys] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    const isSelected = selectedCategorys.includes(value);
    setSelectedCategorys((prevSelectedItems) =>
      isSelected
        ? prevSelectedItems.filter((item) => item !== value)
        : [...prevSelectedItems, value]
    );
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const encodedNames = selectedCategorys.map(encodeURIComponent);
      const params = `${encodedNames.join(",")}`;
      // console.log(params)
      try {
        await fetch(
          `${server_url}/product/getProducts?fieldName=${"category"}&&fieldValue=${category}&&minPrice=${minValue}&&maxPrice=${maxValue}&&subCategory=${params}&&order=${order}&&size=${size}&&page=${page}`
        )
          .then((res) => res.json())
          .then((data) => {
            setQuantity(data?.total);
            setProducts(data?.data);
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        toast.error(err.message);
      }
    };
    fetchData();
  }, [category, selectedCategorys, minValue, maxValue, order, size, page]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className=" mx-5 my-10 border-b pb-5">
      <div className="flex flex-col gap-14 md:flex-row">
        <div className=" md:w-2/12 lg:border-r lg:pr-5">
          <div>
            <h1>Filter by price</h1>
            <div className="my-5">
              <MultiRangeSlider
                min={0}
                max={100000}
                minValue={minValue}
                maxValue={maxValue}
                step={1}
                ruler={false}
                label={false}
                range={range}
                onChange={handleRangeChange}
              />
              <div>
                Price: {minValue} CFA - {maxValue} CFA
              </div>
            </div>

            <div>
              {/* Categories  */}
              <div>
                <div className="flex justify-between items-center">
                  <h1 className=" font-semibold">Categories</h1>
                  <div
                    className="text-xl"
                    onClick={() => setShowIconeCategories(!showIconeCategories)}
                  >
                    {showIconeCategories ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </div>
                </div>
                {showIconeCategories && (
                  <div className="mt-2 grid grid-cols-1 grid grid-cols-1">
                    {subCategorys?.map((c) => (
                      <label key={c?.name} className="inline-flex items-center ">
                        <input
                          type="checkbox"
                          value={c?.name}
                          onChange={handleCheckboxChange}
                          checked={selectedCategorys.includes(c?.name)}
                          className="form-checkbox h-5 w-5 text-indigo-600"
                        />
                        <span className="ml-2 text-gray-700">{c?.name}</span>
                      </label>
                    ))}
                  </div>
                )}
                <div></div>
              </div>
            </div>
          </div>
        </div>

        {/* Product card side bar  */}
        <div className="md:w-10/12">
          <div>
            <div>
              <label className="text-sm font-semibold mr-2">Sort by: </label>
              <select
                name="order"
                type="number"
                onChange={(e) => setOrder(e.target.value)}
                className="border p-2 text-sm focus:outline-none mb-5 rounded"
              >
                <option value={100} selected={order === 100}>
                  None
                </option>
                <option value={1} selected={order === 1}>
                  Short by ascending price
                </option>
                <option value={-1} selected={order === -1}>
                  Short by descending price
                </option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-5 gap-y-16 md:grid-cols-3">
            {products?.map((product) => (
              <CardFashion product={product} />
            ))}
          </div>
          <Pagination
            quantity={quantity}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryProduct;
