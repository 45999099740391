import React from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link } from "react-router-dom";

const EventCard = ({event}) => {
  return (
    <Link to={`/event/${event?.name}`} className="bg-gray-100 rounded max-h-[400px] overflow-hidden">
      <div className="flex my-3 mx-5 justify-between items-center">
        <h2 className="text-lg font-semibold">
          End of sale{" "}
          <span className="text-rose-500">{event?.endDate}</span>
        </h2>
        <IoMdNotificationsOutline className="text-2xl text-rose-500" />
      </div>

      <img
        className="w-full hover:opacity-70 duration-300 rounded-b-md"
        src={event?.banner}
        alt={event?.name}
      ></img>
    </Link>
  );
};

export default EventCard;
