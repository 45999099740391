import React, { useEffect, useState } from "react";
import LargeAdvertisingCard from "./LargeAdvertisingCard";

const AdvertisingLarge = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`https://alala.niroghealthplus.com/api/v1/ad/getAds`)
      .then((res) => res?.json())
      .then((data) => {
        if (data?.data?.length) {
          setData(data?.data[0]);
        } else {
          setData([]);
        }
      });
  }, []);
  return (
    <div className=" px-5">
      <LargeAdvertisingCard data={data}></LargeAdvertisingCard>
    </div>
  );
};

export default AdvertisingLarge;
