import React from "react";
import useGetDataHook from "../../../Hooks/useGetDataHook";
import { server_url } from "../../../Config/API";
import { Link } from "react-router-dom";

const CatagoriesProduct = () => {
  const [universe] = useGetDataHook(`${server_url}/universe/getUniverses`);
  return (
    <div className="mx-5 md:mx-10 my-10 md:my-20">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-5 lg:grid-cols-4">
        {universe?.slice(0, 8)?.map((c) => (
          <Link
            to={`/universe/${c?.name}`}
            style={{ backgroundImage: `url(${c?.banner})` }}
            key={c?._id}
            className="bg-purple-300 w-full rounded-xl flex gap-2 items-center"
          >
            <img
              className="h-[80px] md:h-[92px] object-cover w-32 rounded-xl p-[6px]"
              src={c?.icon}
              alt=""
            ></img>
            <p className="text-lg md:text-xl font-bold pr-2">{c?.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CatagoriesProduct;
