import { server_url } from "../Config/API";

export const singleImageUpload = async (formData, setMyImageUrl) => {
  const imageBaseUrl = `${server_url}/upload/single-image-upload`;
  console.log("imageUrl: ", imageBaseUrl);

  fetch(imageBaseUrl, {
    method: "POST",
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      console.log("Data: ", data);
      if (data.status === "success") {
        console.log("Response:", data);
        setMyImageUrl(data.url);
      }
    });
};
export const colorAdnShadeImg = async (formData, myData,setData,index) => {
  const imageBaseUrl = `${server_url}/upload/single-image-upload`;

  fetch(imageBaseUrl, {
    method: "POST",
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      console.log("Data: ", data);
      if (data?.status === "success") {
        console.log("Response:", data);
        let newData = [...myData];
        newData[index].img = data?.url;
        setData(newData);
      }
    });
};
