import React from "react";
import { Link } from "react-router-dom";

const CardCategoryProduct = ({product}) => {
  return (
    <div className="w-full bg-white shadow-md max-w-sm">
      <Link to={`/productDetails/${product?._id}`}>
        <div
          className="w-full rounded-t mb-5 relative"
        >
          <img className="w-full h-48 object-cover" src={product?.primaryImg} alt="img"></img>
          <p className="absolute start-0 top-4 z-10    bg-red-500 px-3 py-0.5 text-white transition" > NEW</p>
        </div>
      </Link>
      <div>
        <h1 className="px-5 md:px-0 text-center font-semibold">
          {product?.productName}
        </h1>
        <div className="px-10 md:px-5">
          <div className="flex mt-3 justify-between items-center">
            <div className="flex font-bold  text-rose-600">
              <p className="text-2xl">{product?.promotionalPrice} </p>
              <span>F</span>
            </div>

            <div className="flex font-semibold text-lime-700">
              <p className="text-4xl">{(((product?.marketPrice-product?.promotionalPrice)/product?.marketPrice)*100).toFixed(0)}</p>
              <span>% Off</span>
            </div>
          </div>

          <div className="flex text-stone-500 font-semibold ">
            <p className=" line-through">{product?.marketPrice}</p>
            <span>F</span>
          </div>

          <div className="text-center my-5">
            <Link to={`/productDetails/${product?._id}`} className=" font-semibold text-white uppercase block w-full py-3 bg-rose-500 hover:scale-95 duration-300 hover:bg-rose-600">
              Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCategoryProduct;
