import React, { useEffect, useState } from "react";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcPaypal,
  FaCcStripe,
  FaCcApplePay,
} from "react-icons/fa";
import cod from "../../Assets/cod_icon.webp";
import { toast } from "react-toastify";
import axios from "axios";
import AuthUser from "../../Hooks/authUser";
import { server_url } from "../../Config/API";
import moment from "moment";
import useGetDataHook from "../../Hooks/useGetDataHook";

const InformationFrom = ({ order, refetch, setRefetch, price, setShow }) => {
  const { userInfo } = AuthUser();
  const [location, setLocation] = useState("");
  const [shipping, setShipping] = useState([]);
  useEffect(() => {
    fetch(
      `${server_url}/shipping/specific/?fieldName=location&&fieldValue=${location}`
    ).then((res) =>
      res.json().then((data) => {
        if (data?.data?.length) {
          setShipping(data?.data[0]);
        } else {
          setShipping([]);
        }
      })
    );
  }, [location]);
  const [lok] = useGetDataHook(`${server_url}/shipping/specific`);

  let date = moment().format("YYYY-MM-D");
  const [cashOn, setCashOn] = useState(false);
  const [pay, setPay] = useState(true);
  const [standard, setStandard] = useState(true);
  const [express, setExpress] = useState(false);
  const [nextDay, setNextDay] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    const exist = JSON.parse(localStorage.getItem("alalaOrder"));
    let total = exist?.reduce(
      (acc, item) => acc + item?.promotionalPrice * item?.quantity,
      0
    );

    if (total) {
      setTotal(total);
    }
  }, [refetch]);

  // get coupon data
  useEffect(() => {
    async function fetchCoupons() {
      try {
        const response = await axios.get(
          "https://alala.niroghealthplus.com/api/v1/coupon/getCoupons"
        );

        setCoupons(response?.data?.data);
      } catch (err) {
        toast.error(err.message);
      }
    }

    fetchCoupons();
  }, []);

  // applied discount
  const handelDiscount = () => {
    const exist = coupons.find((c) => c.code === coupon);
    if (order?.length) {
      let totalPrice = order?.reduce(
        (acc, item) => acc + item?.promotionalPrice * item?.quantity,
        0
      );

      if (Number(exist?.discount)) {
        setTotal(totalPrice - (totalPrice * Number(exist?.discount)) / 100);
        setDiscount((totalPrice * Number(exist?.discount)) / 100);
        toast.success("coupon applied");
      } else {
        toast?.error("coupon doesn't match");
      }
    } else {
      toast?.error("error");
    }
  };

  const vat = ((total + discount) * shipping?.vat) / 100;
  const payableAmount = standard
    ? Number(shipping?.standard) + Number(total) + Number(vat)
    : (express?Number(shipping?.express) + Number(total) + Number(vat):Number(shipping?.nextDay) + Number(total) + Number(vat));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const firstName = e?.target?.firstName?.value;
    const lastName = e?.target?.lastName?.value;
    const phone = e?.target?.phone?.value;
    const email = e?.target?.email?.value;
    const country = e?.target?.country?.value;
    const address = e?.target?.address?.value;
    const town = e?.target?.town?.value;
    const message = e?.target?.message?.value;

    if (!address) {
      return toast.error(
        "Please select your address"
      );
    }

    if (pay && payableAmount < 101) {
      return toast.error(
        "Minimum payable amount 101 please select cash on delivery"
      );
    }

  
    const user = { firstName,lastName, phone, email, country, address,town,message };
    const data = {
      userId: userInfo?._id,
      user,
      products: order,
      onlinePay: pay,
      totalAmount: payableAmount,
      date,
      deliveryMethod:standard? 'Standard Delivery':(nextDay?'Next Day Delivery':'Express Delivery'),
    };

    if (!total) {
      console.log(total)
      toast.error("Something wrong");
    } else {
      try {
        const response = await axios.post(
          `${server_url}/order/addOrders`,
          data
        );

        if (response.status !== 200) {
          throw new Error(
            `Failed to submit order. Server responded with ${response.status} ${response.statusText}`
          );
        }

        const responseData = response.data;
        console.log("Response Data:", responseData);

        if (responseData?.status === "success") {
          console.log(responseData);

          if (
            responseData?.status === "success" &&
            responseData.data.redirectUrl
          ) {
            const paymentRedirectUrl = responseData.data.redirectUrl;
            console.log("Redirecting to:", paymentRedirectUrl);

            window.location.href = paymentRedirectUrl;
          }

          localStorage.removeItem("alalaOrder");
          toast.success("Order submitted!");
          setRefetch(!refetch);
          setShow("success");
        }
      } catch (error) {
        console.error("Error submitting order:", error);

        // Check if error.response is available
        if (error.response) {
          // Log the server response text
          console.log("Server Response:", await error.response.text());
        } else {
          console.error(
            "Server Response not available. Possibly a network error or CORS issue."
          );
        }

        // Handle the error as needed
        toast.error("Failed to submit order. Please try again later.");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="px-5 md:px-10">
      <h1 className="text-2xl font-bold">Address</h1>
      <div className="border py-5 md:py-10 p-5 md:p-16 rounded mt-5 bg-white  flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <label className="text-lg font-semibold">
            First Name<span className="text-red-500 font-bold"> *</span>
          </label>
          <input
            type="text"
            name="firstName"
            required
            placeholder="type your firstName"
            className="p-3 border-2 rounded focus:outline-none"
          ></input>
        </div>
        <div className="flex flex-col gap-3">
          <label className="text-lg font-semibold">
           Last Name<span className="text-red-500 font-bold"> *</span>
          </label>
          <input
            type="text"
            name="lastName"
            required
            placeholder="type your lastName"
            className="p-3 border-2 rounded focus:outline-none"
          ></input>
        </div>
        <div className="flex flex-col gap-3">
          <label className="text-lg font-semibold">
            Phone Number <span className="text-red-500 font-bold"> *</span>
          </label>
          <input
            type="text"
            required
            name="phone"
            placeholder="type your number"
            className="p-3 border-2 rounded focus:outline-none"
          ></input>
        </div>
        <div className="flex flex-col gap-3">
          <label className="text-lg font-semibold">
            Email Address (Optional)
          </label>
          <input
            type="email"
            name="email"
            placeholder="type your email"
            className="p-3 border-2 rounded focus:outline-none"
          ></input>
        </div>
        <div className="flex flex-col gap-3">
          <label className="text-lg font-semibold">
            Country<span className="text-red-500 font-bold"> *</span>
          </label>
          <input
            type="text"
            required
            name="country"
            placeholder="type your country"
            className="p-3 border-2 rounded focus:outline-none"
          ></input>
        </div>
        <div className="flex flex-col gap-3">
          <label className="text-lg font-semibold">
           City
            <span className="text-red-500 font-bold"> *</span>
          </label>
          <select
            onChange={(e) => setLocation(e.target.value)}
            name="address"
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" selected disabled>
              {" "}
              select
            </option>
            {lok?.map((c) => (
              <option value={c?.location}> {c?.location}</option>
            ))}
          </select>
        </div>

        <div className="flex flex-col gap-3">
          <label className="text-lg font-semibold">
          Address (Town, District)<span className="text-red-500 font-bold"> *</span>
          </label>
          <input
            type="text"
            required
            name="town"
            placeholder="type your address"
            className="p-3 border-2 rounded focus:outline-none"
          ></input>
        </div>

        <div className="flex flex-col gap-3">
          <label className="text-lg font-semibold">
          Your message<span className="text-red-500 font-bold"> Optional</span>
          </label>
          <textarea  type="text"
           
            name="message"
            placeholder="type your message"
            className="p-3 border-2 rounded focus:outline-none" />
         
        </div>


      </div>

      {/* Delivery method  */}
      <h1 className="text-2xl mb-5 rounded mt-10 font-bold">Delivery Method</h1>
      <div className="md:mr-10 bg-white p-5 md:p-10 rounded mt-5 md:mt-10">
        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-4 md:gap-2 text-xl">
            <input
              checked={standard}
              onClick={(e) => {
                setStandard(true);
                setExpress(false);
                setNextDay(false);
              }}
              className={`${
                standard
                  ? " bg-emerald-700 border-emerald-700 outline-none"
                  : "bg-white border-slate-500"
              } w-6 h-6 transition-colors  border-2 rounded-full appearance-none cursor-pointer peer  focus-visible:outline-none disabled:cursor-not-allowed `}
              type="radio"
              value="huey"
              id="huey"
              name="drone"
            />
            <h3 className="font-bold">Standard Delivery</h3>
            <p>{shipping?.standardTime}</p>
          </div>
          <div className="flex items-center gap-2 text-xl">
            <input
              checked={express}
              onClick={(e) => {
                setExpress(true);
                setStandard(false);
                setNextDay(false);
              }}
              className={`${
                express
                  ? " bg-emerald-700 border-emerald-700 outline-none"
                  : "bg-white border-slate-500"
              } w-6 h-6 transition-colors  border-2 rounded-full appearance-none cursor-pointer peer  focus-visible:outline-none disabled:cursor-not-allowed `}
              type="radio"
              value="huey"
              id="huey"
              name="drone"
            />
            <h3 className="font-bold">Express Delivery:</h3>
            <p>{shipping?.expressTime}</p>
          </div>
          <div className="flex items-center gap-2 text-xl">
            <input
              checked={nextDay}
              onClick={(e) => {
                setExpress(false);
                setStandard(false);
                setNextDay(true);
              }}
              className={`${
                nextDay
                  ? " bg-emerald-700 border-emerald-700 outline-none"
                  : "bg-white border-slate-500"
              } w-6 h-6 transition-colors  border-2 rounded-full appearance-none cursor-pointer peer  focus-visible:outline-none disabled:cursor-not-allowed `}
              type="radio"
              value="huey"
              id="huey"
              name="drone"
            />
            <h3 className="font-bold">Next Day Delivery:</h3>
            
          </div>
        </div>
      </div>

      {/* payment method  */}
      <div>
        <h1 className="text-2xl mb-5 mt-10 font-bold">Payment Method</h1>
        <div className="border rounded-md mt-3 bg-white">
          {/* select btn */}
          <fieldset class="flex flex-col gap-3 p-3">
            <div class="relative flex items-center">
              <input
                checked={pay}
                onClick={(e) => {
                  setPay(true);
                  setCashOn(false);
                }}
                className={`${
                  pay
                    ? " bg-emerald-700 border-emerald-700 outline-none"
                    : "bg-white border-slate-500"
                } w-6 h-6 transition-colors  border-2 rounded-full appearance-none cursor-pointer peer  focus-visible:outline-none disabled:cursor-not-allowed `}
                type="radio"
                value="dewey"
                id="dewey"
                name="drone"
              />
              <label
                class="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 w-[80%]"
                for="huey"
              >
                <div className="flex flex-col md:flex-row my-5 gap-2 md:gap-5  md:items-center">
                  <div className="bg-white w-full md:w-40 px-2 md:px-4 mt-2 py-1 gap-2 flex justify-evenly rounded-lg">
                    <FaCcVisa className="text-4xl text-[#283392] "></FaCcVisa>
                    <FaCcMastercard className="text-4xl text-[#C91820]"></FaCcMastercard>
                    <FaCcPaypal className="text-4xl text-[#24377A]"></FaCcPaypal>
                  </div>
                  <div className="bg-white w-full md:w-40 px-2 md:px-4 mt-2 gap-2 py-1 flex justify-evenly rounded-lg">
                    <FaCcStripe className="text-4xl text-[#FDD226]"></FaCcStripe>
                    <FaCcApplePay className="text-4xl text-[#EB6608]"></FaCcApplePay>
                    <FaCcPaypal className="text-4xl text-pink-600"></FaCcPaypal>
                  </div>
                </div>
              </label>
              <svg
                class="absolute left-0 w-6 h-6 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-white peer-checked:scale-100 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-labelledby="title-2 description-2"
                role="graphics-symbol"
              >
                <circle cx="8" cy="8" r="4" />
              </svg>
            </div>
          {
            shipping?.cashOnDelivery==="yes"&&  <div class="relative flex items-center">
            <input
              checked={cashOn}
              onClick={(e) => {
                setCashOn(true);
                setPay(false);
              }}
              className={`${
                cashOn
                  ? " bg-emerald-700 border-emerald-700 outline-none"
                  : "bg-white border-slate-500"
              } w-6 h-6 transition-colors  border-2 rounded-full appearance-none cursor-pointer peer  focus-visible:outline-none disabled:cursor-not-allowed `}
              type="radio"
              value="huey"
              id="huey"
              name="drone"
            />
            <label
              class="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 w-[80%]"
              for="huey"
            >
              <img className="max-w-sm" src={cod} alt="img"></img>
            </label>
            <svg
              class="absolute left-0 w-6 h-6 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-white peer-checked:scale-100 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="title-1 description-1"
              role="graphics-symbol"
            >
              <circle cx="8" cy="8" r="4" />
            </svg>
          </div>
          }
          </fieldset>
          {/* select btn */}
        </div>
      </div>

      <div>
        <h1 className="text-2xl mb-5 mt-10 font-bold">Your Order</h1>
        <div className="bg-white md:p-10 p-5">
          {order?.map((o) => (
            <div
              key={o?._id}
              className=" grid grid-cols-2 gap-5 border-b-2 pb-3 mt-3"
            >
              <div className="text-lg">
                <h3>{o?.productName}</h3>
                <p>{o?.promotionalPrice}</p>
                <p>{o?.category}</p>
                <p>{o?.quantity}</p>
              </div>
              <div>
                <h1 className="text-2xl font-semibold">
                  Total: {o?.promotionalPrice * o?.quantity}
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white md:p-10 p-5 mt-5">
        <div className=" grid grid-cols-2 gap-5 text-2xl font-semibold ">
          <div className="space-y-1">
            <h3>Sub-Total</h3>
            <p>Delivery Charge</p>
            <p>Vat</p>
            <p>Total</p>
          </div>
          <div className="space-y-1">
            <h1 className="">{price}</h1>
            <h1 className="">
              {standard ? shipping?.standard :(express? shipping?.express: shipping?.nextDay)}
            </h1>
            <h1 className="">{shipping?.vat} %</h1>
            <h1 className="">{payableAmount}</h1>
          </div>
        </div>
      </div>

      {/* promo code  */}
      <div className=" bg-white rounded px-8 pb-8">
        <h1 className="text-xl mb-5 font-semibold">Coupon Code (optional)</h1>
        <div className="flex items-center gap-5">
          <input
            placeholder="enter coupon"
            onChange={(e) => setCoupon(e.target.value)}
            className="border-2  w-full p-3 rounded-md focus:outline-none"
          ></input>
          <div
            onClick={handelDiscount}
            className="bg-rose-500 rounded-md px-5 py-3 text-white font-semibold hover:bg-rose-600 hover:scale-105 duration-500 cursor-pointer"
          >
            Apply
          </div>
        </div>
      </div>

      <button
        type="submit"
        className="bg-rose-500 py-3 w-full hover:scale-95 duration-500 block mx-auto mt-5 rounded-md text-lg text-white font-bold"
      >
        ORDER
      </button>
    </form>
  );
};

export default InformationFrom;
