import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import PostHooks from "../../../Hooks/PostHooks";
import { singleImageUpload } from "../../../Hooks/ImageUpload";

const AdminAd = () => {
  const [firstAdImg, setFirstAdImg] = useState("");
  const [secondAdImg, setSecondAdImg] = useState("");
  const [thirdAdImg, setThirdAdImg] = useState("");

  const [formData, setFormData] = useState({
    firstAdTitle: "",
    firstAdDes: "",
    firstAdLink: "",
    secondAdTitle: "",
    secondAdDes: "",
    secondAdLink: "",
    thirdAdTitle: "",
    thirdAdDes: "",
    thirdAdLink: "",
  });

  console.log(formData)
  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);

    if (event?.target?.name === "firstAdImg") {
      singleImageUpload(formData, setFirstAdImg);
    }
    if (event?.target?.name === "secondAdImg") {
      singleImageUpload(formData, setSecondAdImg);
    }
    if (event?.target?.name === "thirdAdImg") {
      singleImageUpload(formData, setThirdAdImg);
    }
  };

  //   load data
  useEffect(() => {
    fetch(`https://alala.niroghealthplus.com/api/v1/ad/getAds`)
      .then((res) => res?.json())
      .then((data) => {
        if (data?.data?.length) {
          setFormData(data?.data[0]);
          setFirstAdImg(data?.data[0]?.firstAdImg);
          setSecondAdImg(data?.data[0]?.secondAdImg);
          setThirdAdImg(data?.data[0]?.thirdAdImg);
        }
      });
  }, []);

  // update and post data
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formData?._id) {
        await UpdateHooks(
          `https://alala.niroghealthplus.com/api/v1/ad/updateAds/${formData?._id}`,
          { ...formData, firstAdImg, secondAdImg, thirdAdImg }
        );
        toast?.success(`Ad section Updated !`);
      } else {
        await PostHooks(
          `https://alala.niroghealthplus.com/api/v1/ad/addAds`,
          { ...formData, firstAdImg, secondAdImg, thirdAdImg },
          `Ad data posted`
        );
      }
    } catch (error) {
      toast?.success(error);
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="bg-white">
      <div className="w-fit  text-xl font-semibold mb-5">
        <h1>Update Ad Section</h1>
        <div className="h-1 mt-1 bg-secondary w-[40%]"></div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 p-6 rounded shadow-md w-full mx-auto "
      >
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              htmlFor="name"
            >
              First Ad Title
            </label>
            <input
              type="text"
              name="firstAdTitle"
              required
              onChange={handleChange}
              value={formData.firstAdTitle}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              htmlFor="name"
            >
              First Ad Link
            </label>
            <input
              type="text"
              name="firstAdLink"
              required
              onChange={handleChange}
              value={formData.firstAdLink}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              First Ad Img
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="firstAdImg"
                type="file"
              />
              <img className="w-12 rounded-md" src={firstAdImg} alt="img"></img>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              htmlFor="name"
            >
              First Ad Description
            </label>
            <input
              type="text"
              name="firstAdDes"
              required
              onChange={handleChange}
              value={formData.firstAdDes}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              htmlFor="name"
            >
              Second Ad Title
            </label>
            <input
              type="text"
              name="secondAdTitle"
              required
              onChange={handleChange}
              value={formData.secondAdTitle}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              htmlFor="name"
            >
              Second Ad Link
            </label>
            <input
              type="text"
              name="secondAdLink"
              required
              onChange={handleChange}
              value={formData.secondAdLink}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              second Ad Img
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="secondAdImg"
                type="file"
              />
              <img
                className="w-12 rounded-md"
                src={secondAdImg}
                alt="img"
              ></img>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              htmlFor="name"
            >
              Second Ad Description
            </label>
            <input
              type="text"
              name="secondAdDes"
              required
              onChange={handleChange}
              value={formData.secondAdDes}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              htmlFor="name"
            >
              Third Ad Title
            </label>
            <input
              type="text"
              name="thirdAdTitle"
              required
              onChange={handleChange}
              value={formData.thirdAdTitle}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              htmlFor="name"
            >
              Third Ad Link
            </label>
            <input
              type="text"
              name="thirdAdLink"
              required
              onChange={handleChange}
              value={formData.thirdAdLink}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Third Ad Img
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="thirdAdImg"
                type="file"
              />
              <img className="w-12 rounded-md" src={thirdAdImg} alt="img"></img>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              htmlFor="name"
            >
              Third Ad Description
            </label>
            <input
              type="text"
              name="thirdAdDes"
              required
              onChange={handleChange}
              value={formData.thirdAdDes}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="bg-secondary text-white px-4 py-2 rounded hover:bg-secondary/90 hover:scale-105 duration-300"
          >
            Update Ad !
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminAd;
