import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { useParams } from "react-router-dom";

const AdminShipping = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    standard: 0,
    express: 0,
    nextDay: 0,
    location: "",
    standardTime: "",
    expressTime: "",
    cashOnDelivery: "",
    vat: 0,
  });

  //   load data
  useEffect(() => {
    fetch(`https://alala.niroghealthplus.com/api/v1/shipping/getShippingsById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setFormData(data?.data);
      });
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData?._id) {
      await UpdateHooks(
        `https://alala.niroghealthplus.com/api/v1/shipping/updateShippings/${formData?._id}`,
        formData
      );
      toast?.success(`Shipping data Updated !`);
    } else {
      try {
        const response = await axios.post(
          "https://alala.niroghealthplus.com/api/v1/shipping/addShippings",
          formData
        );
        toast.success("Shipping posted!");
        console.log("POST request successful:", response);
      } catch (error) {
        toast?.error(error);
        console.error("Error making POST request:", error);
      }
    }
  };

  const percentese = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100,
  ];

  return (
    <div>
      <div class=" ">
        <h1 class="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          Update Shipping
        </h1>
        <p class="text-lg text-gray-800 mb-8">
          update essential shipping for health . Act now to secure the latest
          items
        </p>
      </div>

      <form
        onSubmit={handleSubmit}
        className="shadow-lg shadow-gray-300 px-8 py-10 rounded flex flex-col gap-4 text-left"
      >
        {/* date */}

        <div className="grid gap-5 md:grid-cols-2 grid-cols-1 items-center">
          <div className="mb-1  w-full  mr-0 md:mr-2">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              Delivery Location
            </label>
            <input
              type="text"
              name="location"
              value={formData?.location}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            />
          </div>
          <div className="mb-1  w-full  mr-0 md:mr-2">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              Standard Delivery Charge
            </label>

            <input
              type="number"
              name="standard"
              value={formData?.standard}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            />
          </div>
          <div className="mb-1  w-full  mr-0 md:mr-2">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              Standard Delivery Time
            </label>

            <input
              type="text"
              name="standardTime"
              value={formData?.standardTime}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            />
          </div>

          <div className="mb-1  w-full  mr-0 md:mr-2">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              Express Delivery Charge
            </label>

            <input
              type="number"
              name="express"
              value={formData?.express}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            />
          </div>
          <div className="mb-1  w-full  mr-0 md:mr-2">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              Express Delivery Time
            </label>

            <input
              type="text"
              name="expressTime"
              value={formData?.expressTime}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            />
          </div>

          <div className="mb-1  w-full  mr-0 md:mr-2">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              Next Day Delivery Charge
            </label>

            <input
              type="number"
              name="nextDay"
              value={formData?.nextDay}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none  block w-full p-2.5 focus:border-none"
            />
          </div>
        </div>

        <div className="mb-1  w-full  mr-0 md:mr-2">
          <label
            for="repeat-password"
            class="block mb-2 text-[13px] font-normal text-gray-900 "
          >
            Vat (%)
          </label>
          <select
            name="vat"
            type="number"
            value={formData?.vat}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5    focus:border-blue-500"
          >
            <option value="" disabled selected>
              select
            </option>

            {percentese?.map((cat, index) => (
              <option key={index} cat={cat} value={cat}>
                {cat}%
              </option>
            ))}
          </select>
        </div>
        <div className="mb-1  w-full  mr-0 md:mr-2">
          <label
            for="repeat-password"
            class="block mb-2 text-[13px] font-normal text-gray-900 "
          >
            Cash On Delivery
          </label>
          <select
            name="cashOnDelivery"
            type="text"
            value={formData?.cashOnDelivery}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5    focus:border-blue-500"
          >
            <option value="" disabled selected>
              select one
            </option>

            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        <div className="text-center pt-3">
          <button
            className="bg-primary2 hover:bg-lightPrimary text-white  py-2 rounded-lg text-lg w-fit px-8"
            type="submit"
          >
            Update Shipping
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminShipping;
