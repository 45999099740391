import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useRef } from "react";
import JoditEditor from "jodit-react";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import PostHooks from "../../../Hooks/PostHooks";
import { singleImageUpload } from "../../../Hooks/ImageUpload";

const AdminInfo = () => {
  const editor = useRef(null);
  const [img, setImg] = useState("");
  const [formData, setFormData] = useState({
    aboutUs: "",
    payment: "",
    sales: "",
    pressAndBrand: "",
    ourCustomerApproach: "",
    vvipClub: "",
    legalNotice: "",
    gtc: "",
    privacyPolicy: "",
    recruitment: "",
    becomeAPartner: "",
    delivery: "",
  });
console.log(formData)

  useEffect(() => {
    fetch(`https://alala.niroghealthplus.com/api/v1/generalInfo/getGeneralInfos`)
      .then((res) => res.json())
      .then((data) => {
        if(data?.data?.length){
            setFormData(data?.data[0]);
            setImg(data?.data?.[0]?.banner);
        }
      });
  }, [formData?._id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formData?._id) {
        await UpdateHooks(
          `https://alala.niroghealthplus.com/api/v1/generalInfo/updateGeneralInfos/${formData?._id}`,
          {
            ...formData,
            banner: img,

          }
        );
        toast?.success(`GeneralInfo section Updated !`);
      } else {
        await PostHooks(
          `https://alala.niroghealthplus.com/api/v1/generalInfo/addGeneralInfos`,
          {
            ...formData,
            banner: img,

          },
          `GeneralInfo data posted`
        );
      }
    } catch (error) {
      toast?.success(error);
    }
  };
  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    singleImageUpload(formData, setImg);
  };

  return (
    <div className="bg-white">
      <div className="w-fit  text-xl font-semibold mb-5">
        <h1>Update GeneralInfo Section</h1>
        <div className="h-1 mt-1 bg-secondary w-[40%]"></div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 p-6 rounded shadow-md w-full mx-auto"
      >
        <div className="mb-4 ">
          <label className="block mb-2 text-sm font-medium text-gray-600">
            Banner Img
          </label>
          <div className="flex gap-4">

          <input
            type="file"
            name="banner"
            onChange={(event) => handleChangeUploadImage(event, 0)}
            className="w-full px-3 py-2 border rounded-md"
          />
          <img
            className="w-[70px] rounded-md shadow-lg mt-2"
            src={img}
            alt="img-1"
          />

          </div>
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
            About Us
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.aboutUs}
            onChange={(val) => handleChange("aboutUs", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          Payment
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.payment}
            onChange={(val) => handleChange("payment", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          Sales
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.sales}
            onChange={(val) => handleChange("sales", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          Press And Brand
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.pressAndBrand}
            onChange={(val) => handleChange("pressAndBrand", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          Our Customer Approach
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.ourCustomerApproach}
            onChange={(val) => handleChange("ourCustomerApproach", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          VVIP Club
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.vvipClub}
            onChange={(val) => handleChange("vvipClub", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          Legal Notice
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.legalNotice}
            onChange={(val) => handleChange("legalNotice", val)}
          />
        </div>
    
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          Gtc
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.gtc}
            onChange={(val) => handleChange("gtc", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          Privacy Policy
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.privacyPolicy}
            onChange={(val) => handleChange("privacyPolicy", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          Recruitment
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.recruitment}
            onChange={(val) => handleChange("recruitment", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          Become A Partner
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.becomeAPartner}
            onChange={(val) => handleChange("becomeAPartner", val)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
          delivery
          </label>
          <JoditEditor
            ref={editor}
            value={formData?.delivery}
            onChange={(val) => handleChange("delivery", val)}
          />
        </div>

        <div>
          <button
            type="submit"
            className="bg-secondary text-white px-4 py-2 rounded hover:bg-secondary/90 hover:scale-105 duration-300"
          >
            Update GeneralInfo !
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminInfo;
