import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css";
import MyAccount from "./MyAccount";
import Orders from "./Orders";
import Returns from "./Returns";
import Advantage from "./Advantage";
import Mynotification from "./Mynotification";
import AuthUser from "../../Hooks/authUser";
import ChangePassword from "./ChangePassword";

const AccountPage = () => {
  const { userInfo } = AuthUser();

  const [show, setShow] = useState("myAccount");

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    navigator:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className=" bg-white py-5 shadow rounded-md">
      <h2 className="text-2xl mb-5 text-center">Hello, <span className="font-semibold">{userInfo?.name}</span></h2>
      <div className="md:mx-5 mx-2 py-3 rounded-md shadow-sm md:px-5 px-2 border">
        <Slider {...settings}>
          <button onClick={()=>setShow("myAccount")} className={`${show==="myAccount"?"bg-rose-500 text-white  rounded-md border":"bg-white rounded-md border"} !w-fit block py-2 px-5`}>My Account</button>
          <button onClick={()=>setShow("orders")}className={`${show==="orders"?"bg-rose-500 text-white  rounded-md border":"bg-white rounded-md border"} !w-fit block py-2 px-5`}>Orders</button>
          <button onClick={()=>setShow("returns")} className={`${show==="returns"?"bg-rose-500 text-white  rounded-md border":"bg-white rounded-md border"} !w-fit block py-2 px-5`}>Returns</button>
          <button onClick={()=>setShow("my advantage")}className={`${show==="my advantage"?"bg-rose-500 text-white  rounded-md border":"bg-white rounded-md border"} !w-fit block py-2 px-5`}>My Average</button>
          <button onClick={()=>setShow("changePassword")} className={`${show==="changePassword"?"bg-rose-500 text-white  rounded-md border":"bg-white rounded-md border"} !w-fit block py-2 px-5`}>Change Password</button>
          <button onClick={()=>setShow("my notification")} className={`${show==="my notification"?"bg-rose-500 text-white  rounded-md border":"bg-white rounded-md border"} !w-fit block py-2 px-5`}>My notifications</button>
          {/* Add more items as needed */}
        </Slider>
      </div>



      <div>
        {show === "myAccount" && <MyAccount></MyAccount>}
        {show === "orders" && <Orders></Orders>}
        {show === "returns" && <Returns></Returns>}
        {show === "changePassword" && <ChangePassword></ChangePassword>}
        {show === "my advantage" && <Advantage></Advantage>}
        {show === "my notification" && <Mynotification></Mynotification>}

      </div>
    </div>
  );
};

export default AccountPage;
