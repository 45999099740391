import AddBrand from "../Components/AdminDashboard/AdminBrand/AddBrand";
import AllBrand from "../Components/AdminDashboard/AdminBrand/AllBrand";
import UpdateBrand from "../Components/AdminDashboard/AdminBrand/UpdateBrand";
import AddCategory from "../Components/AdminDashboard/AdminCategory/AddCategory";
import AllCategory from "../Components/AdminDashboard/AdminCategory/AllCategory";
import UpdateCategory from "../Components/AdminDashboard/AdminCategory/UpdateCategory";
import AddCoupon from "../Components/AdminDashboard/AdminDashboard/Coupon/AddCoupon";
import AllCoupon from "../Components/AdminDashboard/AdminDashboard/Coupon/AllCoupon";
import AdminShipping from "../Components/AdminDashboard/AdminShipping/AdminShipping";
import UpdateCoupon from "../Components/AdminDashboard/AdminDashboard/Coupon/updateCoupon";

import AddSubCategory from "../Components/AdminDashboard/AdminSubCategory/AddSubCategory";
import AllSubCategory from "../Components/AdminDashboard/AdminSubCategory/AllSubCategory";
import UpdateSubCategory from "../Components/AdminDashboard/AdminSubCategory/UpdateSubCategory";
import AllUser from "../Components/AdminDashboard/AllUser/AllUser";
import AdminAllBlog from "../Components/AdminDashboard/Blog/AdminAllBlog";
import AdminBlog from "../Components/AdminDashboard/Blog/AdminBlog";
import AllNewsLatter from "../Components/AdminDashboard/NewsLatter/AllNewsLatter";
import AddProduct from "../Components/AdminDashboard/Product/AddProduct";
import AllProducts from "../Components/AdminDashboard/Product/AllProducts";
import AddEvent from "../Components/AdminDashboard/AdminEvent/AddEvent";
import AllEvent from "../Components/AdminDashboard/AdminEvent/AllEvent";
import UpdateEvent from "../Components/AdminDashboard/AdminEvent/UpdateEvent";
import AdminOrders from "../Pages/AdminDashboard/Admin Order/AdminOrder";
import AdminDashboardSliderImg from "../Components/AdminDashboard/AdminDashboardSliderImg/AdminDashboardSliderImg";
import AdminFooter from "../Components/AdminDashboard/AdminFooter/AdminFooter";
import AdminInfo from "../Components/AdminDashboard/AdminInfo/AdminInfo";
import AdminDashboardIndex from "../Components/AdminDashboard/AdminDashboard/AdminDashboardIndex";
import ChangePassword from "../Pages/AccountPage/ChangePassword";
import AddUniverse from "../Components/AdminDashboard/AdminUniverse/AddUniverse";
import AllUniverse from "../Components/AdminDashboard/AdminUniverse/AllUniverse";
import UpdateUniverse from "../Components/AdminDashboard/AdminUniverse/UpdateUniverse";
import AdminAllShipping from "../Components/AdminDashboard/AdminShipping/AdminAllShipping";
import UserOrders from "../Components/UserDashboard/MyOrder/UserOrders/UserOrders";
import AdminAd from "../Components/AdminDashboard/AdminAd/AdminAd";
import AdminFaq from "../Components/AdminDashboard/AdminFaq/AdminFaq";
import AdminAdCarousel from "../Components/AdminDashboard/AdminAd/AdminAdCarousel";


const AdminRoutes = [
  { path: "dashboard", Component: AdminDashboardIndex },
  { path: "changePassword", Component: ChangePassword },
  { path: "add-product", Component: AddProduct },
  { path: "allProduct", Component: AllProducts },
  { path: "updateProducts/:id", Component: AddProduct },

  { path: "add-coupon", Component: AddCoupon },
  { path: "all-coupon", Component: AllCoupon },
  { path: "updateCoupons/:id", Component: UpdateCoupon },


  { path: "updateShipping", Component: AdminShipping },
  { path: "allShipping", Component: AdminAllShipping },
  { path: "updateShipping/:id", Component: AdminShipping },

  { path: "all-news-latter", Component: AllNewsLatter },


  { path: "all-user", Component: AllUser },

  { path: "addCategory", Component: AddCategory },
  { path: "allCategory", Component: AllCategory },
  { path: "updateCategorys/:id", Component: UpdateCategory },

  { path: "addSubCategory", Component: AddSubCategory },
  { path: "allSubCategory", Component: AllSubCategory },
  { path: "updateSubCategorys/:id", Component: UpdateSubCategory },

  { path: "addEvent", Component: AddEvent },
  { path: "allEvent", Component: AllEvent },
  { path: "updateEvents/:id", Component: UpdateEvent },

  { path: "addBrand", Component: AddBrand },
  { path: "allBrand", Component: AllBrand },
  { path: "updateBrands/:id", Component: UpdateBrand },

  { path: "addUniverse", Component: AddUniverse },
  { path: "allUniverse", Component: AllUniverse },
  { path: "updateUniverses/:id", Component: UpdateUniverse },
  
  { path: "shipping", Component: AdminShipping },

  { path: "add-blog", Component: AdminBlog },
  { path: "all-blogs", Component: AdminAllBlog },
  { path: "updateBlog/:id", Component: AdminBlog },



  { path: "orders", Component: AdminOrders },
  {path: "orders/:id", Component: UserOrders},
  { path: "updateBanner", Component: AdminDashboardSliderImg },
  { path: "updateFooter", Component: AdminFooter },
  { path: "updateGeneralInfo", Component: AdminInfo },
  { path: "updateAd", Component: AdminAd },
  { path: "updateFaq", Component: AdminFaq },

  { path: "updateAdSlider", Component: AdminAdCarousel },


];

export default AdminRoutes;
