import BrandDetailsPage from "../Pages/BrandDetailsPage/BrandDetailsPage";
import BrandExclusivePage from "../Pages/BrandExclusivePage/BrandExclusivePage";
import CheckOutPage from "../Pages/CheckOutPage/CheckOutPage";
import Home from "../Pages/Home/Home";
import ProductDetailsPage from "../Pages/ProductDetailsPage/ProductDetailsPage";
import ContactUs from "../Components/Footer/ContactUs";
import BlogPage from "../Pages/BlogPage/BlogPage";
import VerifyEmail from "../Shared/Auth/VerifyEmail";
import ResetPassword from "../Shared/ResetPassword/ResetPassword";
import InsertOtp from "../Shared/ResetPassword/InsertOtp";
import SetNewPassword from "../Shared/ResetPassword/SetNewPassword";
import CategoryProductPage from "../Pages/CategoryProductPage/CategoryProductPage";
import AboutUs from "../Components/Footer/AboutUs";
import FooterLayout from "../Components/Footer/FooterLayout";
import WriteToUs from "../Components/Footer/WriteToUs";
import PrivacyPolicy from "../Components/Footer/PrivacyPolicy";
import ReturnPolicy from "../Components/Footer/ReturnPolicy";
import Success from "../Pages/Payment Status/Success";
import Cancel from "../Pages/Payment Status/Cancel";
import EventProducts from "../Components/EventProducts/EventProducts";
import Faq from "../Components/Footer/Faq";
import UniverseProducts from "../Pages/UniverseProducts/UniverseProducts";



const PublicRoutes = [
  { path: "", Component: Home },
  { path: "home", Component: Home },

  { path: ":id/verify", Component: VerifyEmail },
  { path: "/resetPassword", Component: ResetPassword },
  { path: "/insert-token", Component: InsertOtp },
  { path: "/setNewPassword", Component: SetNewPassword },

  { path: "category/:id", Component: CategoryProductPage },
  { path: "brandExclusive", Component: BrandExclusivePage },
  { path: "/productDetails/:id", Component: ProductDetailsPage },
  { path: "brandExclusive/:id", Component: BrandDetailsPage },
  { path: "checkout", Component: CheckOutPage },
  { path: "blog/:id", Component: BlogPage },
  { path: "faq", Component: Faq },
  { path: "contact", Component: ContactUs },
  { path: "footerElemnet", Component: FooterLayout },
  { path: "aboutUs", Component: AboutUs },
  { path: "contact/writeUs", Component: WriteToUs},
  { path: "privacy", Component: PrivacyPolicy},
  { path: "return", Component: ReturnPolicy},
  { path: "payment/success", Component: Success},
  { path: "payment/cancel", Component: Cancel},

  { path: "event/:id", Component: EventProducts},
  { path: "universe/:id", Component: UniverseProducts},


];

export default PublicRoutes;
