import React, { useContext, useEffect, useState } from "react";
import { MdFavoriteBorder } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselProduct from "../../Components/Homes/CarouselProduct/CarouselProduct";
import CardCategoryProduct from "../../Components/CategoryProduct/CardCategoryProduct";
import Loading from "../../Shared/Loading";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import MyContext from "../../Context/MyContext";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

const ProductDetailsPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [des, setDes] = useState("description");
  const [selectedThumbnail, setSelectedThumbnail] = useState("");
  const [allData, setAllData] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const { refetch, setRefetch } = useContext(MyContext);
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [shade, setShade] = useState("");

  useEffect(() => {
    setLoading(true);
    try {
      fetch(`https://alala.niroghealthplus.com/api/v1/product/getProductsById/${id}`)
        .then((res) => res.json())
        .then((data) => {
          setProduct(data?.data);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      // return <div>{err?.message}</div>;
    }
  }, [id]);

  useEffect(() => {
    try {
      fetch(
        `https://alala.niroghealthplus.com/api/v1/product/specific?size=${3}&&page=${0}`
      )
        .then((res) => res.json())
        .then((data) => {
          setAllData(data?.data);
        });
    } catch (crr) {}
  }, []);

  useEffect(() => {
    const existingOrder = JSON.parse(localStorage.getItem("alalaOrder"));
    let exist = existingOrder?.find((item) => item._id === id);

    if (exist?._id) {
      setQuantity(exist?.quantity);
      setSize(exist?.selectedSize)
      setColor(exist?.selectedColor)
      setShade(exist?.selectedShade)
    } else {
      exist = [];
    }
  }, [id, refetch]);

  // product add to cart
  const addOrderInLocalStorage = () => {
    const existingOrder = JSON.parse(localStorage.getItem("alalaOrder"));

    if (existingOrder) {
      const exist = existingOrder?.find((item) => item._id === id);
      if (exist) {
        const filterOrder = existingOrder.filter(
          (item) => item._id !== exist?._id
        );

        exist.quantity = quantity;
        localStorage.setItem(
          "alalaOrder",
          JSON.stringify([...filterOrder, exist])
        );
        setRefetch(!refetch);
      } else {
        const newOrders = [
          ...existingOrder,
          {
            ...product,
            quantity: quantity,
            selectedSize: size,
            selectedColor: color,
            selectedShade:shade
          },
        ];
        localStorage.setItem("alalaOrder", JSON.stringify(newOrders));
        setRefetch(!refetch);
      }
    } else {
      const order = JSON.stringify([
        {
          ...product,
          quantity: quantity,
          selectedSize: size,
          selectedColor: color,
          selectedShade:shade
        },
      ]);
      localStorage.setItem("alalaOrder", order);
      setRefetch(!refetch);
    }
    // setRefresh(!refresh);
    toast.success("Product add into the cart");
    setRefetch(!refetch);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="mx-5 md:mx-10 mt-2 ">
      {/* add to cart option */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* fst slider image and carousel */}
        <div className="shadow-md rounded-md">
          <div className="">
            <div className="w-full rounded-t mb-5 relative">
            <img
              src={selectedThumbnail ? selectedThumbnail : product?.primaryImg}
              alt="Main img"
              className="w-full h-96 object-cover"
            />
          <p className="absolute  end-0 top-4 z-10    bg-red-500 px-3 py-0.5 text-white transition" > NEW</p>

            </div>
            <div className=" border-t mt-5 ">
              <Carousel
                swipeable={true}
                draggable={true}
                responsive={responsive}
                ssr={true}
                infinite={true}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={"desktop"}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px mx-1 border gap-2"
              >
                {product?.primaryImg ? (
                  <img
                    src={product?.primaryImg}
                    alt={`Thumbnail`}
                    className={` rounded cursor-pointer ${
                      selectedThumbnail === product?.primaryImg
                        ? "border-blue-500 border-2"
                        : "border-gray-300"
                    }`}
                    onClick={() => setSelectedThumbnail(product?.primaryImg)}
                  />
                ) : (
                  <div></div>
                )}
                {product?.img1 ? (
                  <img
                    src={product?.img1}
                    alt={`Thumbnail`}
                    className={` rounded cursor-pointer ${
                      selectedThumbnail === product?.img1
                        ? "border-blue-500 border-2"
                        : "border-gray-300"
                    }`}
                    onClick={() => setSelectedThumbnail(product?.img1)}
                  />
                ) : (
                  <div></div>
                )}
                {product?.img2 ? (
                  <img
                    src={product?.img2}
                    alt={`Thumbnail`}
                    className={` rounded cursor-pointer ${
                      selectedThumbnail === product?.img2
                        ? "border-blue-500 border-2"
                        : "border-gray-300"
                    }`}
                    onClick={() => setSelectedThumbnail(product?.img2)}
                  />
                ) : (
                  <div></div>
                )}
                {product?.img3 ? (
                  <img
                    src={product?.img3}
                    alt={`Thumbnail`}
                    className={` rounded cursor-pointer ${
                      selectedThumbnail === product?.img3
                        ? "border-blue-500 border-2"
                        : "border-gray-300"
                    }`}
                    onClick={() => setSelectedThumbnail(product?.img3)}
                  />
                ) : (
                  <div></div>
                )}
                {product?.img4 ? (
                  <img
                    src={product?.img4}
                    alt={`Thumbnail`}
                    className={` rounded cursor-pointer ${
                      selectedThumbnail === product?.img4
                        ? "border-blue-500 border-2"
                        : "border-gray-300"
                    }`}
                    onClick={() => setSelectedThumbnail(product?.img4)}
                  />
                ) : (
                  <div></div>
                )}
                {product?.img5 ? (
                  <img
                    src={product?.img5}
                    alt={`Thumbnail`}
                    className={` rounded cursor-pointer ${
                      selectedThumbnail === product?.img5
                        ? "border-blue-500 border-2"
                        : "border-gray-300"
                    }`}
                    onClick={() => setSelectedThumbnail(product?.img5)}
                  />
                ) : (
                  <div></div>
                )}
              </Carousel>
            </div>
          </div>
        </div>

        {/* 2nd  */}
        <div>
          <div>
            <h1 className="text-3xl font-semibold">{product?.productName}</h1>
            <div className="flex text-rose-600 font-bold gap-[2px] mt-5">
              <h1 className="text-3xl ">{product?.promotionalPrice}</h1>
              <span className="">F</span>
            </div>
            <div className="flex font-bold gap-[2px] text-stone-500 mt-2">
              <p className="line-through text-xl">{product?.marketPrice}</p>
              <span className="">F</span>
            </div>
          </div>

          {/* colour  */}
          <div className="mt-5">
            {product?.weight && (
              <p className="font-semibold">Weight: {product?.weight}</p>
            )}
            {product?.strength && (
              <p className="font-semibold">Strength: {product?.strength}</p>
            )}

            <div className="flex flex-wrap mt-4 gap-4 md:gap-5 w-full">
                {
                  product?.color?<>{
                    product?.color?.map(p=><div onClick={()=>setSelectedThumbnail(p?.img)} className=" border w-[80px] hover:border-rose-400 hover:opacity-70 duration-500 cursor-pointer" key={p?.img}>
                      <img 
                      className="h-20 w-full border object-cover "
                      src={p?.img}
                      alt=""
                    ></img>
                    <p className={`text-center `}>{p?.value}</p>
                    </div>)
                  }</>:(
                  
                    <>{
                      product?.productType==="Make-up"&&<>{
                        product?.shade?.map(p=><div onClick={()=>setSelectedThumbnail(p?.img)} className=" border w-[80px] hover:border-rose-400 hover:opacity-70 duration-500 cursor-pointer" key={p?.img}>
                          <img 
                          className="h-20 w-full border object-cover "
                          src={p?.img}
                          alt=""
                        ></img>
                        <p className={`text-center `}>{p?.value}</p>
                        </div>)
                      }</>
                    }</>
                   
                  )
                }
            </div>
          </div>

          {/* size  */}

          <div className="mr-10">
            <div className="mt-7">
              {product?.size && (
                <>
                  <p className="font-semibold">Select A Size</p>
                  <div className="flex gap-2 mt-3">
                    {product?.size?.map((s) => (
                      <button
                        key={s?.value}
                        onClick={() => setSize(s?.value)}
                        className={`${
                          s?.value === size && "bg-black text-white"
                        } border border-gray-500 py-1 px-2 font-bold rounded`}
                      >
                        {s?.value}
                      </button>
                    ))}
                  </div>
                </>
              )}
              {product?.color && (
                <>
                  <p className="font-semibold mt-2">Select A Color</p>
                  <div className="flex gap-2 mt-3">
                    {product?.color?.map((s) => (
                      <button
                        key={s?.value}
                        onClick={() => setColor(s?.value)}
                        className={`${
                          s?.value === color && "bg-black text-white"
                        } border border-gray-500 py-1 px-2 font-bold rounded`}
                      >
                        {s?.value}
                      </button>
                    ))}
                  </div>
                </>
              )}

{product?.shade && (
                <>
                  <p className="font-semibold mt-2">Select A Shade</p>
                  <div className="flex gap-2 mt-3">
                    {product?.shade?.map((s) => (
                      <button
                        key={s?.value}
                        onClick={() => setShade(s?.value)}
                        className={`${
                          s?.value === shade && "bg-black text-white"
                        } border border-gray-500 py-1 px-2 font-bold rounded`}
                      >
                        {s?.value}
                      </button>
                    ))}
                  </div>
                </>
              )}

              <p className="text-rose-600 my-5">
                It is {product?.stock} pice available
              </p>
              <label className="text-lg font-semibold ">Quentity</label>
              <div class="flex items-center text-4xl mt-3">
                <button
                  onClick={() => {
                    if (quantity === 1) {
                      toast.error("You can order minimum 1 pice");
                    } else {
                      setQuantity(quantity - 1);
                    }
                  }}
                  class="border rounded-md px-2  mr-2 bg-red-500 text-white"
                >
                  <Icon icon="typcn:minus" />
                </button>
                <span class="text-center text-2xl font-bold">{quantity}</span>
                <button
                  onClick={() => {
                    if (quantity === product?.stock) {
                      toast.error("Stock are not available");
                    } else {
                      setQuantity(quantity + 1);
                    }
                  }}
                  class="border rounded-md  px-2 ml-2 bg-green-500 text-white"
                >
                  <Icon icon="typcn:plus" />
                </button>
              </div>
              <div className="flex mt-5 gap-8">
                <button
                  onClick={addOrderInLocalStorage}
                  className="w-4/5 px-10 rounded py-2 text-lg bg-black text-white font-semibold"
                >
                  Add to cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* more details description  */}

      <div className="my-10">
        <div className="flex gap-2 mb-5 md:gap-20">
          <button
            onClick={() => setDes("description")}
            className={`${
              des === "description"
                ? "border-b-rose-600 py-1 text-rose-600 border-b-2"
                : ""
            } text-xs md:text-lg font-bold uppercase`}
          >
            Description
          </button>
          <button
            onClick={() => setDes("advice")}
            className={`${
              des === "advice"
                ? "border-b-rose-600 py-1 text-rose-600 border-b-2"
                : ""
            } text-xs md:text-lg font-bold uppercase`}
          >
            Advice And Application
          </button>
          <button
            onClick={() => setDes("info")}
            className={`${
              des === "info"
                ? "border-b-rose-600 py-1 text-rose-600 border-b-2"
                : ""
            } text-xs md:text-lg font-bold uppercase`}
          >
            Other Info
          </button>
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html:
              des === "description"
                ? product?.description
                : des === "info"
                ? product?.otherInfo
                : product?.adviceAndApplication,
          }}
        ></div>
      </div>

      {/* 3 product cart  */}
      <div className="my-10">
        <h1 className="text-3xl font-bold">Latest Product</h1>
        <div className="grid grid-cols-1 mt-8 md:grid-cols-3 gap-5">
          {allData?.map((data) => (
            <CardCategoryProduct
              key={data?._id}
              product={data}
            ></CardCategoryProduct>
          ))}
        </div>
      </div>

      <div>
        <h1 className="text-3xl mb-8 font-bold">Vous Livraison Gratuite</h1>
        <CarouselProduct></CarouselProduct>
      </div>
    </div>
  );
};

export default ProductDetailsPage;
