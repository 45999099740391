import React, { useContext, useEffect, useState } from "react";
import logo from "../Assets/logo.png";
import { Icon } from "@iconify/react";
import Glide from "@glidejs/glide";
import { Link } from "react-router-dom";
import AuthModal from "./Auth/AuthModal";
import AuthUser from "../Hooks/authUser";
import GoogleTranslate from "../GoogleTranslate";
import MyContext from "../Context/MyContext";
import ProductSuggestionsModal from "../Components/ProductSuggestionModal/ProductSuggestionsModal";

const WebNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { userInfo, logout } = AuthUser();
  const [searchData, setSearchData] = useState("");
  const [order, setOrder] = useState([]);
  const { refetch, setRefetch } = useContext(MyContext);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("alalaOrder"));
    if (cart) {
      setOrder(cart);
    }
  }, [refetch]);

  useEffect(() => {
    const slider = new Glide(".glide-02", {
      type: "carousel",
      focusAt: "center",
      perView: 13,
      autoplay: 2000,
      animationDuration: 700,
      gap: 5,
      classNames: {
        nav: {
          active: "[&>*]:bg-wuiSlate-700",
        },
      },
      breakpoints: {
        1024: {
          perView: 10,
        },
        640: {
          perView: 6,
        },
      },
    }).mount();

    return () => {
      slider.destroy();
    };
  }, []);
  return (
    <>
      <div className="sticky  left-0 right-0 top-0 z-50 bg-white border-b">
        <nav className=" bg-white flex items-center justify-between px-3 lg:px-7 lg:h-16 h-20 text-white gap-3">
          <Link to="/">
            <img className="md:w-28 w-12" src={logo} alt="logo" />
          </Link>

          <div class="hidden relative md:flex items-center w-full md:max-w-lg h-10 rounded-3xl focus-within:shadow-lg bg-white border overflow-hidden">
            <div class="grid place-items-center h-full w-12 text-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>

            <input
              class="peer h-full w-full outline-none md:text-sm text-xs text-gray-700 pr-2"
              type="text"
              id="search"
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search for a product or brand"
            />
          </div>
          {searchData && (
            <ProductSuggestionsModal
              searchData={searchData}
              setSearchData={setSearchData}
            />
          )}

          <GoogleTranslate />

          <li className="-mt-1" id="google_translate_element"></li>
          {userInfo?.email ? (
            <Link
              to={`/${userInfo?.role}Dashboard`}
              className="text-black border px-2 py-1.5 rounded-md"
            >
              Dashboard
            </Link>
          ) : (
            <button
              onClick={() => setIsOpen(!isOpen)}
              className=" hover:border-white duration-300 text-black cursor-pointer p-1 rounded-md"
            >
              {/* <p className="text-xs font-medium">Please,</p> */}
              <p className="flex  text-sm lg:text-base whitespace-nowrap items-center gap-1">
                {" "}
                <Icon className=" text-4xl" icon="mdi:user-outline" /> LOGIN /
                REGISTER
              </p>
            </button>
          )}

          <Link
            to="/checkout"
            className="w-fit relative hover:cursor-pointer hover:scale-95 duration-300 border hover:border-white  rounded-lg text-black"
          >
            <div className="bg-red-600 md:h-5 text-white md:w-5 w-4 h-4 text-xs flex justify-center items-center rounded-full absolute top-0 right-0 font-semibold">
              {" "}
              {order?.length}
            </div>
            <Icon className="md:text-5xl text-3xl" icon="prime:cart-plus" />
          </Link>
        </nav>

        {/* mobile part */}

        <>
          {/*<!-- Component: Carousel with indicators inside --> */}
          <div className="glide-02 lg:hidden relative w-full py-2 -mt-2 mb-2">
            {/*    <!-- Slides --> */}
            <div className="overflow-hidden" data-glide-el="track">
              <ul className="whitespace-no-wrap flex-no-wrap  [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform] relative flex w-full overflow-hidden p-0 justify-center">
                <Link
                  to="/"
                  className="  mx-auto cursor-pointer border-r hover:text-rose-500 text-center px-2"
                >
                  {/* <img src={img2} alt="img" className="w-10" /> */}
                  <p className="text-xs whitespace-nowrap">WELCOME</p>
                </Link>
                <Link
                  to="/category/beauty"
                  className="  mx-auto cursor-pointer border-r hover:text-rose-500 text-center px-2"
                >
                  {/* <img src={img2} alt="img" className="w-10" /> */}
                  <p className="text-xs whitespace-nowrap">BEAUTY</p>
                </Link>
                <Link
                  to="/category/fashion"
                  className="  mx-auto cursor-pointer border-r hover:text-rose-500 text-center px-2"
                >
                  {/* <img src={img2} alt="img" className="w-10" /> */}
                  <p className="text-xs whitespace-nowrap uppercase">fashion</p>
                </Link>
                <Link
                  to="/category/sports"
                  className="  mx-auto cursor-pointer border-r hover:text-rose-500 text-center px-2"
                >
                  {/* <img src={img2} alt="img" className="w-10" /> */}
                  <p className="text-xs whitespace-nowrap uppercase">sports</p>
                </Link>
                <Link
                  to="/category/child"
                  className="  mx-auto cursor-pointer border-r hover:text-rose-500 text-center px-2"
                >
                  {/* <img src={img2} alt="img" className="w-10" /> */}
                  <p className="text-xs whitespace-nowrap uppercase">child</p>
                </Link>
                <Link
                  to="/category/leisure"
                  className="  mx-auto cursor-pointer border-r hover:text-rose-500 text-center px-2"
                >
                  {/* <img src={img2} alt="img" className="w-10" /> */}
                  <p className="text-xs whitespace-nowrap uppercase">leisure</p>
                </Link>
                <Link
                  to="/brandExclusive"
                  className="  mx-auto cursor-pointer border-r hover:text-rose-500 text-center px-2"
                >
                  {/* <img src={img2} alt="img" className="w-10" /> */}
                  <p className="text-xs whitespace-nowrap uppercase">brandEx</p>
                </Link>
              </ul>
            </div>
          </div>
          {/* thirdNavbar */}

          <nav className="hidden lg:flex items-center  justify-center pl-5 pr-5 p-3 h-auto bg-white text-black border-t border-white/10">
            <div className="flex justify-center items-center gap-5 ">
              <Link
                to="/"
                className="flex justify-center items-center gap-2 cursor-pointer p-1 transition-all duration-300  border-transparent hover:text-green-500"
              >
                <Icon icon="mdi:human-welcome"></Icon>
                <p> WELCOME</p>
              </Link>
              <Link
                to="/category/beauty"
                className="flex justify-center items-center gap-2 cursor-pointer p-1 transition-all duration-300  border-transparent hover:text-green-500"
              >
                <Icon icon="icon-park-outline:beauty"></Icon>
                <p> BEAUTY</p>
              </Link>

              <Link
                to="/category/fashion"
                className="flex justify-center items-center gap-2 cursor-pointer p-1 transition-all duration-300  border-transparent hover:text-green-500"
              >
                <Icon icon="icon-park-outline:hanfu-chinese-style"></Icon>
                <p>FASHION</p>
              </Link>

              <Link
                to="/category/sports"
                className="flex justify-center items-center gap-2 cursor-pointer p-1 transition-all duration-300  border-transparent hover:text-green-500"
              >
                <Icon icon="material-symbols-light:sports-bar"></Icon>
                <p> SPORTS</p>
              </Link>
              <Link
                to="/category/child"
                className="flex justify-center items-center gap-2 cursor-pointer p-1 transition-all duration-300  border-transparent hover:text-green-500"
              >
                <Icon icon="fa-solid:child"></Icon>
                <p> CHILD</p>
              </Link>
              <Link
                to="/category/leisure"
                className="flex justify-center items-center gap-2 cursor-pointer p-1 transition-all duration-300  border-transparent hover:text-green-500"
              >
                <Icon icon="icon-park-outline:tape-measure"></Icon>
                <p> LEISURE</p>
              </Link>
              <Link to="brandExclusive">
                <div className="flex justify-center items-center gap-2 cursor-pointer p-1 transition-all duration-300  border-transparent hover:text-green-500">
                  <Icon icon="tabler:brand-unity"></Icon>
                  <p> BANDEXCLUSIVE</p>
                </div>
              </Link>
            </div>
          </nav>

          {/*<!-- End Carousel with indicators inside --> */}
        </>
      </div>
      {isOpen && <AuthModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

export default WebNavbar;
