import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardCategoryProduct from "../CategoryProduct/CardCategoryProduct";
import { toast } from "react-toastify";
import Loading from "../../Shared/Loading";
import useGetDataHook from "../../Hooks/useGetDataHook";
import { server_url } from "../../Config/API";
import Pagination from "../../Shared/Pagination";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import MultiRangeSlider from "multi-range-slider-react";

const EventProducts = () => {
  const { id } = useParams();
  const [event, setEvent] = useState([]);
  useEffect(() => {
    fetch(
      `${server_url}/event/specific?fieldName=${"name"}&&fieldValue=${id}`
    ).then((res) => res.json().then((data) => setEvent(data?.data)));
  }, [id]);
  const [range, setRange] = useState({ minValue: 0, maxValue: 100000 });
  const [showIconeCategories, setShowIconeCategories] = useState(true);
  const [order, setOrder] = useState(100);
  const [maxValue, setMaxValue] = useState(100000);
  const [minValue, setMinValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subCategorys] = useGetDataHook(
    `${server_url}/subCategory/getSubCategorys`
  );
  const [products, setProducts] = useState([]);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  const handleRangeChange = (newRange) => {
    setRange(newRange);
    setMaxValue(newRange.maxValue);
    setMinValue(newRange.minValue);
  };

  const [selectedCategorys, setSelectedCategorys] = useState([]);
  console.log(event);
  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    const isSelected = selectedCategorys.includes(value);
    setSelectedCategorys((prevSelectedItems) =>
      isSelected
        ? prevSelectedItems.filter((item) => item !== value)
        : [...prevSelectedItems, value]
    );
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const encodedNames = selectedCategorys.map(encodeURIComponent);
      const params = `${encodedNames.join(",")}`;
      // console.log(params)
      try {
        await fetch(
          `${server_url}/product/getProducts?fieldName=${"event"}&&fieldValue=${id}&&minPrice=${minValue}&&maxPrice=${maxValue}&&subCategory=${params}&&order=${order}&&size=${size}&&page=${page}`
        )
          .then((res) => res.json())
          .then((data) => {
            setQuantity(data?.total);
            setProducts(data?.data);
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        toast.error(err.message);
      }
    };
    fetchData();
  }, [id, selectedCategorys, minValue, maxValue, order, size, page]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-11/12 mx-auto min-h-screen">
      <div className="mx-5 md:mx-10">
        {event?.length ? (
          <img
            className=" w-full max-h-[500px] object-cover rounded-md mt-5 overflow-hidden"
            src={event[0]?.internalBanner}
            alt="img"
          ></img>
        ) : (
          ""
        )}
      </div>
      <div className="mx-5 md:mx-10 ">
        <div className="w-fit my-10">
          <h2 className="text-3xl font-semibold">{id} Products</h2>
          <div className="bg-black h-1 rounded-lg mt-2 w-[60%]"></div>
        </div>
        <div className="flex flex-col gap-14 md:flex-row">
          <div className=" md:w-2/12 lg:border-r lg:pr-5">
            <div>
              <h1>Filter by price</h1>
              <div className="my-5">
                <MultiRangeSlider
                  min={0}
                  max={100000}
                  minValue={minValue}
                  maxValue={maxValue}
                  step={1}
                  ruler={false}
                  label={false}
                  range={range}
                  onChange={handleRangeChange}
                />
                <div>
                  Price: {minValue} CFA - {maxValue} CFA
                </div>
              </div>

              <div>
                {/* Categories  */}
                <div>
                  <div className="flex justify-between items-center">
                    <h1 className=" font-semibold">Categories</h1>
                    <div
                      className="text-xl"
                      onClick={() =>
                        setShowIconeCategories(!showIconeCategories)
                      }
                    >
                      {showIconeCategories ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </div>
                  </div>
                  {showIconeCategories && (
                    <div className="mt-2 grid grid-cols-1">
                      {subCategorys?.map((c) => (
                        <label
                          key={c?.name}
                          className="inline-flex items-center"
                        >
                          <input
                            type="checkbox"
                            value={c?.name}
                            onChange={handleCheckboxChange}
                            checked={selectedCategorys.includes(c?.name)}
                            className="form-checkbox h-5 w-5 text-indigo-600"
                          />
                          <span className="ml-2 text-gray-700">{c?.name}</span>
                        </label>
                      ))}
                    </div>
                  )}
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          {/* Product card side bar  */}
          <div className="md:w-10/12">
            <div>
              <div>
                <label className="text-sm font-semibold mr-2">Sort by: </label>
                <select
                  name="order"
                  type="number"
                  onChange={(e) => setOrder(e.target.value)}
                  className="border p-2 text-sm focus:outline-none mb-5 rounded"
                >
                  <option value={100} selected={order === 100}>
                    None
                  </option>
                  <option value={1} selected={order === 1}>
                    Short by ascending price
                  </option>
                  <option value={-1} selected={order === -1}>
                    Short by descending price
                  </option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-5 gap-y-16 md:grid-cols-3">
              {products?.map((product) => (
                <CardCategoryProduct product={product} />
              ))}
            </div>

            <Pagination
              quantity={quantity}
              page={page}
              setPage={setPage}
              size={size}
              setSize={setSize}
            />
          </div>
        </div>
      </div>

      <div className="mt-20 grid grid-cols-14 rounded-lg overflow-hidden shadow ">
        <div className="flex bg-sky-950 text-white p-5 justify-between items-center">
          <h1 className="text-2xl  font-bold uppercase">{event[0]?.name}</h1>
        </div>
        <div className="px-5 py-5 pb-10 bg-sky-200 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
          <img
            className="w-full h-72 object-cover rounded-lg"
            src={event[0]?.internalBanner}
            alt=""
          ></img>
          <div className="flex flex-col justify-center">
            <p className=" text-justify">{event[0]?.presentationText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventProducts;
