import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../../Shared/Loading";
import { CiSearch } from "react-icons/ci";
import moment from "moment";
import UserOrderCard from "./UserOrderCard";
import AuthUser from "../../../Hooks/authUser";
import Pagination from "../../../Shared/Pagination";

const MyOrders = () => {
  const [nav, setNav] = useState("pending");
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const {userInfo}=AuthUser();
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

    // for pagination
    const [quantity, setQuantity] = useState(0);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);

  useEffect(() => {
    setLoading(true);
    const url = `https://alala.niroghealthplus.com/api/v1/order/specificOrders?fieldName=${"userId"}&&fieldValue=${
      userInfo?._id
    }&&size=${size}&&page=${page}`;
    try {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setData(data?.data);
          setQuantity(data?.total)
          setFilterData(data?.data);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      toast.error("something wrong");
    }
  }, [nav, refetch, userInfo,page,size]);

  const handleSearch = (e) => {
    e.preventDefault();
    const n = e.target.name.value;
    const fromDate = e.target.formDate.value;
    const toDate = e.target.toDate.value;

    const results = data?.filter((d) => {
      return (
       
        (fromDate && fromDate
          ? moment(d?.date).isBetween(fromDate, toDate)
          : true)
      );
    });
    setFilterData(results);
  };

 

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="">
     

      <div className="pt-5">
        <form
          onSubmit={handleSearch}
          className="flex flex-wrap items-center   gap-3 mb-6 border p-2 rounded-md"
        >
          <div>
            <label className="block mb-1">Form Date</label>
            <input
              name="formDate"
              type="date"
              className="border focus:outline-none bg-[#F0FDF4] px-3 py-2 rounded-md"
            ></input>
          </div>
          <div>
            <label className="block mb-1">To Date</label>
            <input
              name="toDate"
              type="date"
              className="border focus:outline-none bg-[#F0FDF4] px-3 py-2 rounded-md"
            ></input>
          </div>

          <button
            type="submit"
            className="text-white bg-primary px-3 py-2 rounded-md mt-6"
          >
            Search
          </button>
        </form>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          {
            filterData?.map(order=><UserOrderCard key={order?._id} order={order} setRefetch={setRefetch} refetch={refetch}/>)
          }
        </div>
      
        <Pagination
              quantity={quantity}
              page={page}
              setPage={setPage}
              size={size}
              setSize={setSize}
            />
      </div>
    </div>
  );
};

export default MyOrders;
