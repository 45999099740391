import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import JoditEditor from "jodit-react";
import moment from "moment/moment";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import PostHooks from "../../../Hooks/PostHooks";
import { singleImageUpload } from "../../../Hooks/ImageUpload";

const AdminBlog = () => {
  const editor = useRef(null);
  const date = moment().format("MMM Do YY");
  const [images, setImages] = useState(["", "", "", ""]);
  const [descriptions, setDescriptions] = useState(["", "", "", ""]);
  const [formData, setFormData] = useState({
    title: "",
    authorName: "",
    subtitle1: "",
    subtitle2: "",
    subtitle3: "",


  });

  const { id } = useParams();

  useEffect(() => {
    fetch(`https://alala.niroghealthplus.com/api/v1/blog/getBlogsById/${id}`)
      .then((res) => res.json())
      .then((data) => setFormData(data?.data));
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formData?._id) {
        await UpdateHooks(
          `https://alala.niroghealthplus.com/api/v1/blog/updateBlogs/${formData?._id}`,
          {
            ...formData,
            img1: images[0] ? images[0] : formData?.img1,
            img2: images[1] ? images[1] : formData?.img2,
            img3: images[2] ? images[2] : formData?.img3,
            img4: images[3] ? images[3] : formData?.img4,
            description1: descriptions[0]
              ? descriptions[0]
              : formData?.description1,
            description2: descriptions[1]
              ? descriptions[1]
              : formData?.description2,
            description3: descriptions[2]
              ? descriptions[2]
              : formData?.description3,
            description4: descriptions[3]
              ? descriptions[3]
              : formData?.description4,
          }
        );
        toast?.success(`Blog section Updated !`);
      } else {
        await PostHooks(
          `https://alala.niroghealthplus.com/api/v1/Blog/addBlogs`,
          {
            ...formData,
            img1: images[0],
            img2: images[1],
            img3: images[2],
            img4: images[3],
            description1: descriptions[0],
            description2: descriptions[1],
            description3: descriptions[2],
            description4: descriptions[3],
            date: date,
          },
          `Blog data posted`
        );
      }
    } catch (error) {
      toast?.success(error);
    }
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleChangeUploadImage = async (event, index) => {
    const newImages = [...images];
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("image", file);

    await singleImageUpload(formData, (imageUrl) => {
      newImages[index] = imageUrl;
      setImages(newImages);
    });
  };

  const handleChangeDescription = (index, newContent) => {
    const newDescriptions = [...descriptions];
    newDescriptions[index] = newContent;
    setDescriptions(newDescriptions);
  };

  return (
    <div className="bg-white">
      <div className="w-fit  text-xl font-semibold mb-5">
        <h1>Update Blog Section</h1>
        <div className="h-1 mt-1 bg-secondary w-[40%]"></div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 p-6 rounded shadow-md w-full mx-auto"
      >
        <div className="flex gap-3 items-center">
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-600">
              img-1 Blog Card (600px * 380px)
            </label>
            <input
              type="file"
              name="image1"
              onChange={(event) => handleChangeUploadImage(event, 0)}
              className="w-full px-3 py-2 border rounded-md"
            />
            <img
              className="w-[70px] rounded-md shadow-lg mt-2"
              src={images[0]}
              alt="img-1"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-600">
              Image-2 (600px * 380px)
            </label>
            <input
              type="file"
              name="image2"
              onChange={(event) => handleChangeUploadImage(event, 1)}
              className="w-full px-3 py-2 border rounded-md"
            />
            <img
              className="w-[70px] rounded-md shadow-lg mt-2"
              src={images[1]}
              alt="img-2"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-600">
              Image-3 (600px * 380px)
            </label>
            <input
              type="file"
              name="image3"
              onChange={(event) => handleChangeUploadImage(event, 2)}
              className="w-full px-3 py-2 border rounded-md"
            />
            <img
              className="w-[70px] rounded-md shadow-lg mt-2"
              src={images[2]}
              alt="img-3"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-600">
              Image-4 (600px * 380px)
            </label>
            <input
              type="file"
              name="image4"
              onChange={(event) => handleChangeUploadImage(event, 3)}
              className="w-full px-3 py-2 border rounded-md"
            />
            <img
              className="w-[70px] rounded-md shadow-lg mt-2"
              src={images[3]}
              alt="img-4"
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Author Name:
          </label>
          <input
            type="text"
            name="authorName"
            required
            onChange={handleChange}
            value={formData?.authorName}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
            Title:
          </label>
          <input
            type="text"
            name="title"
            required
            onChange={handleChange}
            value={formData?.title}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
            Subtitle 1:
          </label>
          <input
            type="text"
            name="subtitle1"
            onChange={handleChange}
            value={formData?.subtitle1}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
            Subtitle 2:
          </label>
          <input
            type="text"
            name="subtitle2"
            onChange={handleChange}
            value={formData?.subtitle2}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
            Subtitle 3:
          </label>
          <input
            type="text"
            name="subtitle3"
            onChange={handleChange}
            value={formData?.subtitle3}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>

        <div className="">
          {descriptions.map((_, index) => (
            <div key={index} className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-600">
                {index === 3 ? "Conclusion" : `Description ${index + 1}`}
              </label>
              <JoditEditor
                ref={editor}
                value={descriptions[index]}
                onChange={(newContent) =>
                  handleChangeDescription(index, newContent)
                }
              />
            </div>
          ))}
        </div>

        <div>
          <button
            type="submit"
            className="bg-secondary text-white px-4 py-2 rounded hover:bg-secondary/90 hover:scale-105 duration-300"
          >
            Update Blog !
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminBlog;
