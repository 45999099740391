import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useGetDataHook from "../../../Hooks/useGetDataHook";
import { server_url } from "../../../Config/API";
import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CarouselProduct = () => {
  const [brands] = useGetDataHook(`${server_url}/brand/getBrands`);
  console.log(brands);
  return (
    <div>
      <Carousel
        swipeable={true}
        draggable={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={"desktop"}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px mr-3 z-50"
        autoPlay={true} // Enable autoPlay
        autoPlaySpeed={2000} // Set autoPlay speed in milliseconds (e.g., 3 seconds)
      >
        {brands?.map((brand) => (
          <Link brand={brand} to={`/brandExclusive/${brand?.name}`}>
            <img className="w-24" src={brand?.icon} alt={brand?.name}></img>
          </Link>
        ))}

        <div></div>
        {/* Add more images as needed */}
      </Carousel>
    </div>
  );
};

export default CarouselProduct;
