import React from 'react'
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaHeadphones } from "react-icons/fa";
import { Link } from 'react-router-dom';

const ContactUs = () => {
  return (
    <div className='px-5 mt-10 md:px-10'>
        <div className='grid grid-cols-1 gap-10 md:grid-cols-3'>
            <div className='border text-center flex flex-col gap-8 items-center p-10 rounded'>
                <BsFillQuestionSquareFill className='text-[45px] text-center'></BsFillQuestionSquareFill>
                <h1 className='text-lg md:text-3xl font-bold'>Consult our faq / help</h1>
                <p>Save time we are already answered your question here</p>
                <button className='px-10 uppercase font-semibold text-lg py-1 border border-black'>Consult</button>
            </div>
            <div className='border text-center flex flex-col gap-8 items-center p-10 rounded'>
                <MdEmail className='text-[45px] text-center'></MdEmail>
                <h1 className='text-lg md:text-3xl font-bold'>Write to us by email</h1>
                <p>Save time we are already answered your question here</p>
                <Link to='writeUs' className='px-10 uppercase font-semibold text-lg py-1 border border-black'>Consult</Link>
            </div>
            <div className='border text-center flex flex-col gap-8 items-center p-10 rounded'>
                <FaHeadphones className='text-[45px] text-center'></FaHeadphones>
                <h1 className='text-lg md:text-3xl font-bold'>Call our customer service</h1>
                <p>Save time we are already answered your question here</p>
                <button className='px-10 uppercase font-semibold text-lg py-1 border border-black'>Consult</button>
            </div>
        </div>
    </div>
  )
}

export default ContactUs