import React, { useEffect } from "react";
import { useState } from "react";

const Faq = () => {
  const [faq, setFaq] = useState([]);

  //   load data
  useEffect(() => {
    fetch(`https://alala.niroghealthplus.com/api/v1/faq/getfaqs`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data.length) {
          setFaq(data?.data);
        }
      });
  }, []);

  return (
    <div className=" my-10 w-11/12 md:10/12 mx-auto">
      <h1 className="text-4xl font-semibold mb-10">Frequently ask question</h1>
      <section className="w-full divide-y divide-slate-200 rounded border border-slate-200 bg-white">
        {faq?.map((f) => (
          <details className="group p-4">
            <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
              {f?.title}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute right-0 top-1 h-4 w-4 shrink-0 stroke-slate-700 transition duration-300 group-open:rotate-45"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                aria-labelledby="title-ac24 desc-ac24"
              >
                <title id="title-ac24">Open icon</title>
                <desc id="desc-ac24">
                  icon that represents the state of the summary
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </summary>
            <p className="mt-4 text-slate-500">{f?.des}</p>
          </details>
        ))}
      </section>
    </div>
  );
};

export default Faq;
