import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import WebNavbar from "../../Shared/WebNavbar";
import Footer from "../../Shared/Footer";
import Comunity from "../../Shared/Comunity";
import ServiceQuality from "../../Shared/ServiceQuality";

const Main = () => {
  return (
    <div>
      <WebNavbar></WebNavbar>
      <Outlet></Outlet>
      {/* <ServiceQuality></ServiceQuality> */}
      <Comunity></Comunity>
      <Footer></Footer>
    </div>
  );
};

export default Main;
