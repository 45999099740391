import React, { useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import { server_url } from "../../../Config/API";
import useGetDataHook from "../../../Hooks/useGetDataHook";
import {
  colorAdnShadeImg,
  singleImageUpload,
} from "../../../Hooks/ImageUpload";
import axios from "axios";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { useParams } from "react-router-dom";

const AddProduct = () => {
  const { id } = useParams();
  const [primaryImg, setPrimaryImg] = useState("");
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [img4, setImg4] = useState("");
  const [img5, setImg5] = useState("");

  const [otherInfo, setOtherInfo] = useState("");
  const [adviceAndApplication, setAdviceAndApplication] = useState("");
  const [description, setDescription] = useState("");

  const [size, setSize] = useState([{ value: "" }]);
  const [color, setColor] = useState([{ value: "", img: "" }]);
  const [shade, setShade] = useState([{ value: "", img: "" }]);

  // const [categorys] = useGetDataHook(`${server_url}/category/getCategorys`);
  const [subCategorys] = useGetDataHook(
    `${server_url}/subCategory/getSubCategorys`
  );
  const [brands] = useGetDataHook(`${server_url}/brand/getBrands`);
  const [events] = useGetDataHook(`${server_url}/event/getEvents`);
  const [universes] = useGetDataHook(`${server_url}/universe/getUniverses`);

  const [productData, setProductData] = useState({
    productType: "",
    return: "",
    productName: "",
    universe: "",
    access: "",
    category: "",
    subCategory: "",
    brand: "",
    stock: 0,
    promotionalPrice: 0,
    marketPrice: 0,
    gender: "male",
    deliveryOption: "",
    vendor: "",
    specialOffers: "",
    strength: "",
    texture: "",
    effect: "",
    coverage: "",
    format: "",
    typeOfSkin: "",
    weight: "",
    type: "",
    material: "",
    averageShippingTime: "",
    typeCollar: "",
    cut: "",
    event: "",
  });

  const [data] = useGetDataHook(`${server_url}/product/getProductsById/${id}`);
  useEffect(() => {
    if (data?._id) {
      setProductData(data);
      setPrimaryImg(data?.primaryImg);
      setImg1(data?.img1);
      setImg2(data?.img2);
      setImg3(data?.img3);
      setImg4(data?.img4);
      setImg5(data?.img5);
      setDescription(data?.description);
      setOtherInfo(data?.otherInfo);
      setAdviceAndApplication(data?.adviceAndApplication);
      setSize(data?.size);
      setColor(data?.color);
      setShade(data?.shade);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (productData?._id) {
      UpdateHooks(
        `${server_url}/product/updateProducts/${productData?._id}`,
        {
          ...productData,
          primaryImg,
          img1,
          img2,
          img3,
          img4,
          img5,
          description,
          otherInfo,
          adviceAndApplication,
          size: size === null ? null : size,
          color: color === null ? null : color,
          shade: shade === null ? null : shade,
        },
        true
      );
    } else {
      try {
        const response = await axios.post(`${server_url}/product/addProducts`, {
          ...productData,
          primaryImg,
          img1,
          img2,
          img3,
          img4,
          img5,
          description,
          otherInfo,
          adviceAndApplication,
          size: size[0]?.value ? size : null,
          color: color[0]?.value ? color : null,
          shade: shade[0]?.value ? shade : null,
        });
        if (response?.statusText === "OK") {
          toast.success("Product added successfully");
        }
        console.log(response.data);
      } catch (error) {
        console.error(error);
        toast.error(error?.message);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setProductData({
        ...productData,
        [e.target.name]: e.target.checked,
      });
    } else {
      setProductData({
        ...productData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSize = (e, index) => {
    let newSize = [...size];
    newSize[index].value = e.target.value;
    setSize(newSize);
  };
  const handelAddSize = () => {
    setSize([...size, { value: "" }]);
  };

  const handleRemoveSize = (index) => {
    let newSize = [...size];
    newSize.splice(index, 1);
    setSize(newSize);
  };

  const handleColor = (e, index) => {
    let newColor = [...color];
    newColor[index].value = e.target.value;
    setColor(newColor);
  };
  const handelAddColor = () => {
    setColor([...color, { value: "" }]);
  };

  const handleRemoveColor = (index) => {
    let newColor = [...color];
    newColor.splice(index, 1);
    setColor(newColor);
  };
  // shade
  const handleShade = (e, index) => {
    let newShade = [...shade];
    newShade[index].value = e.target.value;
    setShade(newShade);
  };
  const handelAddShade = () => {
    setShade([...shade, { value: "" }]);
  };

  const handleRemoveShade = (index) => {
    let newShade = [...shade];
    newShade.splice(index, 1);
    setShade(newShade);
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);

    if (event?.target?.name === "primaryImg") {
      singleImageUpload(formData, setPrimaryImg);
    }
    if (event?.target?.name === "img1") {
      singleImageUpload(formData, setImg1);
    }
    if (event?.target?.name === "img2") {
      singleImageUpload(formData, setImg2);
    }
    if (event?.target?.name === "img3") {
      singleImageUpload(formData, setImg3);
    }
    if (event?.target?.name === "img4") {
      singleImageUpload(formData, setImg4);
    }
    if (event?.target?.name === "img5") {
      singleImageUpload(formData, setImg5);
    }
  };

  const handelColorAndShadeImg = async (event, name, index) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);

    if (name === "color") {
      colorAdnShadeImg(formData, color, setColor, index);
    } else {
      colorAdnShadeImg(formData, shade, setShade, index);
    }
  };

  return (
    <div className="w-full mx-auto my-10">
      <h1 className="text-3xl font-bold mb-5">
        {productData?._id ? "Update Product" : "Add Product"}
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Product Type
          </label>
          <select
            onChange={handleChange}
            name="productType"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" selected disabled>
              {" "}
              select
            </option>
            <option
              selected={
                productData?.productType === "Jewellery, watches and glasses"
              }
              value="Jewellery, watches and glasses"
            >
              {" "}
              Jewellery, watches and glasses
            </option>
            <option
              selected={
                productData?.productType === "Leather goods, luggage and acce"
              }
              value="Leather goods, luggage and acce"
            >
              {" "}
              Leather goods, luggage and acce
            </option>
            <option
              selected={productData?.productType === "Clothes"}
              value="Clothes"
            >
              {" "}
              Clothes
            </option>
            <option
              selected={productData?.productType === "Shoes"}
              value="Shoes"
            >
              {" "}
              Shoes
            </option>
            <option
              selected={productData?.productType === "Make-up"}
              value="Make-up"
            >
              {" "}
              Make-up
            </option>
            <option
              selected={productData?.productType === "Fragrance"}
              value="Fragrance"
            >
              Fragrance
            </option>
            <option
              selected={productData?.productType === "Body care"}
              value="Body care"
            >
              Body care
            </option>
            <option
              selected={productData?.productType === "Others"}
              value="Others"
            >
              Others
            </option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Event <span className="text-xs text-orange-500">(Optional)</span>
          </label>
          <select
            onChange={handleChange}
            name="event"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" selected disabled>
              {" "}
              none
            </option>
            {events?.map((c) => (
              <option selected={productData?.event === c?.name} value={c?.name}>
                {" "}
                {c?.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Accessible <span className="text-xs text-orange-500">*</span>
          </label>
          <select
            onChange={handleChange}
            name="access"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" selected disabled>
              {" "}
              select
            </option>

            <option value="everyone">Everyone</option>
            <option value="VIP">VIP</option>
          </select>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="productName"
            >
              Product Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="productName"
              name="productName"
              placeholder="Type your product name"
              type="text"
              value={productData.productName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Universe
            </label>
            <select
              onChange={handleChange}
              name="universe"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="" selected disabled>
                {" "}
                select
              </option>
              {universes?.map((c) => (
                <option
                  selected={productData?.universe === c?.name}
                  value={c?.name}
                >
                  {" "}
                  {c?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Category
            </label>
            <select
              onChange={handleChange}
              name="category"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="" selected disabled>
                {" "}
                select
              </option>
              {/* {categorys?.map((c) => (
                <option
                  selected={productData?.category === c?.name}
                  value={c?.name}
                >
                  {" "}
                  {c?.name}
                </option>
              ))} */}
              <option
                selected={productData?.category === "fashion"}
                value="fashion"
              >
                Fashion
              </option>
              <option
                selected={productData?.category === "beauty"}
                value="beauty"
              >
                Beauty
              </option>
              <option
                selected={productData?.category === "sports"}
                value="sports"
              >
                Sports
              </option>
              <option
                selected={productData?.category === "child"}
                value="child"
              >
                Child
              </option>
              <option
                selected={productData?.category === "leisure"}
                value="leisure"
              >
                Leisure
              </option>
              <option
                selected={productData?.category === "brandExclusive"}
                value="brandExclusive"
              >
                BrandExclusive
              </option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Sub Category
            </label>
            <select
              onChange={handleChange}
              name="subCategory"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="" selected disabled>
                {" "}
                select
              </option>
              {subCategorys?.map((c) => (
                <option
                  selected={productData?.subCategory === c?.name}
                  value={c?.name}
                >
                  {" "}
                  {c?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Brand
            </label>
            <select
              onChange={handleChange}
              name="brand"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="" selected disabled>
                {" "}
                select
              </option>
              {brands?.map((c) => (
                <option
                  selected={productData?.brand === c?.name}
                  value={c?.name}
                >
                  {" "}
                  {c?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Stock
            </label>
            <input
              className="shadow bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="stock"
              type="number"
              value={productData.stock}
              onChange={handleChange}
              placeholder="type product stock"
              required
            ></input>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Promotional Price
            </label>
            <input
              className="shadow bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="promotionalPrice"
              type="number"
              value={productData.promotionalPrice}
              onChange={handleChange}
              placeholder="type product promotional price"
              required
            ></input>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Market Price
            </label>
            <input
              className="shadow bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="marketPrice"
              type="number"
              value={productData.marketPrice}
              onChange={handleChange}
              placeholder="type product market price"
              required
            ></input>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Gender
            </label>
            <select
              onChange={handleChange}
              name="gender"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="" selected disabled>
                {" "}
                select
              </option>

              <option selected={productData?.gender === "male"} value="male">
                {" "}
                Male
              </option>
              <option
                selected={productData?.gender === "female"}
                value="female"
              >
                {" "}
                Female
              </option>
              <option
                selected={productData?.gender === "others"}
                value="others"
              >
                {" "}
                Others
              </option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Delivery Option
            </label>
            <select
              required
              onChange={handleChange}
              name="deliveryOption"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="" selected disabled>
                Select a delivery type
              </option>
              <option
                selected={productData?.deliveryOption === "standard"}
                value="standard"
              >
                Standard Delivery
              </option>
              <option
                selected={productData?.deliveryOption === "express"}
                value="express"
              >
                Express Delivery
              </option>
              <option
                selected={productData?.deliveryOption === "nextDay"}
                value="nextDay"
              >
                Next Day Delivery
              </option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Vendor
            </label>
            <input
              className="vendor bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="vendor"
              type="text"
              value={productData.vendor}
              onChange={handleChange}
              placeholder="type product vendor"
              required
            ></input>
          </div>

          {(productData?.productType === "Body care" ||
            productData?.productType === "Make-up" ||
            productData?.productType === "Fragrance") && (
            <>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Strength
                </label>
                <input
                  className="texture bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="strength"
                  value={productData.strength}
                  onChange={handleChange}
                  placeholder="type strength"
                  required
                ></input>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Texture
                </label>
                <input
                  className="texture bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="texture"
                  type="text"
                  value={productData.texture}
                  onChange={handleChange}
                  placeholder="type texture"
                  required
                ></input>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Effect
                </label>
                <input
                  className="texture bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="effect"
                  type="text"
                  value={productData.effect}
                  onChange={handleChange}
                  placeholder="type effect"
                  required
                ></input>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Coverage
                </label>
                <input
                  className=" bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="coverage"
                  type="text"
                  value={productData.coverage}
                  onChange={handleChange}
                  placeholder="type coverage"
                  required
                ></input>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Format
                </label>
                <input
                  className=" bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="format"
                  type="text"
                  value={productData.format}
                  onChange={handleChange}
                  placeholder="type format"
                  required
                ></input>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Type Of Skin
                </label>
                <input
                  className=" bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="typeOfSkin"
                  type="text"
                  value={productData.typeOfSkin}
                  onChange={handleChange}
                  placeholder="write type Of Skin"
                  required
                ></input>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="weight"
                >
                  Weight
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="weight"
                  name="weight"
                  placeholder="type your product weight"
                  type="text"
                  value={productData.weight}
                  onChange={handleChange}
                  required
                />
              </div>
            </>
          )}
          {(productData?.productType === "Others" ||
            productData?.productType === "Shoes" ||
            productData?.productType === "Clothes" ||
            productData?.productType === "Leather goods, luggage and acce" ||
            productData?.productType === "Jewellery, watches and glasses") && (
            <>
              <div className="mb-4 col-span-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="size"
                >
                  Size
                </label>
                {size?.map((s, i) => (
                  <div className="flex items-center gap-2 mt-2" key={s?.value}>
                    <input
                      className="shadow bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      name="size"
                      type="text"
                      defaultValue={s.value}
                      onBlur={(e) => handleSize(e, i)}
                      placeholder="item Size"
                    ></input>
                    <div
                      onClick={() => handleRemoveSize(i)}
                      className="px-3 py-1 rounded-lg bg-red-500 font-bold text-white cursor-pointer"
                    >
                      X
                    </div>
                    <div
                      onClick={handelAddSize}
                      className="px-2  rounded-lg bg-green-500 font-bold text-white text-2xl cursor-pointer"
                    >
                      +
                    </div>
                  </div>
                ))}
              </div>
              <div className="mb-4 col-span-2">
                {color?.map((s, i) => (
                  <div className="mt-1 flex items-center gap-5 border p-2 rounded-md shadow-sm">
                    <div className="w-full">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Color
                      </label>
                      <div
                        className="flex items-center gap-2 mt-2"
                        key={s?.value}
                      >
                        <input
                          className="shadow bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          name="color"
                          type="text"
                          defaultValue={s.value}
                          onBlur={(e) => handleColor(e, i)}
                          placeholder="item Color"
                        ></input>
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          image
                        </label>
                        <div className="flex items-center gap-3">
                          <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            onChange={(e) =>
                              handelColorAndShadeImg(e, "color", i)
                            }
                            name="colorImg"
                            type="file"
                          />
                          <img
                            className="w-12 rounded-md"
                            src={s?.img}
                            alt="img"
                          ></img>
                        </div>
                      </div>
                      {/* button part */}
                    </div>

                    <div className="space-y-2">
                      <div
                        onClick={() => handleRemoveColor(i)}
                        className="px-3 py-1 rounded-lg bg-red-500 font-bold text-white cursor-pointer"
                      >
                        X
                      </div>
                      <div
                        onClick={handelAddColor}
                        className="px-2  rounded-lg bg-green-500 font-bold text-white text-2xl cursor-pointer"
                      >
                        +
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Type
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="type"
                  placeholder="type your product type"
                  type="text"
                  value={productData.type}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  material
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="material"
                  placeholder="type your product material"
                  type="text"
                  value={productData.material}
                  onChange={handleChange}
                  required
                />
              </div>
            </>
          )}
          {productData?.productType === "Make-up" && (
            <div className="mb-4 col-span-2">
              {shade?.map((s, i) => (
                <div className="mt-1 flex items-center gap-5 border p-2 rounded-md shadow-sm">
                  <div className="w-full">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Shade
                    </label>
                    <div
                      className="flex items-center gap-2 mt-2"
                      key={s?.value}
                    >
                      <input
                        className="shadow bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="shade"
                        type="text"
                        defaultValue={s.value}
                        onBlur={(e) => handleShade(e, i)}
                        placeholder="shade color"
                      ></input>
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        image
                      </label>
                      <div className="flex items-center gap-3">
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          onChange={(e) =>
                            handelColorAndShadeImg(e, "shade", i)
                          }
                          name="shadeImg"
                          type="file"
                        />
                        <img
                          className="w-12 rounded-md"
                          src={s?.img}
                          alt="img"
                        ></img>
                      </div>
                    </div>
                    {/* button part */}
                  </div>

                  <div className="space-y-2">
                    <div
                      onClick={() => handleRemoveShade(i)}
                      className="px-3 py-1 rounded-lg bg-red-500 font-bold text-white cursor-pointer"
                    >
                      X
                    </div>
                    <div
                      onClick={handelAddShade}
                      className="px-2  rounded-lg bg-green-500 font-bold text-white text-2xl cursor-pointer"
                    >
                      +
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Special Offers
            </label>
            <input
              className="texture bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="specialOffers"
              value={productData.specialOffers}
              onChange={handleChange}
              placeholder="type specialOffers"
              required
            ></input>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Average Shipping Time
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="averageShippingTime"
              placeholder="type average Shipping Time"
              type="text"
              value={productData.averageShippingTime}
              onChange={handleChange}
              required
            />
          </div>
          {productData?.productType === "Clothes" && (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Cut
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="cut"
                placeholder="type product cut"
                type="text"
                value={productData.cut}
                onChange={handleChange}
                required
              />
            </div>
          )}

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Product Primary image
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="primaryImg"
                type="file"
              />
              <img className="w-12 rounded-md" src={primaryImg} alt="img"></img>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              First Image
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="img1"
                type="file"
              />
              <img className="w-12 rounded-md" src={img1} alt="img"></img>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Second Image
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="img2"
                type="file"
              />
              <img className="w-12 rounded-md" src={img2} alt="img"></img>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Third Image
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="img3"
                type="file"
              />
              <img className="w-12 rounded-md" src={img3} alt="img"></img>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Fourth Image
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="img4"
                type="file"
              />
              <img className="w-12 rounded-md" src={img4} alt="img"></img>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Fifth Image
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="img5"
                type="file"
              />
              <img className="w-12 rounded-md" src={img5} alt="img"></img>
            </div>
          </div>
          <div className="mb-1  w-full  mr-0 md:mr-2">
            <label class="block mb-2 text-[13px] font-normal text-gray-900 ">
              Return Option
            </label>
            <select
              name="return"
              type="text"
              value={productData?.return}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5    focus:border-blue-500"
            >
              <option value="" disabled selected>
                select one
              </option>

              <option selected={productData?.return === "yes"} value="yes">
                Yes
              </option>
              <option selected={productData?.return === "no"} value="no">
                No
              </option>
            </select>
          </div>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="description"
          >
            Description
          </label>
          <JoditEditor
            name="description"
            value={description}
            onChange={(value) => setDescription(value)}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Advice And Application
          </label>
          <JoditEditor
            name="adviceAndApplication"
            value={adviceAndApplication}
            onChange={(value) => setAdviceAndApplication(value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Other Info
          </label>
          <JoditEditor
            name="otherInfo"
            value={otherInfo}
            onChange={(value) => setOtherInfo(value)}
          />
        </div>

        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {productData?._id ? "Update Product" : "Add Product"}
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
