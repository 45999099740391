import UserOrderOverview from '../Components/UserDashboard/MyOrder/UserOrderOverview';
import AccountPage from '../Pages/AccountPage/AccountPage'
import MyAccount from '../Pages/AccountPage/MyAccount'
const UserRoutes = [
  { path: "", Component: AccountPage },
  { path: "myOrders/order/:id", Component: UserOrderOverview },

];

export default UserRoutes;
