import { toast } from "react-toastify";
import swal from "sweetalert";

const PostHooks = (url, data, successMsg) => {
  fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.status === "success") {
        return toast.success(successMsg ? successMsg : "Successfully post")
      } else {
        return toast.error(`${data.error.split(":").slice(2).join(":")}`)
        
      }
    });
};

export default PostHooks;
