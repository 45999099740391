import React from "react";
import { Link } from "react-router-dom";

const BrandCard = ({ brand }) => {
 
  return (
    <Link to={`/brandExclusive/${brand?.name}`}>
      <div className="border bg-white rounded ">
        <img className="rounded w-full" src={brand?.banner} alt="banner"></img>

        <div className="text-center py-6 px-5 relative border-t-2">
          <div className="w-fit absolute -top-8 left-3 rounded-full shadow-lg">
            <img
              className="w-14 h-14 object-cover rounded-full border-2 border-rose-400"
              src={brand?.icon}
              alt="icon"
            ></img>
          </div>
          <button className="text-center bg-rose-100 text-rose-600 font-bold px-8 py-1 hover:scale-105 duration-500 hover:bg-rose-200">
            {brand?.name}
          </button>
        </div>
      </div>
    </Link>
  );
};

export default BrandCard;
