import React, { useEffect, useState } from "react";
import bag from "../../Assets/bag.png";
import ArticleCarousel from "../../Components/Homes/ArticleCarousel/ArticleCarousel";
import { useParams } from "react-router-dom";

const BlogPage = () => {
  const { id } = useParams();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          `https://alala.niroghealthplus.com/api/v1/blog/getBlogsById/${id}`
        );
        const data = await response.json();
        setBlogs(data.data); // Assuming the API response is an array of blog data
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, [id]);

  return (
    <div className="px-5 md:px-10 m-5">
      {/* fst  */}
      <div className="grid mb-10 grid-cols-1 gap-10 md:gap-20 md:grid-cols-2">
        <div className="flex flex-col  justify-center">
          <h1 className="text-md md:text-[45px] mb-2 font-bold">
            {blogs?.title}{" "}
          </h1>

          {/* <div className="bg-black w-1/2 mt-1 px-2 text-white">
            Parfum Par perfume perfume
          </div>
          <div className="bg-black w-1/2 mt-1 px-2 text-white">
            Parfum Par perfume perfume
          </div> */}
        </div>
        <div>
          <img className="w-full h-64 md:h-96 rounded-md" src={blogs.img2} alt=""></img>
        </div>
      </div>
      {/* 2nd */}
      <div>
      <p className="text-justify" dangerouslySetInnerHTML={{ __html: blogs?.description1 }} />

      </div>

      {/* 3rd  */}
      <div className="mt-2 grid grid-cols-10">
        <div className="grid mb-10 grid-cols-1 gap-5 md:grid-cols-2">
          <div>
            <img
              className="w-full md:w-11/12  rounded-md h-64 md:h-96"
              src={blogs?.img2}
              alt=""
            ></img>
          </div>
          <div>
            <h1 className="text-2xl md:text-4xl mb-5 font-bold">
              {blogs?.subtitle1}
            </h1>
            <p className="text-justify">
            <p className="text-justify" dangerouslySetInnerHTML={{ __html: blogs?.description2 }} />

            </p>
          </div>
        </div>
      </div>

      

      {/* 5th  */}
      <div className="mt-2 grid grid-cols-10">
        <div className="grid mb-10 grid-cols-1 gap-5 md:grid-cols-2">
          <div>
            <h1 className="text-2xl md:text-4xl rounded-md mb-5 font-bold">
            {blogs?.subtitle2}
            </h1>
            <p className="text-justify">
            <p className="text-justify" dangerouslySetInnerHTML={{ __html: blogs?.description3}} />

            </p>
          </div>

          <div>
            <img
              className="w-full md:w-11/12 rounded-md  h-64 md:h-96"
              src={blogs?.img3}
              alt=""
            ></img>
          </div>
        </div>
      </div>

      {/* 6th  */}
      <div className="mb-20">
        <h1 className="text-2xl md:text-4xl rounded-md mb-5 font-bold">
      {blogs?.subtitle3}
        </h1>
        <p className="text-justify" dangerouslySetInnerHTML={{ __html: blogs?.description4 }} />

      </div>

      <ArticleCarousel></ArticleCarousel>
    </div>
  );
};

export default BlogPage;
