import React from "react";
import { Link } from "react-router-dom";

const LargeAdvertisingCard = ({data}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${data?.thirdAdImg})`,
      }}
      className="  w-full mx-auto"
    >
      <div className=" text-white bg-black/50 p-10 h-full w-full">
        <h1 className="text-4xl ">{data?.thirdAdTitle}</h1>
        <p className="text-lg mb-5">{data?.thirdAdDes}</p>
        <Link to={data?.thirdAdLink} className=" bg-black  px-6 text-lg font-bold py-1 mt-5">
          Discover
        </Link>
      </div>
    </div>
  );
};

export default LargeAdvertisingCard;
