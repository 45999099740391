import React from "react";
// import icon from "../../../Assets/location.png";
import { Link } from "react-router-dom";
import DeleteHook from "../../../Hooks/DeleteHook";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { server_url } from "../../../Config/API";
import { toast } from "react-toastify";

const UserOrderCard = ({ order,refetch,setRefetch }) => {
  const msg = true;

  const handelUpdate = async ( id) => {
  
    await UpdateHooks(
      `${server_url}/order/updateOrders/${id}`,
      { status: "return" }
    );
    toast.success(`Order status is Return `);
    setRefetch(!refetch);
  };
  return (
    <Link Link to={`/userDashboard/myOrders/order/${order?._id}`}>
      <div className="shadow p-2 rounded-md hover:shadow-primary lg:px-5 px-3 hover:cursor-pointer">
        <div className="flex justify-between gap-5 py-3 md:text-base text-sm">
          <div className="space-y-1">
            <p>Order Id</p>
            <p>Total Products</p>
            <p>Date</p>
            <p>Status</p>
            <p className="font-semibold">Amount Payable</p>
          </div>
          <div className="space-y-1">
            <p className="font-semibold"># {order?._id}</p>
            <p className="font-semibold"> {order?.products?.length}</p>
            <p>{order?.date}</p>
            {order?.status === "pending" ? (
              <button  onClick={() => {
                DeleteHook({
                  refetch,
                  setRefetch,
                  url: `https://alala.niroghealthplus.com/api/v1/order/deleteOrders/${order?._id}`,msg
                });
              }} className="bg-red-500 text-white px-3 py-1 rounded-lg">
                Cancel
              </button>
            ) : (
              order?.status==="return"?<button  className="bg-red-500 text-white px-3 py-1 rounded-lg">
              Already  Returned
            </button>:<button onClick={()=>handelUpdate(order?._id)} className="bg-red-500 text-white px-3 py-1 rounded-lg">
                Return
              </button>
            )}
            <p className="font-semibold">{order?.totalAmount}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default UserOrderCard;
