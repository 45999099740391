import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const ProductSuggestionsModal = ({ searchData, setSearchData }) => {
  console.log(searchData);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setLoading(true)
    async function fetchProducts() {
      try {
        const response = await axios.get(
          "https://alala.niroghealthplus.com/api/v1/product/specific"
        );
        setProducts(response?.data?.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    fetchProducts();
  }, []);

 const search = ()=>{
  if (products) {
    const filter = products?.filter((product) =>
      product?.productName.toLowerCase().includes(searchData.toLowerCase())||
      product?.brand.toLowerCase().includes(searchData.toLowerCase())
    );
    setFilteredProducts(filter);
    setLoading(false)
  }
 }

  useEffect(()=>{
search();
  },[searchData])

  return (
    <div className="fixed lg:top-14 lg:right-[25%] lg:left-[15%] top-28 right-[5%] left-[5%]  z-50 flex items-center justify-center text-black lg:w-5/12 w-11/12">
      <div
        className="  bg-gray-100 p-4 rounded-md z-10 "
        style={{ width: "530px" }}
      >
        <div className="bg-white shadow-sm py-2 flex justify-between items-center px-5 rounded-md mb-1">
          <h2 className="text-lg font-semibold">{searchData}</h2>
          <button
            onClick={() => setSearchData("")}
            className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-rose-500 rounded-md"
          >
            X
          </button>
        </div>
        <div  className="w-full h-[400px] overflow-y-scroll main-navigation">
          {
            filteredProducts?.slice(0,10)?.map(p=>
            <div key={p?._id} className="bg-white shadow m-2 rounded-md p-2 text-gray-700 flex items-center gap-4 justify-between">
              <img
                className="w-16 h-16 object-contain border border-rose-500 p-1 rounded-full"
                src={p?.primaryImg}
                alt="item"
              ></img>
  
              <div className="flex justify-between w-full">
                <div className="space-y-1">
                  <p className="text-[15px] ">{p?.productName}</p>
                  <p className="text-[11px] ">{p?.brand}</p>
                  <p className="text-[11px]">{p?.category}</p>
              
                </div>
  
                <div className="flex flex-col justify-between w-fit ">
                  <p className="text-rose-500 font-medium text-right text-sm">
                  {p?.promotionalPrice}
                  </p>
                  <Link onClick={()=>setSearchData("")} to={`/productDetails/${p?._id}`} className="py-0.5 px-2 rounded-md bg-rose-500 text-white">
                    Details
                  </Link>
                </div>
              </div>
            </div>
          )
          }
          </div>
        
        
      </div>
    </div>
  );
};

export default ProductSuggestionsModal;
