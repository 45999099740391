import React, { useEffect, useState } from "react";
import Faq from "./Faq";

const FooterLayout = () => {
  const [isOpen, setIsOpen] = useState("aboutUs");
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://alala.niroghealthplus.com/api/v1/generalInfo/getGeneralInfos`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setData(data?.data[0]);
        }
      });
  }, []);

  return (
    <div>
      <div className="relative">
        <img className="opacity-80" src={data?.banner} alt="img"></img>
        <p className="absolute top-[45%] md:text-3xl text-lg font-semibold uppercase bg-white/70 px-5 py-2 rounded-md ">
          {isOpen}
        </p>
      </div>
      <div className="flex flex-col  md:flex-row gap-5 mt-5 md:mt-10 md:px-10 px-5">
        <div className="md:w-3/12 md:mt-10">
          <ul className="flex flex-col gap-1 text-start">
            <button
              onClick={() => setIsOpen("aboutUs")}
              className={`${isOpen==="aboutUs"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}
            >
              About us
            </button>
            <button
              onClick={() => setIsOpen("payment")}
             
               className={`${isOpen==="payment"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}
            >
              Payment
            </button>
            <button
              onClick={() => setIsOpen("sales")}  className={`${isOpen==="sales"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              Sales
            </button>
            <button
              onClick={() => setIsOpen("pressAndBrand")}  className={`${isOpen==="pressAndBrand"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              Press and Brand
            </button>
            <button
              onClick={() => setIsOpen("ourCustomerApproach")}  className={`${isOpen==="ourCustomerApproach"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              Our customer approach
            </button>
            <button
              onClick={() => setIsOpen("vvipClub")}  className={`${isOpen==="vvipClub"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              VVIP club
            </button>
            <button
              onClick={() => setIsOpen("legalNotice")}  className={`${isOpen==="legalNotice"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              Legal Notice
            </button>
            <button
              onClick={() => setIsOpen("faq")}
               className={`${isOpen==="faq"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}
            >
              Faq
            </button>
            <button
              onClick={() => setIsOpen("gtc")}  className={`${isOpen==="gtc"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              GTC
            </button>
            <button
              onClick={() => setIsOpen("privacy")}  className={`${isOpen==="privacy"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              Privacy
            </button>
          
            <button
              onClick={() => setIsOpen("recruitment")}  className={`${isOpen==="recruitment"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              Recruitment
            </button>
            <button
              onClick={() => setIsOpen("becomeAPartner")}  className={`${isOpen==="becomeAPartner"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              Become a partner
            </button>
            <button
              onClick={() => setIsOpen("delivery")}  className={`${isOpen==="delivery"&&"bg-rose-500 text-white"} border rounded-md py-1 font-medium hover:bg-rose-500 duration-300 hover:text-white`}>
              Delivery
            </button>
         
          </ul>
        </div>

        <div className=" md:w-9/12 mb-8 md:border-l-2 md:pl-3">
          {/* {isOpen === "about us" && <AboutUs></AboutUs>}
          {isOpen === "faq" && <FaqBox></FaqBox>}
          {isOpen === "payment" && <Payment></Payment>} */}
             <div
          dangerouslySetInnerHTML={{
            __html:
             data?.[isOpen]
          }}
        ></div>
        {isOpen==="faq"&&<Faq/>}
        </div>
      </div>
    </div>
  );
};

export default FooterLayout;
