import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const ProductCarousel = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch data from your API endpoint
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://alala.niroghealthplus.com/api/v1/product/specific"
        );
        const data = await response.json();
        setProducts(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  console.log("all product", products);
  return (
    <div className=" my-24 mx-5 md:mx-10">
      <h1 className="text-3xl uppercase font-bold ">top must have</h1>
      <p className="text-md  font-semibold">
        For <span className=" text-red-500 font-bold uppercase">HER</span> or for <span className=" text-red-500 font-bold uppercase">HIM</span> … give her the ideal gift.
      </p>
      <Carousel
        swipeable={true}
        draggable={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={"desktop"}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px mr-3 z-50"
        autoPlay={true} // Enable autoPlay
        autoPlaySpeed={2000} // Set autoPlay speed in milliseconds (e.g., 3 seconds)
      >
        {products.map((product, index) => (
          <div key={index}>
            <Link to={`/productDetails/${product?._id}`} className="group relative block overflow-hidden bg-[#F5F7FD] rounded-md ">
              <button className="absolute end-0 top-4 z-10 text-sm   bg-red-500 px-5  text-white transition ">
                New
              </button>
              <button className="absolute start-0 top-4 z-10    bg-black px-3 py-0.5 text-white transition ">
                <span className="sr-only">Wishlist</span>
                Promo!
              </button>
              <img
                src={product.primaryImg}
                alt=""
                className="h-64 w-full object-cover transition duration-500 group-hover:scale-105 sm:h-72 "
              />

              <div className="relative   p-6">
                <h3 className=" text-lg font-medium text-gray-900">
                  {product?.productName}
                </h3>

                <div className="flex mt-3 justify-between items-center">
                  <div className="flex font-bold  text-rose-600">
                    <p className="text-xl">{product?.promotionalPrice} CFA </p>
                  </div>

                  <div className="flex font-semibold text-lime-700">
                    <p className="text-xl">
                      {(
                        ((product?.marketPrice - product?.promotionalPrice) /
                          product?.marketPrice) *
                        100
                      ).toFixed(0)}
                    </p>
                    <span>% Off</span>
                  </div>
                </div>
                <div className="flex text-stone-500 font-semibold ">
                  <p className=" line-through">{product?.marketPrice}</p>
                  <span>F</span>
                </div>
              </div>
            </Link>
          </div>
        ))}
        {/* Add more images as needed */}
      </Carousel>
    </div>
  );
};

export default ProductCarousel;