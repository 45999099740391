import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import PostHooks from "../../../Hooks/PostHooks";
import { singleImageUpload } from "../../../Hooks/ImageUpload";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import DeleteHook from "../../../Hooks/DeleteHook";

const AdminAdCarousel = () => {
  const [refetch, setRefetch] = useState(false);
  const [data, setData] = useState([]);
  const [img, setImg] = useState("");
  const [formData, setFormData] = useState({
    link: "",
  });

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    singleImageUpload(formData, setImg);
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "https://alala.niroghealthplus.com/api/v1/adCarousel/getAdCarousels"
        );
        const data = await response.json();
        setData(data.data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching Ad Slider data:", error);
      }
    }
    fetchData();
  }, [refetch]);

  // update and post data
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formData?._id) {
        await UpdateHooks(
          `https://alala.niroghealthplus.com/api/v1/adCarousel/updateAdCarousels/${formData?._id}`,
          { ...formData, img }
        );
        toast?.success(`Ad section Updated !`);
      } else {
        
        await PostHooks(
          `https://alala.niroghealthplus.com/api/v1/adCarousel/addAdCarousels`,
          { ...formData, img },`Ad data posted`
        );

        setRefetch(!refetch)
      }
    } catch (error) {
      toast?.success(error);
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="bg-white">
      <div className="w-fit  text-xl font-semibold mb-5">
        <h1>Update Ad Section</h1>
        <div className="h-1 mt-1 bg-secondary w-[40%]"></div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 p-6 rounded shadow-md w-full mx-auto "
      >
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-600">
              Link
            </label>
            <input
              type="text"
              name="link"
              required
              onChange={handleChange}
              value={formData?.link}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Image
            </label>
            <div className="flex items-center gap-3">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleChangeUploadImage}
                name="img"
                type="file"
              />
              <img className="w-12 rounded-md" src={img} alt="img"></img>
            </div>
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="bg-secondary text-white px-4 py-2 rounded hover:bg-secondary/90 hover:scale-105 duration-300"
          >
            Update Ad !
          </button>
        </div>
      </form>

      <div className="w-fit  text-xl font-semibold my-5">
        <h1>All Ad </h1>
        <div className="h-1 mt-1 bg-secondary w-[40%]"></div>
      </div>

      <div className=" my-10">
        <table
          className="w-full text-left rounded w-overflow-x-auto "
          cellspacing="0"
        >
          <thead>
            <tr className="text-center">
              <th className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Image
              </th>
              <th className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Link
              </th>
              <th className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr className="border-b text-center" key={item?._id}>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
                  <img className="w-20 p-1 rounded-sm border" alt="img" src={item?.img}></img>
                </td>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  <a target="_blank" rel="noreferrer" className="hover:underline hover:text-blue-300" href={item?.link}>{item?.link}</a>
                </td>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  <div className="flex justify-center gap-3">
                    <button
                      className=" border border-red-500 bg-red-100 text-white px-1 py-1 rounded  text-2xl"
                      onClick={() =>
                        DeleteHook({
                          setRefetch,
                          refetch,
                          url: `https://alala.niroghealthplus.com/api/v1/adCarousel/deleteAdCarousels/${item?._id}`,
                        })
                      }
                    >
                      <Icon icon="material-symbols:delete-outline" />
                    </button>
                    <button
                      onClick={async () => {
                        setFormData(item);
                        setImg(item?.img);
                        scrollToTop();
                      }}
                      className="border border-secondary py-1 px-1 rounded-md hover:bg-secondary/10 duration-300 text-xl "
                    >
                      <Icon icon="basil:edit-outline" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminAdCarousel;
