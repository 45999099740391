import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const ArticleCarousel = () => {
  const [blogs, setBlogs] = useState([]);  
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://alala.niroghealthplus.com/api/v1/blog/getBlogs");
        const data = await response.json();
        setBlogs(data.data); // Assuming the API response is an array of blog data
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []); 
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };


  return (
    <div className="px-5 md:px-10 pt-10 pb-16 bg-black">
      <h1 className="text-3xl text-white font-bold uppercase">Latest Article</h1>
      <div className=" mt-14 ">
        <Carousel
          swipeable={true}
          draggable={false}
          responsive={responsive}
          ssr={true}
          infinite={true}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={"desktop"}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px mr-10"
          autoPlay={true} // Enable autoPlay
          autoPlaySpeed={2000} // Set autoPlay speed in milliseconds (e.g., 3 seconds)
        >
        {blogs.map((blog) => (
            <Link key={blog._id} to={`/blog/${blog._id}`}>
              <img
                className="relative ring-opacity-95 rounded-2xl w-full"
                src={blog.img2}
                alt=""
              ></img>
              <div className="absolute font-semibold text-sm bottom-5 p-1">
                <div className="py-[1px] px-[2px] bg-black text-white">
                  {blog.author}
                </div>
                <h1 className="mt-1  px-1">
                  {blog.title}
                </h1>
              </div>
            </Link>
          ))}

          {/* Add more images as needed */}
        </Carousel>
      </div>
    </div>
  );
};

export default ArticleCarousel;