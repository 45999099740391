import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteHook from "../../Hooks/DeleteHook";

const Returns = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const url = `https://alala.niroghealthplus.com/api/v1/order/specificOrders?fieldName=${"status"}&&fieldValue=return`;
    try {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setOrders(data?.data);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      toast.error("something wrong");
    }
  }, []);
  return (
    <div className="px-5 md:px-10 my-10">
      <h1 className="text-xl md:text-3xl font-bold mb-10">Returns </h1>

      {/* box  */}
      {orders?.map((order) => (
        <div
          key={order?._id}
          className=" p-2 rounded-md border lg:px-5 px-3 hover:cursor-pointer"
        >
          <div className="flex justify-between gap-5 py-3 md:text-base text-sm">
            <div className="space-y-1">
              <p>Order Id</p>
              <p>Total Products</p>
              <p>Date</p>
              <p>Status</p>
              <p className="font-semibold">Amount Payable</p>
            </div>
            <div className="space-y-1">
              <p className="font-semibold"># {order?._id}</p>
              <p className="font-semibold"> {order?.products?.length}</p>
              <p>{order?.date}</p>
              <button className="bg-red-500 text-white px-3 py-1 rounded-lg">
                Returned
              </button>

              <p className="font-semibold">{order?.totalAmount}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Returns;
