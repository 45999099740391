import React, { useEffect, useState } from "react";
import CategoryProduct from "../../Components/CategoryProduct/CategoryProduct";
import Loading from "../../Shared/Loading";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";


const CategoryProductPage = () => {
  const [category, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    fetch(
      `https://alala.niroghealthplus.com/api/v1/category/specific/?fieldName=${"name"}&&fieldValue=${id}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setCategory(data?.data[0]);
        }else{
          setCategory([]);
          setLoading(false);
        }

      });
  }, [id]);


  return (
    <div>
        {/* <img
          className="w-full"
          src="https://alala.ci/wp-content/uploads/2023/10/Affiche-nouvelle-saison-web-copie-1.png"
          alt=""
        ></img> */}
      <CategoryProduct category={id}></CategoryProduct>
    </div>
  );
};

export default CategoryProductPage;
