import React, { useContext, useEffect, useState } from "react";
import AddToCart from "../../Components/CheckOut/AddToCart";
import InformationFrom from "../../Components/CheckOut/InformationFrom";
import SuccessForm from "../../Components/CheckOut/SuccessForm";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { FaSchoolLock } from "react-icons/fa6";
import { BiSolidChevronDownCircle } from "react-icons/bi";
import MyContext from "../../Context/MyContext";

const CheckOutPage = () => {
  const { refetch, setRefetch } = useContext(MyContext);
  const [show, setShow] = useState("addToCart");
  const [order, setOrder] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    const exist = JSON.parse(localStorage.getItem("alalaOrder"));
    let total = exist?.reduce(
      (acc, item) => acc + item?.promotionalPrice * item?.quantity,
      0
    );
 
    if (total) {
      setTotalPrice(total);
    }
    if (exist) {
      setOrder(exist);
    } else {
      setOrder([]);
    }
  }, [refetch]);

  return (
    <div className="bg-stone-100 pb-10">
      <div className="flex pt-10 items-center justify-center">
        <button
          className={`${true?"text-green-600 border-green-600":"  border-stone-500"} text-[40px] p-2 rounded-full border-2`}
          onClick={() => setShow("addToCart")}
        >
          <HiOutlineShoppingCart />
        </button>
        <hr className="border-2 w-16 md:w-56 border-stone-500"></hr>
        <button
          className={`${(show==="information"||show==="success")?"text-green-600 border-green-600":"  border-stone-500"} text-[40px] p-2 rounded-full border-2`}
          onClick={() => setShow("information")}
        >
          <FaSchoolLock />
        </button>
        <hr className="border-2 border-stone-500 w-16 md:w-56"></hr>
        <button 
          className={`${show==="success"?"text-green-600 border-green-600":"  border-stone-500"} text-[40px] p-2 rounded-full border-2`}
          onClick={() => setShow("success")}
        >
          <BiSolidChevronDownCircle />
        </button>
      </div>

      <div>
        {show === "addToCart" && (
          <AddToCart
            order={order}
            refetch={refetch}
            setRefetch={setRefetch}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
            setShow={setShow}
          ></AddToCart>
        )}
        {show === "information" && <InformationFrom  order={order}
            refetch={refetch}
            setRefetch={setRefetch} price={totalPrice} setShow={setShow}></InformationFrom>}
        {show === "success" && <SuccessForm></SuccessForm>}
      </div>
    </div>
  );
};

export default CheckOutPage;
