import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";

const AdminDashboardNav = () => {
  const { userInfo, logout } = AuthUser();
  const [issideNavOpen, setSidenavOpen] = useState(false);

  //show  notice
  const [openNotice, setOpenNotice] = useState(false);
  const [openNotice2, setOpenNotice2] = useState(false);
  //show  Complain
  const [openComplain, setOpenComplain] = useState(false);
  //show  frontend
  const [openFrontend, setOpenFrontend] = useState(false);

  //show blog
  const [openBlog, setOpenBlog] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [openBrand, setOpenBrand] = useState(false);
  const [openUniverse, setOpenUniverse] = useState(false);

  //show sidenav on toggle
  const handleToggle = () => {
    issideNavOpen === true ? setSidenavOpen(false) : setSidenavOpen(true);
  };

  let activeStyle = {
    backgroundColor: "#01AEEF",
  };
  return (
    <ul className="space-y-2 pt-8">
      <li>
        <NavLink
          to="dashboard"
          className="flex items-center gap-2 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <span className="text-lg">
            <Icon icon="bxs:dashboard" />
          </span>

          <span className="">Dashboard</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="all-user"
          className="flex items-center gap-2 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <span className="text-lg">
            <Icon icon="fa6-solid:user-gear" />
          </span>

          <span className="">All Users</span>
        </NavLink>
      </li>

      {/* <li>
        <span
          onClick={() => setOpenCategory(!openCategory)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[15px]">
              <Icon icon="material-symbols:category" />
            </span>

            <span className="">Category</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openCategory === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul
          className={`drop_down  ${openCategory === true ? "block" : "hidden"}`}
        >
          <li onClick={handleToggle}>
            <NavLink
              to="addCategory"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:add" />
              </span>
              <span className="ml-3">Add Category</span>
            </NavLink>
          </li>
          <li onClick={handleToggle}>
            <NavLink
              to="allCategory"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:done-all" />
              </span>
              <span className="ml-3">All Category</span>
            </NavLink>
          </li>
        </ul>
      </li> */}

      <li>
        <span
          onClick={() => setOpenBrand(!openBrand)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[15px]">
              <Icon icon="material-symbols:category" />
            </span>

            <span className="">Brand</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openBrand === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul className={`drop_down  ${openBrand === true ? "block" : "hidden"}`}>
          <li onClick={handleToggle}>
            <NavLink
              to="addBrand"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:add" />
              </span>
              <span className="ml-3">Add Brand</span>
            </NavLink>
          </li>
          <li onClick={handleToggle}>
            <NavLink
              to="allBrand"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:done-all" />
              </span>
              <span className="ml-3">All Brand</span>
            </NavLink>
          </li>
        </ul>
      </li>
      <li>
        <span
         onClick={() => setOpenUniverse(!openUniverse)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[15px]">
              <Icon icon="material-symbols:category" />
            </span>

            <span className="">Universe</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openUniverse === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul className={`drop_down  ${openUniverse === true ? "block" : "hidden"}`}>
          <li onClick={handleToggle}>
            <NavLink
              to="addUniverse"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:add" />
              </span>
              <span className="ml-3">Add Universe</span>
            </NavLink>
          </li>
          <li onClick={handleToggle}>
            <NavLink
              to="allUniverse"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:done-all" />
              </span>
              <span className="ml-3">All Universe</span>
            </NavLink>
          </li>
        </ul>
      </li>
      <li>
        <span
          onClick={() => setOpenSubCategory(!openSubCategory)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[15px]">
              <Icon icon="material-symbols:category" />
            </span>

            <span className="">Sub Category</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openSubCategory === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul
          className={`drop_down  ${
            openSubCategory === true ? "block" : "hidden"
          }`}
        >
          <li onClick={handleToggle}>
            <NavLink
              to="addSubCategory"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:add" />
              </span>
              <span className="ml-3">Add Sub-Category</span>
            </NavLink>
          </li>
          <li onClick={handleToggle}>
            <NavLink
              to="allSubCategory"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:done-all" />
              </span>
              <span className="ml-3">All Sub-Category</span>
            </NavLink>
          </li>
        </ul>
      </li>
      
      <li>
        <span
          onClick={() => setOpenEvent(!openEvent)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[15px]">
              <Icon icon="material-symbols:category" />
            </span>

            <span className="">Event</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openEvent === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul
          className={`drop_down  ${
            openEvent === true ? "block" : "hidden"
          }`}
        >
          <li onClick={handleToggle}>
            <NavLink
              to="addEvent"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:add" />
              </span>
              <span className="ml-3">Add Event</span>
            </NavLink>
          </li>
          <li onClick={handleToggle}>
            <NavLink
              to="allEvent"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:done-all" />
              </span>
              <span className="ml-3">All Event</span>
            </NavLink>
          </li>
        </ul>
      </li>

      {/* notice */}
      <li>
        <span
          onClick={() => setOpenNotice(!openNotice)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[15px]">
              <Icon className=" text-xl" icon="carbon:product" />
            </span>

            <span className="">Products</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openNotice === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul
          className={`drop_down  ${openNotice === true ? "block" : "hidden"}`}
        >
          <li onClick={handleToggle}>
            <NavLink
              to="add-product"
              className="flex items-center p-2 text-[14px]  hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:add" />
              </span>
              <span className="ml-3">Add Product</span>
            </NavLink>
          </li>
          <li onClick={handleToggle}>
            <NavLink
              to="allProduct"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:done-all" />
              </span>
              <span className="ml-3">All Product</span>
            </NavLink>
          </li>
        </ul>
      </li>
      {/* shipping */}
      <li>
        <NavLink
          to={"updateShipping"}
          className="flex items-center gap-2 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <span className="text-[15px]">
            <Icon icon="fa-solid:chalkboard-teacher" />
          </span>

          <span className="">Update Shipping</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"allShipping"}
          className="flex items-center gap-2 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <span className="text-[15px]">
            <Icon icon="fa-solid:chalkboard-teacher" />
          </span>

          <span className="">All Shipping</span>
        </NavLink>
      </li>
      {/* coupon  */}

      <li>
        <span
          onClick={() => setOpenNotice2(!openNotice2)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[15px]">
              <Icon className=" text-xl" icon="mdi:coupon-outline" />
            </span>
            <span className="">Coupon</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openNotice2 === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul
          className={`drop_down  ${openNotice2 === true ? "block" : "hidden"}`}
        >
          <li onClick={handleToggle}>
            <NavLink
              to="add-coupon"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:add" />
              </span>
              <span className="ml-3">Add Coupon</span>
            </NavLink>
          </li>
          <li onClick={handleToggle}>
            <NavLink
              to="all-coupon"
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="text-[15px]">
                <Icon className=" text-xl" icon="material-symbols:done-all" />
              </span>
              <span className="ml-3">All Coupon</span>
            </NavLink>
          </li>
        </ul>
      </li>

      {/* Category  */}

      {/* Complain */}
      <li>
        <span
          onClick={() => setOpenComplain(!openComplain)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[18px]">
              <Icon icon="ic:sharp-report" />
            </span>

            <span className="">News Latter</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openComplain === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul
          className={`drop_down  ${openComplain === true ? "block" : "hidden"}`}
        >
          <li onClick={handleToggle}>
            <NavLink
              to={"all-news-latter"}
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">All News Latter</span>
            </NavLink>
          </li>
        </ul>
      </li>
      <li>
        <span
          onClick={() => setOpenComplain(!openComplain)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[18px]">
              <Icon icon="ic:sharp-report" />
            </span>

            <span className="">Blog</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openComplain === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul
          className={`drop_down  ${openComplain === true ? "block" : "hidden"}`}
        >
          <li onClick={handleToggle}>
            <NavLink
              to={"add-blog"}
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">Add Blog</span>
            </NavLink>
          </li>

          <li onClick={handleToggle}>
            <NavLink
              to={"all-blogs"}
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">All Blogs</span>
            </NavLink>
          </li>
        </ul>
      </li>

      {/* order */}

      <li>
        <span
          onClick={() => setOpenBlog(!openBlog)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[18px]">
              <Icon icon="mdi:cart" />
            </span>

            <span className="">Orders</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openBlog === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul className={`drop_down  ${openBlog === true ? "block" : "hidden"}`}>
          <li onClick={handleToggle}>
            <NavLink
              to={"orders"}
              className="flex items-center p-2 text-[14px] hover:bg-primary duration-300  font-normal py-2.5  rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">All Order</span>
            </NavLink>
          </li>
          
          
        </ul>
      </li>
      {/* frontend */}
      <li>
        <span
          onClick={() => setOpenFrontend(!openFrontend)}
          className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
        >
          <div className="flex items-center gap-2">
            <span className="text-[15px]">
              <Icon icon="solar:server-square-update-bold" />
            </span>
            <span className="">Update Frontend</span>
          </div>
          <span
            className={`text-xl transition_move ${
              openFrontend === true ? "rotate-180" : ""
            }`}
          >
            <Icon icon="mingcute:down-fill" />
          </span>
        </span>
        <ul
          className={`drop_down  ${openFrontend === true ? "block" : "hidden"}`}
        >
          <li onClick={handleToggle}>
            <NavLink
              to={"updateGeneralInfo"}
              className="flex items-center p-2 text-[14px]  hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">Update General Info</span>
            </NavLink>
          </li>
          <li onClick={handleToggle}>
            <NavLink
              to={"updateFaq"}
              className="flex items-center p-2 text-[14px]  hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">Update Faq</span>
            </NavLink>
          </li>
          <li onClick={handleToggle}>
            <NavLink
              to={"updateBanner"}
              className="flex items-center p-2 text-[14px]  hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">Update Banner</span>
            </NavLink>
          </li>

          <li onClick={handleToggle}>
            <NavLink
              to={"updateAd"}
              className="flex items-center p-2 text-[14px]  hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">Update Ad</span>
            </NavLink>
          </li>

          <li onClick={handleToggle}>
            <NavLink
              to={"updateAdSlider"}
              className="flex items-center p-2 text-[14px]  hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">Update Slider Ad</span>
            </NavLink>
          </li>
         
          <li onClick={handleToggle}>
            <NavLink
              to={"updateFooter"}
              className="flex items-center p-2 text-[14px]  hover:bg-primary duration-300 font-normal  py-2.5 rounded-md  text-white"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span className="ml-3">Update Footer</span>
            </NavLink>
          </li>
        </ul>
      </li>

     
      <li
        onClick={logout}
        className="flex cursor-pointer items-center gap-2 px-2 py-2.5 text-[14px] font-normal rounded  text-white hover:bg-primary duration-300"
      >
        <span className="text-lg">
          <Icon icon="tabler:logout" />
        </span>

        <span className="">LogOut</span>
      </li>
    </ul>
  );
};

export default AdminDashboardNav;
