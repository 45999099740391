import React from "react";
import EventCard from "./EventCard";
import useGetDataHook from "../../../Hooks/useGetDataHook";
import { server_url } from "../../../Config/API";
import Loading from "../../../Shared/Loading";

const NewEvent = () => {
  const [events, loading] = useGetDataHook(
    `${server_url}/event/specific?fieldName=typeOfEvent&&fieldValue=New`
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {/* news product  */}
      <div className="mx-5 md:mx-10 mb-10 md:mb-24">
        <h1 className="text-3xl font-semibold">NEW EVENTS</h1>
        <div className="grid grid-cols-1 mt-6 mb-10 md:grid-cols-2 gap-7">
          {events?.map((event) => (
            <EventCard key={event?._id} event={event}></EventCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewEvent;
