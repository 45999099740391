import React, { useEffect, useState } from 'react';
import AdvertisingCard from './AdvertisingCard';


const Advertising = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`https://alala.niroghealthplus.com/api/v1/ad/getAds`)
      .then((res) => res?.json())
      .then((data) => {
        if (data?.data?.length) {
          setData(data?.data[0]);
        } else {
          setData([]);
        }
      });
  }, []);
    return (
       <div>
         <AdvertisingCard data={data} />
       </div>
    );
};

export default Advertising;