import React from "react";
import { server_url } from "../../Config/API";
import useGetDataHook from "../../Hooks/useGetDataHook";

const Universe = () => {
  const [universes] = useGetDataHook(`${server_url}/universe/getUniverses`);
  return (
    <div className="mx-5 md:mx-10 my-10 md:my-20">
        <h2 className="text-2xl font-semibold mb-5">Universe</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-5 lg:grid-cols-4">
        {universes?.slice(0, 8)?.map((c) => (
          <div
            style={{ backgroundImage: `url(${c?.banner})` }}
            key={c?._id}
            className="bg-purple-300 w-full rounded-xl flex gap-2 items-center"
          >
            <img
              className="h-[80px] md:h-[92px] object-cover w-32 rounded-xl p-[6px]"
              src={c?.icon}
              alt=""
            ></img>
            <p className="text-lg md:text-xl font-bold pr-2">{c?.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Universe;
