import React from "react";
import CatagoriesProduct from "../../Components/Homes/CatagoriesProduct/CatagoriesProduct";
import LogoNameCarousel from "../../Components/Homes/LogoNameCarousel/LogoNameCarousel";
import Slider from "./Slider/Slider";
import ArticleCarousel from "../../Components/Homes/ArticleCarousel/ArticleCarousel";
import ProductCarousel from "../../Components/Homes/CarouselProduct/ProductCarousel";
import Advertising from "../../Components/Advertising/Advertising";
import AdvertisingLarge from "../../Components/Advertising/AdvertisingLarge";
import SliderAd from "../../Components/Advertising/SliderAd";
import NewEvent from "../../Components/Homes/AllEvent/NewEvent";
import CurrentSelectionEvent from "../../Components/Homes/AllEvent/CurrentSelectionEvent";
import ForHerEvent from "../../Components/Homes/AllEvent/ForHerEvent";
import ForHimEvent from "../../Components/Homes/AllEvent/ForHimEvent";
import BrandCarousel from "../../Components/BrandExclusive/BrandCarousel";

const Home = () => {
  return (
    <div>
      <Slider/>
      <CatagoriesProduct></CatagoriesProduct>
      <NewEvent></NewEvent>
      <SliderAd></SliderAd>
      <CurrentSelectionEvent/>
      <Advertising></Advertising>
      <ForHerEvent/>
      <ForHimEvent/>
      <ProductCarousel></ProductCarousel>
      <AdvertisingLarge></AdvertisingLarge>
      {/* <LogoNameCarousel></LogoNameCarousel> */}
      <BrandCarousel/>
      <ArticleCarousel></ArticleCarousel>
    </div>
  );
};

export default Home;
