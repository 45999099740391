import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Slider.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Slider = () => {
  const [images, setImages] = useState([]);
  const fetchData = async () => {
    await fetch(`https://alala.niroghealthplus.com/api/v1/slider/getSliders`)
      .then((res) => res.json())
      .then((data) => setImages(data?.data));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="md:mx-10 mx-5">
      <>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {images?.map((i) => (
            <SwiperSlide
              className=" w-full max-h-[500px] object-cover rounded-md mt-5 overflow-hidden"
              key={i?._id}
            >
              <img
                className="w-full max-h-[500px] object-contain"
                src={i?.sliderImg}
                alt="img"
              ></img>
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    </div>
  );
};

export default Slider;
