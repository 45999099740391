import React from "react";
import useGetDataHook from "../../../Hooks/useGetDataHook";
import { server_url } from "../../../Config/API";

const AdminDashboardIndex = () => {
    const [products] = useGetDataHook(
        `${server_url}/product/specific`
      );
    const [orders] = useGetDataHook(
        `${server_url}/order/specificOrders`
      );
    const [pendingOrders] = useGetDataHook(
        `${server_url}/order/specificOrders?fieldName=status&&fieldValue=pending`
      );
    const [confirmOrders] = useGetDataHook(
        `${server_url}/order/specificOrders?fieldName=status&&fieldValue=confirm`
      );
    const [deliveredOrders] = useGetDataHook(
        `${server_url}/order/specificOrders?fieldName=status&&fieldValue=delivered`
      );
      let totalSelling = deliveredOrders?.reduce(
        (acc, item) => acc + item?.totalAmount,
        0
      );

      const [users] = useGetDataHook(
        `${server_url}/order/specificOrders`
      );
      const [brands] = useGetDataHook(
        `${server_url}/brand/specific`
      );
      const [events] = useGetDataHook(
        `${server_url}/event/specific`
      );

  return (
    <div className="">
      <div className="">
        <div>
          <h1 className="text-2xl font-semibold">
            Welcome to the Admin Dashboard
          </h1>
          <p className="mt-4 text-gray-500">
            Here is where you can manage all the functionalities of the website.
          </p>
        </div>
      </div>

      <div className=" w-full md:max-w-6xl px-8">
        <div className="flex items-center flex-wrap  ">
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F0FDF4] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#B0D6D0]"></div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Products
                </h3>
                <span className="text-sm text-lightTextColor">{products?.length}</span>
              </div>
            </div>
          </div>

          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F0FDF4] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#B0D6D0]"></div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Orders
                </h3>
                <span className="text-sm text-lightTextColor">{orders?.length}</span>
              </div>
            </div>
          </div>

          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F8FFEC] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#D9F99D]"></div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Pending Order
                </h3>
                <span className="text-sm text-lightTextColor">{pendingOrders?.length}</span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0  shadow-lg shadow-gray-300 gap-5  bg-[#F4FFEF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#BBF7D0]"></div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Confirm Orders
                </h3>
                <span className="text-sm text-lightTextColor">{confirmOrders?.length}</span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0  shadow-lg shadow-gray-300 gap-5  bg-[#F4FFEF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#BBF7D0]"></div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Delivered Orders
                </h3>
                <span className="text-sm text-lightTextColor">{deliveredOrders?.length}</span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F6F3FF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#DDD6FE]"></div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Selling
                </h3>
                <span className="text-sm text-lightTextColor">{totalSelling}</span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F6F3FF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#DDD6FE]"></div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Users
                </h3>
                <span className="text-sm text-lightTextColor">{users?.length}</span>
              </div>
            </div>
          </div>

          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F6F3FF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#DDD6FE]"></div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Brands
                </h3>
                <span className="text-sm text-lightTextColor">{brands?.length}</span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F6F3FF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#DDD6FE]"></div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Events
                </h3>
                <span className="text-sm text-lightTextColor">{events?.length}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardIndex;
