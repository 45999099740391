import React from "react";
import logo from "../Assets/logo.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const Footer = () => {
  const handleForm = async (e) => {
    e.preventDefault();
    const form = e.target;
    const name = e.target.name.value;
    const email = e.target.email.value;

    try {
      const response = await fetch("https://alala.niroghealthplus.com/api/v1/newsLatter/addNewsLatter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email,}),
      });

      
      if (response.ok) {
        form.reset();
        toast.success("Subscribe Successfully") 

      } else {
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  return (
    <div>
      <div className=" m-10">
        <div className="mx-5 md:mx-10 flex flex-col gap-7 md:gap-0 md:flex-row">
          <div className="md:w-7/12 py-2">
            <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
              <div>
                <img src={logo} alt=""></img>
                <p className="text-xs font-bold mt-1">
                  Alala, your private sales platform for major brands.
                </p>
              </div>
              <div className="text-xs flex flex-col font-bold gap-2">
                <Link to="footerElemnet">About us</Link>
                <Link>Payment</Link>
                <Link>Sales</Link>
                <Link>Press & Brand</Link>
                <Link>Our customer approach</Link>
                <Link>VVIP Club</Link>
                <Link>Legal Notices</Link>
                <Link to="contact">Contact us</Link>
              </div>

              <div className="text-xs md:border-l py-2 border-l-stone-300 md:pl-3 flex flex-col font-bold gap-2">
                <Link to="/faq">FAQ</Link>
                <Link>T&Cs</Link>
                <Link to="privacy">Privacy policy</Link>
                <Link>Recruitment</Link>
                <Link>Become a</Link>
                <Link>Delivery Partner</Link>
                <Link to="return">Return policies</Link>
              </div>
            </div>
          </div>

          <div className="md:w-5/12 py-2 md:border-l-2 border-l-stone-400 md:pl-3">
            <div>
              <h1 className="font-bold text-xl">NEW TO ALALA?</h1>
              <p className="font-semibold text-sm mt-1">
                Subscribe to our newsletters to stay informed about our best
                offers!
              </p>
            </div>

            <form onSubmit={handleForm}>
              <input
                name="name"
                type="text"
                className="border p-2 mt-5 mb-3 border-stone-400 w-full rounded"
                placeholder="Your Name"
              ></input>
              <input
                name="email"
                type="email"
                className="border p-2 my-3 border-stone-400 w-full rounded"
                placeholder="Your email"
              ></input>
              <button
                type="submit"
                className="bg-red-500 mt-5 px-10 rounded text-xl font-bold text-white py-2"
              >
                Subscribe
              </button>
              <p className="text-sm font-bold mt-8 mb-5 text-rose-500">
                SPONSOR YOUR FRIENDS!
              </p>
            </form>
          </div>
        </div>
      </div>

      <div className="bg-stone-200 p-3">
        <p className="text-sm font-semibold text-center">
          ALALA © All Rights Reserved | Developed by{" "}
          <span className="font-bold">Thinkystorm</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;