import React from "react";

const AboutUs = () => {
  return (
    <div>
      <h1 className="text-2xl md:text-4xl text-rose-500 font-bold">
        Brand Name Products At Low prices 24 hours a day.... all year round
      </h1>

      <div className="mt-6">
        <p>
          ALALA is the first private sales site for luxury products in West
          Africa. We provide opportunities for thousands of people every day to
          get great deals on the brand name products they want. Our portfolio,
          which currently includes nearly twenty international and African
          brands, allows us to maintain competitive prices on high quality
          products. Fashion, Beauty, Home, Sport and many other categories, we
          are strengthening our offer by putting hundreds of new references
          every week to the delight of our customers.
        </p>

        <div className="mt-8">
            <h1 className="font-bold">ALALA Is :</h1>
            <ul className="flex flex-col gap-2 ml-5 list-disc">
                <li> +20 brands (international and African) and +100 references each week</li>
                <li>Customer service at your service</li>
                <li>Competitive prices</li>
                <li>Delivery to Abidjan between 24 hours after the order or 5 days maximum anywhere on Ivorian territory. Possibility of delivery internationally</li>
                <li>100% guaranteed authentic products.</li>
                <li>Free return and refund within 7 working days (see conditions by clicking here ).</li>
      
            </ul>

        </div>
      </div>
    </div>
  );
};

export default AboutUs;
