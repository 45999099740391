import React,{useEffect,useState} from "react";
import { MdDeleteOutline } from "react-icons/md";
import CardCategoryProduct from "../CategoryProduct/CardCategoryProduct";

import { Icon } from "@iconify/react";

const AddToCart = ({ order, refetch, setRefetch,totalPrice,setShow }) => {
  const [allData,setAllData]=useState([])
  useEffect(() => {
    try {
      fetch(
        `https://alala.niroghealthplus.com/api/v1/product/specific?size=${3}&&page=${0}`
      )
        .then((res) => res.json())
        .then((data) => {
          setAllData(data?.data);
        });
    } catch (crr) {}
  }, []);
  
  const handleQuantity = (action, item) => {
    const existingOrder = JSON.parse(localStorage.getItem("alalaOrder"));
    if (existingOrder) {
      const exist = existingOrder.find((i) => i?._id === item?._id);

      if (exist) {
        const filterOrder = existingOrder.filter(
          (item) => item._id !== exist?._id
        );

        if (action === "odd") {
          if (exist?.quantity === 1) {
            localStorage.setItem(
              "alalaOrder",
              JSON.stringify([...filterOrder])
            );
          } else {
            exist.quantity = exist.quantity - 1;
            localStorage.setItem(
              "alalaOrder",
              JSON.stringify([exist, ...filterOrder])
            );
          }
        } else if (action === "even") {
          exist.quantity = exist.quantity + 1;
          localStorage.setItem(
            "alalaOrder",
            JSON.stringify([exist, ...filterOrder])
          );
        } else {
          localStorage.setItem("alalaOrder", JSON.stringify([...filterOrder]));
        }
      }
    }
    setRefetch(!refetch);
  };
  return (
    <div className="px-2 md:px-10">
      <h1 className="text-2xl font-bold mb-10 mt-16">
        Total {order?.length ? order?.length : 0} product
      </h1>
      {/* card */}
      <div className="grid border rounded bg-white grid-cols-1 md:gap-5 gap-3">
        {order?.map((o) => (
          <div
            key={o?._id}
            className="grid grid-cols-2  md:p-10 p-3 md:gap-10 gap-4 items-center shadow-sm"
          >
            {/* fst  */}
            <div>
              <img className="w-80 h-80" src={o?.primaryImg} alt="img"></img>
            </div>

            {/* 2nd  */}
            <div className="md:pr-10 ">
              <h1 className="text-xl font-semibold">{o?.productName}</h1>
              <h1 className="my-2 ">Category: {o?.category}</h1>
              <p className="my-2 flex gap-2">Color : red</p>
              <p>Size : 23</p>
              <div className="flex my-2 font-bold  ">
                <p className="md:text-2xl text-lg">Price: {o?.promotionalPrice}</p>
                <span>F</span>
              </div>
              <div className="md:flex flex-row gap-3 mt-3 items-center">
                <label className="text-lg font-semibold ">quantity</label>
                <div className=" flex gap-5 items-center">
                  <div class="flex items-center md:text-4xl text-2xl mt-3 border p-2 rounded-md shadow-sm">
                    <button
                      onClick={() => handleQuantity("odd", o)}
                      class="border rounded-md px-2  mr-2 bg-red-500 text-white"
                    >
                      <Icon icon="typcn:minus" />
                    </button>
                    <span class="text-center md:text-2xl text-lg font-bold">
                      {o?.quantity}
                    </span>
                    <button
                      onClick={() => handleQuantity("even", o)}
                      class="border rounded-md  px-2 ml-2 bg-green-500 text-white"
                    >
                      <Icon icon="typcn:plus" />
                    </button>
                  </div>
                <MdDeleteOutline
                  onClick={() => handleQuantity("delete", o)}
                  title="delete from cart"
                  className="text-red-500 text-4xl md:text-5xl mt-3 bg-red-100 rounded-full p-1 cursor-pointer hover:scale-105 duration-500"
                />
                </div>

              </div>

              
            </div>
          </div>
        ))}
      </div>

      

      {/* price  */}
      <div className="mt-16 bg-white rounded   p-8">
        <div className="grid grid-cols-1 gap-5">
          <div className="flex justify-between items-center">
            <p className="text-xl font-semibold">Sub-total</p>
            <div className="flex font-semibold ">
              <p className="text-2xl">{totalPrice}</p>
              <span>F</span>
            </div>
          </div>
       
        </div>
      </div>

      <div>
        <button onClick={()=>setShow("information")} className="uppercase w-full mt-10 bg-rose-500 text-white font-bold py-4 md:text-xl">
          Payment
        </button>
        <button className="uppercase w-full mt-10 bg-green-500 text-white font-bold py-4 md:text-xl">
          Pay by whatsapp
        </button>
      </div>

      {/* 3 card  */}
      <div>
        <h1 className="uppercase text-2xl md:text-3xl mb-5 font-bold mt-20">
          You may also like
        </h1>
        <div className="grid grid-cols-1 mt-8 md:grid-cols-3 gap-5">
          {allData?.map((data) => (
            <CardCategoryProduct
              key={data?._id}
              product={data}
            ></CardCategoryProduct>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
