import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const BrandCarousel = ({brands}) => {
  return (
    <div className="mx-5 md:mx-10 my-20">
      <Carousel
        swipeable={true}
        draggable={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={"desktop"}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px mr-3"
      >
        {
          brands?.map(brand=><div key={brand?._id}
            className="h-24 rounded"
            style={{
              backgroundImage: `url(${brand?.banner})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <h1 className="text-2xl font-bold absolute top-9 left-2 bg-white/70 px-3 rounded-md">{brand?.name}</h1>
          </div>)
        }
        <div></div>

      </Carousel>
    </div>
  );
};

export default BrandCarousel;
