import React from "react";
import { Link } from "react-router-dom";

const AdvertisingCard = ({data}) => {
  return (
    <div className=" grid md:grid-cols-2 grid-cols-1 gap-5 px-5 py-5 ">
      <div
        style={{
          backgroundImage: `url(${data?.firstAdImg})`,
        }}
        className=" w-full mx-auto"
      >
        <div className=" text-white bg-black/50 p-10 h-full w-full">
          <h1 className="text-4xl ">{data?.firstAdTitle}</h1>
          <p className="text-lg mb-5">{data?.firstAdDes}</p>
          <Link to={data?.firstAdLink} className=" bg-black  px-6 text-lg font-bold py-1 mt-5">
            Discover
          </Link>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${data?.secondAdImg})`,
        }}
        className=" w-full mx-auto"
      >
        <div className=" text-white bg-black/50 p-10 h-full w-full">
          <h1 className="text-4xl ">{data?.secondAdTitle}</h1>
          <p className="text-lg mb-5">{data?.secondAdDes}</p>
          <Link to={data?.secondAdLink} className=" bg-black  px-6 text-lg font-bold py-1 mt-5">
            Discover
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdvertisingCard;
